

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Reflective Shades",
    description: "Reflect enemy fire spells, your next spell casts in 1s.",
   // flair: "I, too, am warm blooded",
    spellType: spellTypes.fire,
    level: 1,
   // charges: 1,
    castTime: 0,
    cooldown: 10,
    duration: 3,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "eyes",
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, {
                    buff: buffs.spellTypeReflect, 
                    buffParams: [100, spellTypes.fire]
                }, 3
            ],
        },
        {
            action: actions.addConditionalTimerBuff,
            actionParams: [
                targets.me, 
                {
                    buff: buffs.fasterCastTime, 
                    buffParams: [5]
                }, 
                3,
                [conditions.spellBegunCasting, targets.me, null, null],
            ],
        },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.allAliveAllies, 
        //         {
        //             buff: buffs.damageTypeBonus,
        //             buffParams: [2, spellTypes.fire]
        //         }, 
        //         4, 
        //     ]
        // }
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.triggeringPlayer, 
        //         {
        //             buff: buffs.damageTypeBonus,
        //             buffParams: [2, spellTypes.fire]
        //         }, 
        //         5, 
        //     ]
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}