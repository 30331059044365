import { useNavigate } from 'react-router-dom';

const storageValues = require("../storageValues");
const serverMessages = require('shared/serverMessages.js');
const matchOptions = require('shared/matchOptions.js');
const {Tutorial1, Tutorial2, Tutorial3, TutorialAI, TutorialAI2, EarthOnly, FireOnly, IceOnly} = require('shared/decks/providedDecks');
let {Hats, Robes} = require("shared/hats");

export default function useGameCreator(playerID, partyID, ioConnection, gameOptions, profileOptions, onStart, setGameName){

    let navigate = useNavigate();

    const { 
        allyAI, 
        enemyAI, 
        roundStyle, 
        maxHealth, 
        fightTime,
        gameName 
    } = gameOptions;

    const { 
        myDeck, 
        myHat, 
        myRobe
    } = profileOptions;

    const createGame = () => {
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = partyID;
            let options = {...gameOptions};
       
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.joinOrCreateGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe,
            });
            setGameName(myGameName);
            navigate('/game/'+myGameName);
        }, 1);
        onStart();
        checkSounds();
        if(soundsOn)Sounds.whoosh.play();
    }

    const createPracticeGame = () => {
        let options = {...gameOptions};
        options.enemyAI = matchOptions.ai.dummy;//hard // dummy
        options.roundStyle = matchOptions.roundStyle.builddeckupfront;
        options.enemyAICount = 1;
        options.allyAICount = 0;
       
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = playerID;
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.createGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe
            });
           // navigate(gameName);
        }, 1);
        onStart();
        if(soundsOn)Sounds.whoosh.play();
    }

    const createBearChallenge = () => {
        let options = {...gameOptions};
        options.enemyAI = matchOptions.ai.hard;//hard // dummy
       // options.roundStyle = matchOptions.roundStyle.builddeckupfront;
        options.aiDrawsFromProvidedDeck = true;
        options.enemyAICount = 1;
        options.enemyAIHat = Hats.none;
        options.enemyAIRobe = Robes.bear;
        options.enemyAIName = "Ol' Griz";
        options.enemyAIDeck = EarthOnly();
        options.allyAICount = 0;
       
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = playerID;
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.createGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe
            });
           // navigate(gameName);
        }, 1);
        onStart();
        if(soundsOn)Sounds.whoosh.play();
    }

    const createIceChallenge = () => {
        let options = {...gameOptions};
        options.enemyAI = matchOptions.ai.hard;//hard // dummy
       // options.roundStyle = matchOptions.roundStyle.builddeckupfront;
        options.aiDrawsFromProvidedDeck = true;
        options.enemyAICount = 1;
        options.enemyAIHat = Hats.none;
        options.enemyAIRobe = Robes.iced;
        options.enemyAIName = "Snowball";
        options.enemyAIDeck = IceOnly();
        options.allyAICount = 0;
       
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = playerID;
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.createGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe
            });
           // navigate(gameName);
        }, 1);
        onStart();
        if(soundsOn)Sounds.whoosh.play();
    }

    const createFireChallenge = () => {
        let options = {...gameOptions};
        options.enemyAI = matchOptions.ai.hard;//hard // dummy
       // options.roundStyle = matchOptions.roundStyle.builddeckupfront;
        options.aiDrawsFromProvidedDeck = true;
        options.enemyAICount = 1;
        options.enemyAIHat = Hats.none;
        options.enemyAIRobe = Robes.fired;
        options.enemyAIName = "Francis";
        options.enemyAIDeck = FireOnly();
        options.allyAICount = 0;
       
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = playerID;
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.createGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe
            });
           // navigate(gameName);
        }, 1);
        onStart();
        if(soundsOn)Sounds.whoosh.play();
    }

    const createTutorialGame = () => {
        let options = {...gameOptions};
        //options.enemyAI = matchOptions.ai.dummy; 
        options.enemyAI = matchOptions.ai.easy; 
       // options.roundStyle = matchOptions.roundStyle.builddeckupfront;
       // options.roundStyle = matchOptions.roundStyle.damageTest;
        options.roundStyle = matchOptions.roundStyle.tutorial;

       // options.maxHealth = matchOptions.maxHealth.medium;
        options.enemyAICount = 1;
        options.allyAICount = 0;
       
        setTimeout(()=>{
            let myGameName = gameName;
            if(!myGameName) myGameName = playerID;
            const myName = localStorage.getItem(storageValues.playerName);
            ioConnection.emit(serverMessages.requests.createGame, {
                gameName: myGameName, playerID, gameOptions: options, myName, myDeck, myHat, myRobe
            });
            navigate('/game/'+gameName);
        }, 1);
        onStart();
        if(soundsOn)Sounds.whoosh.play();
    }

    return {
        createGame,
        createPracticeGame,
        createTutorialGame,
        createBearChallenge,
        createFireChallenge,
        createIceChallenge
    }
}