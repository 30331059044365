
import React, { useEffect } from 'react';
import "./Disconnected.scss"

export default function Disconnected({ioConnection}){


    return  <div className="disconnectedOverlay">
        <div className="disconnectedMessageArea">
           
            <div className="disconnectedMessage">

                <div className="disconnectedIcon">
                    <span className="material-symbols-outlined" style={{color:"white"}}>
                        cloud_off 
                    </span>    
                </div>
                  
                <div>Disconnected</div>

                <div className="reconnecting">Reconnecting...</div>
            </div>
        </div>
    </div>
}