


let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Meditate",
    description: "Target ally gains +5hp/2sec until they are delt damage.",
   // flair: "Sobriety looks good on you",
    spellType: spellTypes.earth,
    level: 2,
   // charges: 1,
    castTime: 0,
    duration: 10,
    brokenByDamage: true,
    cooldown: 30,
    damage: 0,
    target: targets.selections.targetAlly,
    effectNumber: "+5x",
    image: "hotbox",
    effects: [
        { // need : actions.addEffectToGroup
            action: actions.addConditionalPeriodicEffect,
            actionParams: [
                targets.selectedPlayer, 
                {
                    action: actions.gainHealth,
                    actionParams: [5],
                    image: "weed",
                }, 
                2, 
                [conditions.damageDone, null, targets.me, null], // TODO less than 2 
                10
            ]
        },
        // { // thing to be done when condition is met (add timer buff)
        //     action: actions.addTimerConditionState,
        //     actionParams: [
        //         targets.me, 
        //         stateBuffs.channeling,
        //         6,
        //         [conditions.damageDone, null,  targets.me, null], 
        //     ]
        // },
    ],
    xpGain: 0,
    manaCost: 1,
}


// {
//                 action: actions.addConditionalPeriodicEffect,
//                 actionParams: [
//                 playerTargets.me, 
//                 {
//                     action: actions.gainHealth,
//                     actionParams: [1],
//                     image: "meditation",
//                     description: "Gain life until someone casts a spell",
//                 }, 
//                 1, 
//                 [conditions.spellCast]
//                 ],
//             },