import { useEffect, useState, useReducer } from 'react';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import Disconnected from '../menu/components/Disconnected';
const serverMessages = require("../../../shared/serverMessages");
const storageValues = require("../storageValues");
const {StartingDeck} = require('shared/decks/providedDecks');


export default function useIOConnection(isInDiscord, discordInfo, joinGameID, joinPartyID){

    // game ID means I am in a game
    const [gameID, setGameID] = useState(null);
    // party ID basically means when I start a game, this will be the name of the game
    const [partyID, setPartyID] = useState(null);
    const [partyMembers, setPartyMembers] = useState([]);
    const [playerID, setPlayerID] = useState(null);
    const [playerName, setPlayerNameWrapped] = useState(null);

   
    let navigate = useNavigate();

    const [ioConnection, setIOConnection] = useState();

     // when we set the player name we need to change the state and the storage value
     const setPlayerName = function(name){
        if(name){
            setPlayerNameWrapped(name)
            localStorage.setItem(storageValues.playerName, name);
            if(ioConnection) ioConnection.emit(serverMessages.requests.changeName, {playerID, name});
        }
    }


    const [connecting, setConnecting] = useReducer((state, data)=>{
        return data;
    }, true);

    const [reconnectTrigger, tryReconnect] = useReducer((state)=>{
        return !state;
    }, false);

    const [isDisconnected, setIsDisconnected] = useState(false);


    useEffect(()=>{
        if(!connecting) return;
        if(isInDiscord && !discordInfo) return;
        if(isInDiscord && discordInfo && discordInfo.loading) return;

       //console.log("connecting")
        // figure out player ID and establish connection
        let savedID = localStorage.getItem(storageValues.playerID);
        if(!savedID || savedID === "undefined"){
            savedID = uuidv4();
            localStorage.setItem(storageValues.playerID, savedID);
        }
        setPlayerID(savedID);
        let socket = io.connect(null, {'reconnection': true,'reconnectionDelay': 500,'maxReconnectionAttempts': Infinity});

        setIOConnection(socket); 

        // join game if game is saved
        let playerName = localStorage.getItem(storageValues.playerName);
        setPlayerName(playerName);
        let lastGame = localStorage.getItem(storageValues.currentGame);
        let myHat = localStorage.getItem(storageValues.myHat);
        let myRobe = localStorage.getItem(storageValues.myRobe);

        if(lastGame || joinGameID){
            let savedName = localStorage.getItem(storageValues.playerName);
            let myDeck = localStorage.getItem(storageValues.playerDeck);
          
            if(!myDeck || myDeck === "undefined" || myDeck == "[object Object]"){
                myDeck = StartingDeck();
            } else {
                myDeck = JSON.parse(myDeck);
            }
            if(savedName && myHat && myRobe){
                socket.emit(serverMessages.requests.joinOrCreateGame, {gameName: joinGameID || lastGame, playerID: savedID, myName: savedName, myDeck, myHat, myRobe});
            }
            navigate('/game/'+ (joinGameID || lastGame))
        }

        // loooks like join party is almost being used like 'init' now (I guess thats fine)
        let storedParty = localStorage.getItem(storageValues.partyID);
        if(isInDiscord){
            socket.emit(serverMessages.requests.joinParty, {partyID: discordInfo.channel.id, playerID: savedID, myName: playerName, myHat});
        } else if(joinPartyID){
            socket.emit(serverMessages.requests.joinParty, {partyID: joinPartyID, playerID: savedID, myName: playerName, myHat});
        } else if(storedParty) {
            socket.emit(serverMessages.requests.joinParty, {partyID: storedParty, playerID: savedID, myName: playerName, myHat});
        } else {
            // else use player ID
            socket.emit(serverMessages.requests.joinParty, {partyID: savedID, playerID: savedID, myName: playerName, myHat});
        }
        
        socket.on('disconnect', (reason) => {
            setIsDisconnected(true);
            // let socket = io.connect(null, {'reconnection': true,'reconnectionDelay': 500,'maxReconnectionAttempts': Infinity});
            // setIOConnection(socket);
            //setReconnectionInterval(setInterval(()=> {
            //     setIsDisconnected(!isDisconnected)}, 1000)
            //);
        });

        socket.on('connect', function() { 
            setIsDisconnected(false);
            //clearInterval(reconnectionInterval);
            
            setConnecting(false);
            
        });

        socket.on(serverMessages.responses.gameCreated, function({game, gameOptions}) { 
            setGameID(game);
            localStorage.setItem(storageValues.currentGame, game);
            localStorage.setItem(storageValues.gameOptions, JSON.stringify(gameOptions));
        });

        socket.on(serverMessages.responses.gameJoined, function({game, gameOptions}) { 
            setGameID(game);
            localStorage.setItem(storageValues.currentGame, game);
            localStorage.setItem(storageValues.gameOptions, JSON.stringify(gameOptions));
        });

        socket.on(serverMessages.responses.gameLeft, function() { 
           // console.log("left game");
            setGameID("");
            localStorage.setItem(storageValues.currentGame, "");
        });

        socket.on(serverMessages.responses.partyJoined, function({partyID, members}) { 
            // console.log("left game");
            localStorage.setItem(storageValues.partyID, partyID);
            setPartyID(partyID);
            setPartyMembers(members);
         });

        socket.on(serverMessages.responses.aiCountChanged, function({enemyAICount, allyAICount}) { 
            let _lastOptions = localStorage.getItem(storageValues.gameOptions);
            if(!_lastOptions || _lastOptions == "undefined" || _lastOptions == "[object Object]"){
                 _lastOptions = {};
            } else {
                _lastOptions = JSON.parse(_lastOptions);
            }
            localStorage.setItem(storageValues.gameOptions, JSON.stringify({..._lastOptions, enemyAICount, allyAICount}));
        });

        
    }, [connecting, isInDiscord && discordInfo]);


    if(isDisconnected && !connecting){
        setConnecting(true);
        setTimeout(()=>{
            // location.reload() is for development.
            // after we reconnect its because the code changes,
            // so reload the JS
            // TURN OFF FOR PROD ===========
            // location.reload();

            
            // this will case the webpage to periodically try and reconnect
            // to the server without reloading the page.
            // TURN ON FOR PROD ===========

            tryReconnect();

            setTimeout(()=>{
                if(isDisconnected) setConnecting(false);
                // if we are still not connected two second after tryReconnect,
                // then set connecting to false, which will trigger the attempt
                // to connect again
            }, 2000);
        }, 3000);
    }


     // attempt to reconnect when disconnected
     if(ioConnection && isDisconnected){
        ioConnection.connect();
        let savedID = localStorage.getItem(storageValues.playerID);
        if(!savedID || savedID === "undefined"){
            savedID = uuidv4();
            localStorage.setItem(storageValues.playerID, savedID);
        }
        
        let savedName  = localStorage.getItem(storageValues.playerName);
        let lastGame  = localStorage.getItem(storageValues.currentGame);
        let lastOptions = localStorage.getItem(storageValues.gameOptions);
        if(lastOptions){
            try{
                lastOptions = JSON.parse(lastOptions);
            } catch(e){}
        } 

        // if we have a url
        if(joinGameID && playerName){
            // leave the last game
            if(lastGame){
                localStorage.setItem(storageValues.currentGame, "");
                ioConnection.emit(serverMessages.requests.leave, {});
            }
            // join the new
            let myDeck = localStorage.getItem(storageValues.playerDeck);
            let myHat = localStorage.getItem(storageValues.myHat);
            let myRobe = localStorage.getItem(storageValues.myRobe);
            if(!myDeck || myDeck === "undefined" || myDeck == "[object Object]"){
                myDeck = StartingDeck();
            } else {
                myDeck = JSON.parse(myDeck);
            }
            setTimeout( () => ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName: joinGameID, playerID: savedID, myName: playerName || savedName, gameOptions: lastOptions, myDeck, myHat, myRobe}),
            300);
        } else if(lastGame != "" && lastGame != undefined){
            let savedName = localStorage.getItem(storageValues.playerName);
            let myDeck = localStorage.getItem(storageValues.playerDeck);
            let myHat = localStorage.getItem(storageValues.myHat);
            let myRobe = localStorage.getItem(storageValues.myRobe);
            if(!myDeck || myDeck === "undefined" || myDeck == "[object Object]"){
                myDeck = StartingDeck();
            } else {
                myDeck = JSON.parse(myDeck);
            }
            ioConnection.emit(serverMessages.requests.joinGame, {gameName: lastGame, playerID: savedID, myName: savedName, myDeck, myHat, myRobe});
            navigate('/game/'+lastGame)
        }  
    }

    return {gameID, setGameID, playerID, playerName, partyID, partyMembers, setPlayerName, ioConnection, isDisconnected}
}