import React, { useEffect, useState, useRef } from 'react';
import './ResourceBar.scss';

function ResourceBar( props ) {

    const { 
        current, 
        castID,
        total,
        previousCurrent,
        className = "reddish",
        perfectFill = true,
        timeToFillBar = 0.5,
        totallyFull = false,
        preFillPercent = 0,
        inverted
    } = props;

    let bars = [];
   // const [timeToFillBarState, set] = useState((timeToFillBar != -1)? timeToFillBar : 888);
    const divRef = useRef();

    if(preFillPercent){
        // honestly, this components needs a rewrite anyway.
        // (just seperate health bar and casting bar lol)
        if(inverted){
            let addedClass = "inverted";
            if(timeToFillBar == -1){
                addedClass = "";
            }
            bars.push(
                <div key={timeToFillBar} // if time to fill changes we force a start over
                    style={{
                        width: (100 - preFillPercent)+"%",
                        animationDuration: parseFloat(timeToFillBar) +'s'
                    }}
                    className={`bar ${className} ${addedClass}`} >
                </div>
            )

            bars.push(
                <div key={0}
                    style={{
                        width: preFillPercent+"%"
                    }}
                    className={`bar ${className} empty`} >
                </div>
            )
        } else {
            bars.push(
                <div key={0}
                    style={{
                        width: preFillPercent+"%"
                    }}
                    className={`bar ${className}`} >
                </div>
            )
    
            bars.push(
                <div key={timeToFillBar} // if time to fill changes we force a start over
                    style={{
                        width: (100 - preFillPercent)+"%",
                        animationDuration: parseFloat(timeToFillBar) +'s'
                    }}
                    className={`bar ${className} dissapating`} >
                </div>
            )
        }
     
    } else {
        let width = Math.ceil(100 / total);
        let remainder = 100 % total;
    
        for(let i = 0; i < total; i++){
            let fullClass = i < current || totallyFull? `${className} filled`: className;
    
            if(i >= current && i < previousCurrent){
                fullClass = ` ${className} dissapating`;
            }
    
            let animationDuration = (parseFloat(timeToFillBar));
            
            bars.push(
                <div key={i}
                    style={{
                        width:width+"%",
                        animationDuration: animationDuration +'s'
                    }}
                    className={`bar ${fullClass}`} >
                </div>
            )
    
        }
        if(current == total && perfectFill){
            bars.push(
                <div key={"last"} ref={divRef}
                    style={{
                        width:remainder+"%",
                        animationDuration: (parseFloat(timeToFillBar) * (remainder/width))+'s' 
                    }}
                    className={`bar ${className} filled`} >
                </div>
            )
        }
    }

    

    return (
        <div className={`ResourceBar ${className}`}>
            {bars}
        </div>
    );
}

export default ResourceBar;
