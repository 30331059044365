



// const maxPlayers = {
//     'teamBattle': {
//        'two': 2,
//        'four': 4,
//         'six': 6
//     },
//     'freeForAll': {
//         'three': 3,
//         'four': 4
//     }
// }


const gameType = {
    teamBattle: 'teamBattle',
    //freeForAll: 'freeForAll'
}
const gameTypeLabels = {
    'teamBattle': 'Team Battle',
   // 'freeForAll': 'Free for All'
}


// const deck = {
//     earthDeck: 'earthDeck',
//     fireDeck: 'fireDeck',
//     iceDeck: 'iceDeck',
//     standard: 'standard',
//     simplified: 'simplified',
//     tutorialDeck: 'tutorialDeck'
// }
// const deckLabels = {
//     'earthDeck': 'Earths Fury',
//     'fireDeck': 'Blazin',
//     'iceDeck': 'Snowstorm',
//     'standard': 'Standard Deck',
//     'simplified': 'Starter Deck',
//     'tutorialDeck': 'Tutorial Deck'
// }



const ai = {
    dummy: 'dummy',
    easy: 'easy',
    medium: 'medium',
    hard: 'hard',
    controllable: 'controllable'
}
const aiLabels = {
    'dummy': 'Dummy (do nothing)',
    'easy': 'Easy AI',
    'medium': 'Medium AI',
    'hard': 'Hard AI'
}


const roundStyle = {
    standard: 'standard',
    builddeckupfront: 'builddeckupfront',
    tutorial: 'tutorial',
    damageTest: 'damageTest'
}

// const roundStyleLabels = {
//     anaconda: 'Anaconda',
//     tutorial: 'Tutorial',
//     simple: '1 of 3 (x3) perminant',
//     standard: '1 of 3 (x3) cycle',
//     threeofsix: '3 of 6 cycle',
//     builddeckupfront: '3 of 6 (x2) permanent',
//     randomDraw: 'Random 10s permanent',
// }


const maxHealth = {
    low: 'low',
    medium: 'medium',
    high: 'high'
}

const maxHealthLabels = {
    low: 'Low',
    medium: 'Medium',
    high: 'High'
}


const fightTime = {
    low: 'low',
    medium: 'medium',
    high: 'high',
    forever: 'forever'
}

const fightTimeLabels = {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    forever: 'Forever'
}


const matchSettingBools = {
    private: 'private',
    customGame: 'customGame',
    changeNameIfTaken: 'changeNameIfTaken'
}




const defaults = {
    // deck: 'standard',
    ai: 'hard',
    roundStyle: 'standard',
    maxHealth: 'medium',
    fightTime: 'medium'
}

// const practiceDefaults = {
//     gameType: 'teamBattle',
//     maxPlayers: 'six',
//     decks: 'standard',
//     aiTypes: 'preset',
//     roundStyle: 'standard'
// }

module.exports = {
    gameType,
    gameTypeLabels,
    //  deck,
    //  deckLabels,
    ai,
    aiLabels,
    roundStyle,
    //  roundStyleLabels,
    maxHealth,
    maxHealthLabels,
    fightTime,
    fightTimeLabels,
    matchSettingBools,
    defaults
};
