
module.exports = {
    
    penetrate: "penetrate", // can't be reflected
    enraged: "enraged",
    selfDamageHeals: "selfDamageHeals",
    unstunnable: "unstunnable", // can't be stunned
    mightGainFireDamage: "mightGainFireDamage",
  
    channeling: "channeling",
    canCastWhileChanneling: "canCastWhileChanneling", // TODO

    // these are here because they don't add states to the state bar-
    // they draw on players as if they are positive states
    frozen: "frozen", 
    noHeals: "noHeals", 
    
    icons:{
        penetrate: "margarita",
        enraged: "vine",
        selfDamageHeals: "bath",
        unstunnable: "whiskey_glass", 
        mightGainFireDamage: "firebonus",
        channeling: "channeling",
        canCastWhileChanneling: "long_island",

        frozen: "freeze",
        noHeals: "smoke",
    }
}