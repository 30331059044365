

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Nature's Strength",
    description: "+2 earth damage.",
    flair: "Sobriety looks good on you.",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 0,
    duration: 8,
    cooldown: 15,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "fist",
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, 
                {
                    buff: buffs.damageTypeBonus,
                    buffParams: [2, spellTypes.earth]
                }, 
                8, 
            ]
        },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.selectedPlayer, 
        //         {
        //             buff: buffs.healBonus,
        //             buffParams: [1, spellTypes.earth]
        //         }, 
        //         10, 
        //     ]
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}

// {
//     action: actions.addTimerConditionPromptEffect,
//     actionParams: [
//         targets.selectedPlayer,
//         {
//           action: actions.gainHealth,
//           actionParams: [targets.selectedPlayer, specialDamageAmounts.triggeringDamageDone],
//           image: "squirrel"
//         }, 
//         [conditions.damageDone, targets.selectedPlayer], // damage done by selected player
//         7
//     ]
//   },

// {
//     action: actions.removeTimer,
//     actionParams: [targets.selectedPlayer]
// },
// {
//     action: actions.addTimerState,
//     actionParams: [
//       targets.selectedPlayer, 
//       stateBuffs.silence, 
//       3,
//     ],
// }