import React, {useState, useEffect} from 'react';
import Spell from './Spell'
import './SpellList.scss'
import { myHat } from '../../../storageValues';
let targets = require('shared/cardConstants/targets');
const {Items} = require("shared/decks/providedDecks");
const AllSpells = require("shared/decks/available");


function SpellList( props ) {

    const {
        spells = [],
        items = [],
        selectedSpellIndex,
        setSelectedSpellIndex,
        // castSpell,
        autoCastingOn,
        isLobby,
        spellQueue = [],
        //onSelect = ()=>{},
        owner = {},
        cardChoices = {},
        isFighting,
        castingIndex = -1,
        countdown,
        selectedAlly,
        selectedEnemy,
        spellsCasted = {},
        spellsCastingWhenReady = [],
        setSpellsCastingWhenReady,
        setCardBoundingBoxes,
        setSpellsCasted,
       // selectedPlayer,
        isDisplayList,
        nextCastSpellIndex,
        topRow,
        tutorial,
        animated,
        makeSpaceAboveSpells,
        onClickSpell = () => {},
        disabledAppended = []
    } = props;

    const [showingItems, setShowingItems] = useState(false);

    let realSpells = [...spells];
    

    function getSpellComponent(spellData, i, isSmaller, isItem, disabled){
        let canAutoCastFromSelectedPlayer = false;

        if(spellData.flipToItems){
            return <div className={`Spell ${isSmaller?'smaller':''} `} onClick={()=>{
                if(showingItems) setShowingItems(false);
                else setShowingItems(true);
            }}>
                <div className="imageArea icon">
                    {!showingItems && <div className="material-symbols-outlined">
                        local_bar
                    </div>}
                    {showingItems && <div className="material-symbols-outlined">
                        playing_cards
                    </div>}
                </div>
            </div>
        }

        // if(selectedPlayer && selectedPlayer.isEnemy && spellData.target == targets.selections.targetEnemy){
        //     canAutoCastFromSelectedPlayer = true;
        // }
    
        // if(selectedPlayer && selectedPlayer.isAlly && spellData.target == targets.selections.targetAlly){
        //     canAutoCastFromSelectedPlayer = true;
        // }
    
        // if(selectedPlayer && selectedPlayer.isMe && spellData.target == targets.selections.targetSelf){
        //     canAutoCastFromSelectedPlayer = true;
        // }

        // leaving this even though it should already be removed...
        if(spellsCasted[i] && spellData.onCooldown){
            setSpellsCasted({type:"remove", index: i});
        }

        // if spell data is in the queue, make sure its marked as casted
        // if(!spellsCasted[i] && spellData.isInQueue){
        //     setSpellsCasted({type:"add", index: i});
        // }

        // once a spell is casted, remove it from cast when ready
        // if(spellsCastingWhenReady[i] && spellsCasted[i]){
        //     setSpellsCastingWhenReady({type:"remove", index: i});
        // }

        let index = i;
        let charges = 2;
        if(isItem){
            //index = spells.length + i - 1;
            //console.log(index);
            // I suppose that 8, 9, 10 are special spells.
            // 8 will always be water, 
            // 9 will always be rum,
            // 10 will always be tequila.

            // I need to render empty space here
            // for spells that I don't have
        }

        return <Spell 
            key={index}
            isSelected={selectedSpellIndex == index && !isFighting}
           //isNext={nextCastSpellIndex == i}
            isChosen={cardChoices[index]}
            canAutoCastFromSelectedPlayer={canAutoCastFromSelectedPlayer}
            isCasted={spellsCasted[index]}
            isFighting={isFighting}
            index={index}
            animated={animated}
            isCastingWhenReady={spellsCastingWhenReady.includes(index)}
            setCardBoundingBoxes={setCardBoundingBoxes}
            onClick={()=> {
                onClickSpell(index); 
            }}
            spellData={spellData}
            otherSpellSelected={selectedSpellIndex != -1 && selectedSpellIndex != index}
            clientIsCasting={castingIndex != -1? castingIndex == index : spellQueue && spellQueue[spellQueue.length - 1] == index}
            isCastingAny={spellQueue && spellQueue.length > 0}
            owner={owner}
            autoCastingOn={autoCastingOn}
            isDisplayList={isDisplayList}
            isSmaller={isSmaller}
            disabled={disabled}
        />
    }

    function SpellListRow(spells, min, max, isItem, smaller = true, index, disabled){
        return  <div className={`SpellListRelative ${smaller?'smaller':''}`} key={index}>
        { // change this to operate on a sub list for preformance
            spells.map((spellData, i) => {
                if(i < min || i >= max) return; 
                return getSpellComponent(spellData, i, smaller, isItem, disabled);
            })
        }
        </div>
    }

    function SpellListAllRows(mySpells, rowCount, items, hasItemSwitchButton){
        let allRows = [];
        let i = 0;

        let spells = [...mySpells];

        if(hasItemSwitchButton) spells.push({flipToItems: true});

        let hasItems = false;
        // if(items){
        //     items.map(item => {
        //         if(item != null){
        //             hasItems = true;
        //         }
        //     })
        // }
        let isSmaller = spells.length > 9 || hasItems;

        let disabledStart = spells.length;
        spells = spells.concat(disabledAppended);
        
        if(!hasItemSwitchButton && spells.length > 0 && spells.length < 5){
            return SpellListRow(spells, 0, spells.length, false, isSmaller, 0 );
        }
        // else 
        
        // couple special cases for 6, 6 and 7 cards
        if(rowCount == 4 && spells.length == 5){
            rowCount = 3;
            allRows.push(SpellListRow(spells, 0, 2, false, isSmaller, allRows.length));
            i = 2;

        } else if(rowCount == 4 && spells.length == 6){
            rowCount = 3;
        }
        else if(rowCount == 4 && spells.length == 7){
            rowCount = 4;
            allRows.push(SpellListRow(spells, 0, 3, false, isSmaller, allRows.length));
            i = 3;
        }
        // this is where we add the rows.
        // if we have some leftover, we need an extra row.
        let leftover = (spells.length > 8)? spells.length % rowCount : 0;

        for(; i <= spells.length - rowCount; i+=rowCount){
            allRows.push(SpellListRow(spells, i, i+rowCount, false, rowCount > 3 || isSmaller, allRows.length, i >= disabledStart));
        }

        if(leftover != 0){
            allRows.push(SpellListRow(spells, i, i+leftover, false, rowCount > 3 || isSmaller, allRows.length, i >= disabledStart));
        }

        if(items){
          
            allRows.push(<div className={`SpellListRelative smaller`}>
            { // change this to operate on a sub list for preformance
              
                items.map((item, i)=>{
                    if(!item) return <div style={{width:"calc(10vh + 2%)"}} key={i}/>;
                    else {
                        return getSpellComponent(item, realSpells.length+i, true);
                    }
                    //if()
                    // let found = false;
                    // let charges = 0;
                    // for(let i = 0; i < items.length; i++){
                    //     if(items[i]?.name == key){
                    //         found = true;
                    //         charges = items[i].charges;
                    //         break;
                    //     }
                    // }

                    // if(found){
                    //     return getSpellComponent({...AllSpells[key], charges}, spells.length+i, true);
                    // } else {
                    //     return <div style={{width:"11vh"}}/>
                    // }
                   
                })
            }
            </div>);
        }
        if(hasItemSwitchButton && spells.length < 5){
            allRows.push(SpellListRow(spells, 0, spells.length, false, isSmaller, 0 ));
        }
        return allRows;
    }

    let classname = spells.length <= 9?'lower':'';
    if(tutorial){
        classname = 'tutorial'
    }


    if(spells  && !isFighting){

        return <div className={`ScrollableSpellList ${ classname }`}>

            { SpellListAllRows(spells, 3) }

            {/* <div className={`SpellListRelative ${ spells.length > 9?'smaller':''}`}>
            { // change this to operate on a sub list for preformance
                spells.map((spellData, i) => {
                    if(i >= 3) return; 
                    return getSpellComponent(spellData, i, spells.length > 9);
                })
            }
            </div>
            <div className={`SpellListRelative ${ spells.length > 9?'smaller':''}`}>
            {
                spells.map((spellData, i) => {
                    if(i < 3 || i >= 6) return; 
                    return getSpellComponent(spellData, i, spells.length > 9);
                })
            }
            </div>
            <div className={`SpellListRelative ${ spells.length > 9?'smaller':''}`}>
            {
                spells.map((spellData, i) => {
                    if(i < 6 || i >= 9) return; 
                    return getSpellComponent(spellData, i, spells.length > 9);
                })
            }
            </div>
            <div className={`SpellListRelative ${ spells.length > 9?'smaller':''}`}>
            {
                spells.map((spellData, i) => {
                    if(i < 9) return; 
                    return getSpellComponent(spellData, i, spells.length > 9);
                })
            }
            </div> */}
        
        </div>
        
    } else if (spells) {

        return <div className={`SpellListArea ${makeSpaceAboveSpells?'makeSpaceAbove':''}`}>
             {!showingItems && SpellListAllRows(spells, 4, [], items && items.filter(i=>i).length > 0) }
             {showingItems && SpellListAllRows([], 4, items, true) }
            {/* <div className={`SpellList ${ spells.length > 7?'smaller':''} ${topRow?'topRow':''} ${isDisplayList?'currentHand':''}`}>
            {
                spells.map((spellData, i) => {
                    if(i >= 4) return; 
                    if(spells.length <= 7 && i >= 3) return;
                    return getSpellComponent(spellData, i, spells.length > 7);
                })
            }
            </div>
            <div className={`SpellList ${ spells.length > 6?'smaller':''} bottom ${isDisplayList?'currentHand':''}`}>
            {
                spells.map((spellData, i) => {
                    if(i < 3) return; 
                    if(spells.length > 7 && i < 4) return;
                    return getSpellComponent(spellData, i, spells.length > 6);
                })
            }
            </div> */}
        
        </div>
    } else {
        return null;
    }
}

export default SpellList;