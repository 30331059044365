
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Avalanche",
    description: "+4 ice-weakness to the target.",
    spellType: spellTypes.ice,
    level: 2,
   // charges: 2,
    castTime: 3,
    cooldown: 30,
    damage: 0,
    duration: 10,
    damageType: spellTypes.ice,
    target: targets.selections.targetEnemy,
    image: "avalanche",
    effectNumber: "/4",
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: debuffs.damageTypeWeakness, 
                    buffParams: [4, spellTypes.ice]
                }, 10
            ],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}