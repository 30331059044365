
import React from 'react';
import BigButton from '../../../menu/components/BigButton';
const spellTypes = require("shared/cardConstants/spellTypes");
import {Tabs} from '../CardSelectionPage'
const AllCards = require("shared/decks/available");
const {actionCosts} = require("shared/cardConstants/costs")
const {Armor} = require("shared/decks/providedDecks");
let ArmorList = Armor();

export default function ActionButton(props){

    const {
        me,
        currTab,
        selectedCardIndex,
        localSpellChoices,
        choicesLeft,
        itemCount,
        chooseSpell,
        buySelectedItem,
        buySelectedGear,
        showDraw3,
        showRedraw,
        buyDraw3,
        buyRedraw
    } = props;

    if(currTab == Tabs.spells){
        if(me.ready){
            return <div className="ActionMessage">
            </div>
        }

        if(showDraw3){
            if(me.currentMonies < actionCosts.draw3){
                return <div className="ActionMessage">
                    Too broke
                </div>
            }
            return <div className="ActionButton">
                <div className="addButton">
                    <BigButton 
                        red
                        onClick={()=>{
                            buyDraw3();
                        }} >
                            Buy
                    </BigButton>
                </div>
            </div>
        } else if(showRedraw){
            if(!me.canRedraw){
                return <div className="ActionMessage">
                    Too broke
                </div>
            }
            return <div className="ActionButton">
                <div className="addButton">
                    <BigButton 
                        red
                        onClick={()=>{
                            buyRedraw();
                        }} >
                            Buy
                    </BigButton>
                </div>
            </div>
        } else if(localSpellChoices[selectedCardIndex]){
            // Remove spell button
            return <div className="ActionButton">
                <div className="removeButton" onClick={()=>{
                    chooseSpell(selectedCardIndex);
                }}>
                    <div className="icon">
                        <span className="material-symbols-outlined">
                            remove
                        </span>
                    </div>
                    <div className="text">
                        Remove
                    </div>
                </div>
            </div>
        } else if(selectedCardIndex != -1 && selectedCardIndex < me.spellsToChooseFrom.length &&
                        // if a weapon type, check item slots.  
                        ((me.spellsToChooseFrom[selectedCardIndex]?.spellType == spellTypes.weapon && itemCount < 4) ||
                        // if its not a weapon, check choicesLeft
                        (me.spellsToChooseFrom[selectedCardIndex]?.spellType != spellTypes.weapon && choicesLeft))){
                        // Add spell button

            return <div className="ActionButton">
                <div className="addButton">
                    <BigButton 
                        green={me.spellsToChooseFrom[selectedCardIndex].spellType == spellTypes.earth}
                        blue={me.spellsToChooseFrom[selectedCardIndex].spellType == spellTypes.ice}
                        red={me.spellsToChooseFrom[selectedCardIndex].spellType == spellTypes.fire }
                        onClick={()=>{
                            chooseSpell(selectedCardIndex);
                        }} >
                            Add
                    </BigButton>
                </div>
            </div>
        } else if(selectedCardIndex != -1 && selectedCardIndex < me.spellsToChooseFrom.length) {
            return <div className="ActionMessage">
                Hand full
           </div>
        }
    } else if(currTab == Tabs.potions){

        if(selectedCardIndex != -1)
        {
            // PROBLEM HERE
            let itemKey = me.availableItems[selectedCardIndex];
            let itemData = {...AllCards[itemKey]};
            let handIndex = -1;
            let k = -1;
            let hasInHand = me.items.reduce((accum, curr) =>{
                k++;
                if(accum) return true;
                else if (!curr) return false;
                else if (curr.name == itemKey){
                    handIndex = k;
                    return true;
                } 
                else return false;
            }, false);

            // Buy potion button
            if(itemCount >= 4 && !hasInHand){
                return <div className="ActionMessage">
                    Hand full
                </div>
            }
            if(me.currentMonies < itemData.cost || (itemData.armorCost && me.currentArmors < itemData.armorCost)){
                return <div className="ActionMessage">
                    Too broke
                </div>
        
            // }  If current potion + gear count is 4
            // else if(me.currentMonies < itemData.cost){
            //    return <div className="ActionMessage">
            //        Hand full
            //    </div>
        
            } else {
                return <div className="ActionButton">
                    <div className="addButton">
                        <BigButton 
                            red
                            onClick={()=>{
                                buySelectedItem();
                            }} >
                                Buy
                        </BigButton>
                    </div>
                </div>
            }
        }
    } else if(currTab == Tabs.armor){

        if(selectedCardIndex != -1)
        {
            let itemKey = ArmorList[selectedCardIndex];
            let itemData = {...AllCards[itemKey]};
            // Buy potion button
            if(me.currentMonies < itemData.cost){
                return <div className="ActionMessage">
                    Too broke
                </div>
        
            // }  If current potion + gear count is 4
            // else if(me.currentMonies < itemData.cost){
            //    return <div className="ActionMessage">
            //        Hand full
            //    </div>
        
            } else {
                return <div className="ActionButton">
                    <div className="addButton">
                        <BigButton 
                            red
                            onClick={()=>{
                                buySelectedGear();
                            }} >
                                Buy
                        </BigButton>
                    </div>
                </div>
            }
        }
    }

    return null;
}

