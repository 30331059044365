
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Espresso Martini",
    description: "Reduce cast time by 0.5s, cooldowns by 1s.",
    spellType: spellTypes.item,
    charges: 1,
    level: 1,
    castTime: 0,
    damage: 0,
    duration: 40,
    target: targets.selections.targetSelf,
    image: "esspresso_martini",
    item: true,
    cost: 3,
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, {
                    buff: buffs.fasterCastTime, 
                    buffParams: [0.5]
                }, 40
            ],
        },
        {
          action: actions.addTimerBuff,
          actionParams: [
              targets.me, {
                  buff: buffs.reducedCooldown, 
                  buffParams: [1]
              }, 40
          ],
      }
    ],
    xpGain: 0,
    manaCost: 1,
}