
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');



module.exports = {
    name: "Lost Tome of Sloshed Spellcraft",
    description: "Reduce cast time by 0.5 seconds.",
    spellType: spellTypes.weapon,
    level: 1,
    notCastable: true,
    canHoldMultiple: false,
    noDiscardDeck: true,
    image: "tome",
    effects: [
        {
            buff: buffs.fasterCastTime, 
            buffParams: [0.5]
        }
    ]
  
}