
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
let silksash = require('./silksash');
let pendant = require('./pendant');
let pants = require('./pants');
let lantern = require('./lantern');

module.exports = {
    name: "Leather Belt of Big Bucks",
    description: "+1 money each round.",
    spellType: spellTypes.armor,
    level: 3,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    extraRoundMoney: 1,
    requires: pants.name,
    upgradesTo: silksash.name,
    image: "belt",
}