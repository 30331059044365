
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const chestplate = require('./chestplate');



module.exports = {
    name: "Hanging Lantern of Lit Hangs",
    description: "Gain 20 health after each round.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "lantern",
   // upgradesTo: chestplate.name,
   requires: "Silk Sash of Fat Stacks",
    effects: [
        {
            buff: buffs.gainHealthEachRound, 
            buffParams: [30]
        }
    ]
}