import React, { useState, useEffect, useReducer } from 'react';
import imageMap from '../../images/index.js'

import IntroPage from './IntroPage'
import DeckPage from './DeckPage'
import ProfilePage from './ProfilePage'
import PlayPage from './PlayPage'

import Wastey from './components/Wastey.js';
import FooterToolbar from './components/FooterToolbar';

import useGlitters from '../hooks/useGlitters.js';
import useGameOptions from '../hooks/useGameOptions.js';
import useProfile from '../hooks/useProfile.js';
import useGameCreator from '../hooks/useGameCreator.js';
import chooseOneToAdd from '../../../shared/rounds/chooseOneToAdd.js';

window.Pages = {
    NewUser: 0,
    Battle: 1,
    Deck: 2,
    Profile: 3,
    Settings: 4
};

export default function MainMenuPage(props){

    const {
        playerID,
        ioConnection,    
        playerName,
        setPlayerName,
        joinGameID,
        partyID,
        partyMembers,
        discordInfo
    } = props;    

    const glitters = useGlitters();

    const gameOptions = useGameOptions(playerID);
    const {
        allyAI, setAllyAI,
        enemyAI, setEnemyAI,
        roundStyle, setRoundStyle,
        maxHealth, setMaxHealth,
        fightTime, setFightTime,
        gameName, setGameName,
     } = gameOptions;

    const profileOptions = useProfile(ioConnection, playerID);
    const {
        myDeck, setDeck,
        myHat, setHat,
        myRobe, setRobe
     } = profileOptions;

    const [battleAnimationPlaying, setBattleAnimationPlaying] = useState(false);

    let isNewPlayer = !playerName || playerName == "null";

    const [currentPage, setCurrentPage] = useState(isNewPlayer? Pages.NewUser :Pages.Battle);
    const [pushTutorial, setPushTutorial] = useState();

    useEffect(()=>{
        if(selectedCard != null) setSelectedCard(null);
    }, [currentPage]);

    const onStart = () => setBattleAnimationPlaying(true);
    const {
        createGame,
        createPracticeGame,
        createTutorialGame,
        createBearChallenge,
        createIceChallenge,
        createFireChallenge
     } = useGameCreator(playerID, partyID, ioConnection, gameOptions, profileOptions, onStart, setGameName);


    const [soundsOnState, setSoundsOnState] = useState(window.soundsOn);

    const [selectedCard, setSelectedCard] = useState();

    const [showInviteText, setShowInviteText] = useState(false);

    return <React.Fragment>

        {glitters}

        {currentPage == Pages.NewUser && 
            <IntroPage
                ioConnection={ioConnection}
                playerName={playerName}
                setPlayerName={setPlayerName} 
                myHat={myHat}
                setHat={setHat}
                myRobe={myRobe}
                setRobe={setRobe}
                joinGameID={joinGameID}
                onFinish={()=>{
                    setCurrentPage(Pages.Battle);
                    setPushTutorial(true);
                }}
            />
        }

        { currentPage != Pages.NewUser && 
            <div className="MenuPage">

                { soundsOnState && 
                    <div className={"soundButton"}>
                        <span className="material-symbols-outlined" style={{color:"#eeeeee"}} onClick={()=>{
                            setSoundsOnState(false);
                            window.soundsOn = false;
                        }}>
                            volume_up
                        </span>
                    </div>
                }

                { !soundsOnState && 
                    <div className={"soundButton"}>
                        <span className="material-symbols-outlined" style={{color:"#eeeeee"}} onClick={()=>{
                            checkSounds();
                            setSoundsOnState(true);
                            window.soundsOn = true;
                            window.Sounds.softclick.play();
                        }}>
                            volume_off
                        </span>
                    </div>
                }

                <div className={"inviteArea"}>
                   { <div className={`inviteText ${showInviteText?'show':''}`}>
                        Link Copied!
                    </div>}
                   { <div className={"inviteButton"}>
                        <span className="material-symbols-outlined" style={{color:"#eeeeee"}} onClick={()=>{
                            setShowInviteText(true);
                            setTimeout(()=> setShowInviteText(false), 2000);
                            window.copyToClipboard(location.protocol + '//' + location.host + '/room/' + partyID);
                        }}>
                            group_add
                        </span>
                    </div>}
                </div>
                <div className={"partyArea"}>
                    <div className={"partyMembers"}>
                        {
                            partyMembers.map(info => {
                                if(info.playerID  == playerID) return null;
                                return <div className="partyMember">
                                    <div className="partyMemberName">
                                        {info.name || "..."}
                                    </div>
                                    <div className="partyMemberHatWrapper">
                                       {info.hat && <img className="partyMemberHat" src={imageMap[info.hat]}/>}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                
                <div className={"wizardArea"}>
                    <Wastey 
                        hat={myHat} 
                        robe={myRobe}
                        selectedCard={selectedCard}
                    />
                </div>

                { currentPage == Pages.Battle && <div className={"nameArea"}>
                        <img className="gameName" src={imageMap["gameName"]} />
                    </div>
                }

                <div className="mainMenuArea">
                    { currentPage == Pages.Battle &&
                        <PlayPage 
                            createGame={createGame} 
                            createPracticeGame={createPracticeGame} 
                            createBearChallenge={createBearChallenge}
                            createIceChallenge={createIceChallenge}
                            createFireChallenge={createFireChallenge}
                            pushTutorial={pushTutorial}
                            discordInfo={discordInfo}
                            playerName={playerName}
                            playerID={playerID}
                            partyID={partyID}
                        />
                    }
                    { currentPage == Pages.Profile &&
                        <ProfilePage 
                            myHat={myHat} 
                            myRobe={myRobe} 
                            setHat={setHat} 
                            setRobe={setRobe}
                        />
                    }
                    { currentPage == Pages.Deck &&
                        <DeckPage 
                            myDeck={myDeck} 
                            setDeck={setDeck}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                        />
                    }
                </div>

                <FooterToolbar 
                    currentPage={currentPage} 
                    setCurrentPage={setCurrentPage}
                />
            </div>
        }
    </React.Fragment>
    
}
