
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

// let name = "Wild Flame";

module.exports = {
  name: '"Heal"',
  description: "Heal +5 health.",
  //flair: 'You know, "medicine"',
  spellType: spellTypes.earth,
  level: 1,
 // charges: 1,
  castTime: 2,
  cooldown: 10,
  duration: 3,
  damage: 0,
  target: targets.selections.targetAlly,
  image: "weed",
 // bonusState: "squirrel",
  effectNumber: "+4/2",
  effects: [
    {
      action: actions.gainHealth,
      actionParams: [targets.selectedPlayer, 4]
    },
    {
      action: actions.addTimerPeriodicEffect,
      actionParams: [
          targets.selectedPlayer,
          {
              action: actions.gainHealth,
              actionParams: [2],
              image: "weed2"
          },
          2, 
          2
      ],
    }
  ],
    // {
    //   action: actions.addTimerPeriodicEffect,
    //   actionParams: [
    //       targets.selectedPlayer,
    //       {
    //           action: actions.gainHealth,
    //           actionParams: [1],
    //           image: "weed"
    //       },
    //       3, 
    //       20
    //   ],
    // }

  xpGain: 0,
  manaCost: 1,
}
