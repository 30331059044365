
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const pants = require('./pants');
const silksash = require('./silksash');



module.exports = {
    name: "Tights of Titilation",
    description: "Draw 1 extra item each round.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "trousers",
   // requires: "Silk Sash of Fat Stacks",
    upgradesTo: "Elven Chained Pants of Evermore Raging",
    effects: [
        {
            buff: buffs.drawExtraItems, 
            buffParams: [1]
        }
    ]
}