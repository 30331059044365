import React, {useState} from 'react';

import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    Link
  } from "react-router-dom";

import App from './App.js'

export default function Router() {
    return (
      <BrowserRouter>
          <Routes>
  
            <Route path="/" element={ <App/> } />

            <Route path="/game/:joinGameID" element={ <App/> } />

            <Route path="/room/:joinRoomID" element={ <App/> } />

            <Route path="/party/:joinRoomID" element={ <App/> } />
              
          </Routes>
      </BrowserRouter>
    )
  }