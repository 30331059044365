

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Shatter",
    description: "Deal 2x your ice weakness as ice damage to all enemies.",
    flair: "",
    spellType: spellTypes.ice,
    image: "shatter",
    level: 2,
   // charges: 1,
    castTime: 1,
    cooldown: 20,
    duration: 0,
    damage: 0,
    target: targets.selections.targetEnemies,
    bonusState: "damageTypeWeakness/ice",
    effectNumber: "2x",
    effects: [
        {
            action: actions.damage, // damage: ( target, damage, type)
            actionParams: [
                targets.allEnemies, 
                [specialDamageAmounts.timerCountMultiple, targets.me, 2, "damageTypeWeakness/ice"], spellTypes.ice],
        },
        {
            action: actions.removeTimer,
            actionParams: [targets.me, "damageTypeWeakness/ice"]
        },
    ],
    xpGain: 0,
    manaCost: 1,
}