
module.exports = {
    
    stun: "stun",
    wildflame: "wildflame",
   
    
    icons:{
        stun: "stun",
        wildflame: "fireball",
    }
}