
let spellTypes = require('./spellTypes');

module.exports = {
    damageTypeWeakness: "damageTypeWeakness", 

    icons: {
        damageTypeWeakness: {
          [spellTypes.ice]: "frost",
        }
    }
}