
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const belt = require('./belt');



module.exports = {
    name: "Silk Sash of Fat Stacks",
    description: "+3 money each round.",
    spellType: spellTypes.armor,
    level: 4,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    extraRoundMoney: 3,
   // extraRoundArmor: 1,
    image: "sash",
    upgradesFrom: "Leather Belt of Big Bucks"
}