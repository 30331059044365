
import React, {useState} from 'react';
import imageMap from '../../../images/index.js'

export default function ImageSelector({list, current, onSelect, smaller}){

    return <div className="ImageSelector">
        {list.map((name) => {
            return <div onClick={()=>{if(soundsOn)Sounds.softclick.play(); onSelect(name)}} className={`SelectItem ${current==name?'selected':''}`} key={name}>
                {current==name && <div className="underline" /> }
                <img className={`SelectItemImage ${smaller?'smaller':''}`} src={imageMap[name]} />
            </div>
        })}
    </div>
}