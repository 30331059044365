

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Drain Life",
    description: "Do damage and gain health equal 2x target's ice-weakness.",
    flair: "",
    spellType: spellTypes.ice,
    image: "drainlife",
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 20,
    duration: 0,
    damage: 0,
    target: targets.selections.targetEnemy,
    bonusState: "damageTypeWeakness/ice",
    effectNumber: "2x",
    effects: [
        { // 1 ice damage
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 0, spellTypes.ice],
        },
        { // 1 ice damage
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 0, spellTypes.ice],
        },
        {
            action: actions.gainHealth, 
            actionParams: [
                targets.me, 
                [specialDamageAmounts.timerCountMultiple, targets.selectedPlayer, 2, "damageTypeWeakness/ice"]],
        },
        // {
        //     action: actions.damage, 
        //     actionParams: [
        //         targets.selectedPlayer, 
        //         [specialDamageAmounts.timerCountMultiple, targets.selectedPlayer, 1, "damageTypeWeakness/ice"], spellTypes.ice],
        // },
        // {
        //     action: actions.removeTimer,
        //     actionParams: [targets.selectedPlayer, "damageTypeWeakness/ice"]
        // },
    ],
    xpGain: 0,
    manaCost: 1,
}