import React, {useState, useEffect} from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import imageMap from '../images/index.js'

import MainMenuPage from './menu/MainMenuPage.js'
import GamePage from './game/GamePage.js'
import Disconnected from './menu/components/Disconnected.js'
import useIOConnection from './hooks/useIOConnection.js'
import useDiscord from './hooks/useDiscord.js';

import './menu/MenuStyles.scss'

export default function App(){

    const [loading, setLoading] = useState(true);

    // preload images
    useEffect(() => {
          const imagesPromiseList = []
          for (const i of Object.values(imageMap)) {
            imagesPromiseList.push(preloadImage(i))
          }
         Promise.all(imagesPromiseList).then(()=> setLoading(false))
      }, [])

    const [discordInfo, setDiscordInfo] = useState(null);

    const isInDiscord = useDiscord(setDiscordInfo);

    const {joinGameID, joinRoomID} = useParams();

    // setup connection to backend
    const {gameID, setGameID, playerID, playerName, partyID, partyMembers, setPlayerName, ioConnection, isDisconnected} = useIOConnection(isInDiscord, discordInfo, joinGameID, joinRoomID); 

    // if discord name is different than saved name, discord name should win

    if(discordInfo && discordInfo.playerData && (discordInfo.playerData.global_name || discordInfo.playerData.username)){
      let discordName = discordInfo.playerData.global_name || discordInfo.playerData.username;
      if(discordName != playerName){
        setPlayerName(discordName);
      }
    }

    // Theme is really just used for the BigButtons... (material-ui)
    return  <React.Fragment>
        { isDisconnected && // if disconnected, show message and try reconnecting
            <Disconnected ioConnection={ioConnection} />
        }

        { playerID && gameID && // if joining a game, show game page
            <GamePage  
                playerID={playerID}
                ioConnection={ioConnection} 
                gameID={gameID}  
                setGameID={setGameID}   
                playerName={playerName} 
                discordInfo={discordInfo}  
                partyID={partyID}                                                                                                             
            />
        }
        { !playerID || !gameID && // or else show the menu page
            <MainMenuPage 
                joinGameID={joinGameID}
                playerID={playerID}
                ioConnection={ioConnection} 
                gameID={gameID} 
                playerName={playerName}
                setPlayerName={setPlayerName}
                partyID={partyID}
                partyMembers={partyMembers}
                discordInfo={discordInfo}
            />
        }
    </React.Fragment>

    // } else if(gameID){
    //     return  <ThemeProvider theme={theme}>
    //         {!!isDisconnected && <Disconnected ioConnection={ioConnection} />}
    //         <GamePage  
    //             playerID={playerID}
    //             ioConnection={ioConnection} 
    //             isDisconnected={isDisconnected} 
    //             gameID={gameID}  
    //             setGameID={setGameID}   
    //             clientName={playerName}  
    //             currentLevel={currentLevel}            
    //             setCurrentLevel={setCurrentLevel}                                                                                                              
    //         />
    //     </ThemeProvider>

    // } else if(ioConnection) {

    //     //if(playerName && playerName != "null"){
    //         return  <ThemeProvider theme={theme}>
    //             {!!isDisconnected &&
    //                 <div className="persistantMessage">Disconnected</div>
    //             }
              
    //             <MenuRouter 
    //                 playerName={playerName}
    //                 gameID={gameID} 
    //                 playerID={playerID}
    //                 ioConnection={ioConnection} 
    //                 isDisconnected={isDisconnected} 
    //                 currentLevel={currentLevel}
    //             />
    //         </ThemeProvider>
    //     //} else {
    //         return <ThemeProvider theme={theme}>
    //             {!!isDisconnected &&
    //                 <div className="persistantMessage">Disconnected</div>
    //             }
    //             { <IntroPage 
    //                 gameID={gameID} 
    //                 playerID={playerID}
    //                 ioConnection={ioConnection} 
    //                 isDisconnected={isDisconnected} 
    //             />}
    //         </ThemeProvider>
    //     //}

    //     return  <ThemeProvider theme={theme}>
    //         {!!isDisconnected &&
    //             <div className="persistantMessage">Disconnected</div>
    //         }
    //         { playerName && playerName != "null" && <MenuRouter 
    //             gameID={gameID} 
    //             playerID={playerID}
    //             ioConnection={ioConnection} 
    //             isDisconnected={isDisconnected} 
    //             currentLevel={currentLevel}
    //         />}
    //          { (!playerName || playerName == "null") && <div className="introArea">
    //                 <div className="introText">
    //                     Are you over 18?<br/><br/>
    //                     If yes, enter your name below:<br/>
    //                 </div> 
    //                 <div className="nameField">
    //                 <TextField
    //                     label={""}
    //                     value={nameField}
    //                     error={""}
    //                     helperText={""}
    //                     onChange={(e) => {
    //                         setNameField(e.target.value)
    //                     }}
    //                 />
    //                 </div>
    //                 <div className="nameField">
    //                 <BigButton 
    //                     green 
    //                     disabled={!nameField}
    //                     onClick={()=>{ 
    //                         setPlayerName(nameField)
    //                         if(joinGameID){
    //                             let savedID = localStorage.getItem(storageValues.playerID);
    //                             let myDeck = localStorage.getItem(storageValues.playerDeck);
    //                             let myHat = localStorage.getItem(storageValues.myHat);
    //                             let myRobe = localStorage.getItem(storageValues.myRobe);
    //                             if(!myDeck || myDeck === "undefined" || myDeck == "[object Object]"){
    //                                 myDeck = BasicDeck();
    //                             } else {
    //                                 myDeck = JSON.parse(myDeck);
    //                             }
    //                             ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName: joinGameID, playerID: savedID, myName: nameField, myDeck, myHat, myRobe})
    //                         }

    //                     }} >
    //                         Let's go!
    //                 </BigButton>
    //                 </div>
    //             </div> 
    //          }
    //     </ThemeProvider>
    // }
}

/**
 * Copy a string to clipboard
 * @param  {String} string         The string to be copied to clipboard
 * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
 * @see https://stackoverflow.com/a/53951634/938822
 */
window.copyToClipboard = (string)=> {
    let textarea;
    let result;
  
    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', true);
      textarea.setAttribute('contenteditable', true);
      //textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;
  
      document.body.appendChild(textarea);
  
      textarea.focus();
      textarea.select();
  
      const range = document.createRange();
      range.selectNodeContents(textarea);
  
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
  
      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    } catch (err) {
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }
  
    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  }

import cheeringSound from '../sounds/cheering.mp3'
let cheering = new Audio(cheeringSound);
import healSound from '../sounds/heal.mp3'
let heal = new Audio(healSound);
import trumpetSound from '../sounds/trumpets.mp3'
let trumpet = new Audio(trumpetSound);
import threeSound from '../sounds/three.mp3'
let three = new Audio(threeSound);
import twoSound from '../sounds/two.mp3'
let two = new Audio(twoSound);
import oneSound from '../sounds/one.mp3'
let one = new Audio(oneSound);
import hitfireSound from '../sounds/hitfire.mp3'
let hitfire = new Audio(hitfireSound);
import hiticeSound from '../sounds/hitice.mp3'
let hitice = new Audio(hiticeSound);
import hitearthSound from '../sounds/hitearth.mp3'
let hitearth = new Audio(hitearthSound);
import ouchSound from '../sounds/ouch.mp3'
let ouch = new Audio(ouchSound);
import deathSound from '../sounds/death.mp3'
let death = new Audio(deathSound);
import whooshSound from '../sounds/whoosh.mp3'
let whoosh = new Audio(whooshSound);
import reflectSound from '../sounds/whoosh2.mp3'
let reflect = new Audio(reflectSound);
import clickSound from '../sounds/click.mp3'
let click = new Audio(clickSound);
import softClickSound from '../sounds/softclick.mp3'
let softclick = new Audio(softClickSound);

window.Sounds = {
    cheering,
    heal,
    trumpet,
    three,
    two,
    one,
    hitfire,
    hitice,
    hitearth,
    ouch,
    whoosh,
    death,
    reflect,
    click,
    softclick
}
let soundsLoaded = false;
window.soundsOn = true;
window.checkSounds = () => {
    if(!soundsLoaded){
        cheering.play();
        cheering.pause();
        heal.play();
        heal.pause();
        trumpet.play();
        trumpet.pause();
        three.play();
        three.pause();
        two.play();
        two.pause();
        one.play();
        one.pause();
        hitfire.play();
        hitfire.pause();
        hitice.play();
        hitice.pause();
        hitearth.play();
        hitearth.pause();
        ouch.play();
        ouch.pause();
        death.play();
        death.pause();
        whoosh.play();
        whoosh.pause();
        reflect.play();
        reflect.pause();
        click.play();
        click.pause();
        softclick.play();
        softclick.pause();
    }
    soundsLoaded = true;
}

import {
    useParams,
  } from "react-router-dom";



function preloadImage (src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = function() {
        resolve(img)
      }
      img.onerror = img.onabort = function() {
        reject(src)
      }
      img.src = src
    })
}