
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

// let name = "Wild Flame";

module.exports = {
  name: 'Cleanse',
  description: "Remove all effects from yourself.",
  flair: '',
  spellType: spellTypes.ice,
  level: 1,
  //charges: 1,
  castTime: 1,
  cooldown: 15,
  duration: 0,
  damage: 0,
  alwaysCast: true,
  target: targets.selections.targetSelf,
  image: "soap",
 // flair: "Spoil their fun with cleanliness.",
  effects: [
    {
        action: actions.removeTimer,
        actionParams: [targets.me]
    }
  ], 
  xpGain: 0,
  manaCost: 1,
}
