
import React, {useState, useReducer, useRef} from 'react';
import './PaginatedSelector.scss'

export default function PaginatedSelector(props){
    const {
        list = [], 
        pageAmount = 3,  
        Card = null,
        sections // sections overrides list
    } = props;

    // optimize the below
    let maxPage = Math.floor((list.length - 1)/pageAmount);
    if(sections){
        maxPage = sections.length - 1;
    }

    let marginTop = "0%";
    if(pageAmount == 3){
        marginTop = "19%";
    }

    // call changePage(1) or changePage(-1)
    const [page, changePage] = useReducer((state, data)=>{
        if(soundsOn)Sounds.softclick.play();
        if(data == 1){
            if(state < maxPage) return ++state;
        } else if(data == -1){
            if(state > 0) return --state;
        }
        return state;
    }, 0);

    return <div className="paginatedSelector">
        {page > 0 && 
            <div className="leftButton" onClick={()=>changePage(-1)}>
                <span className="material-symbols-outlined" style={{color:"white"}}>
                    arrow_back_ios
                </span>
            </div>
        }

        <div className="selectionArea" style={{marginTop}}>
            { sections &&
                sections[page][1].map((item, i) => {
                    return <Card item={item} index={i} key={i}/>
                })
            }
            { !sections &&
                list.map((item, i) => {
                    let pageStartIndex = page * pageAmount;
                    if(i < pageStartIndex || i >= pageStartIndex + pageAmount) return null;
                    return <Card item={item} index={i} key={i}/>
                })
            }
        </div>

        { page < maxPage && 
            <div className="rightButton" onClick={()=>changePage(1)}>
                <span className="material-symbols-outlined" style={{color:"white"}}>
                    arrow_forward_ios
                </span>
            </div>
        }

       { sections && <div className={`pageName`}>
            <div className={`pageNameText`}>
                {sections[page][0]}
            </div>
        </div>
        }
    </div>
}