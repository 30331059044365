import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = {
  bigButton: {
    background: 'linear-gradient(45deg, #8e88b9 30%, #749f8e 90%);',
    border: '1px solid #858585',
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px #40468d73',
    color: 'white',
    width: "100%",
    height: "100%",
    fontSize: 'clamp(0.7rem, -0.675rem + 3.333vh, 2.7rem);'
  },

  blue: {
    background: 'linear-gradient(45deg, #5618ed 30%, #704d99 90%);',
    
  },

  grey: {
    background: 'linear-gradient(45deg, #957f7f 30%, #85858a 90%);',
  },

  black: {
    background: 'linear-gradient(45deg, #565656 30%, #545353 90%);',
  },

  green: {
    background: 'linear-gradient(45deg, #177f67 30%, #0c7b1f 90%);',
    
  }, //fffcde

  darkgreen:{
    background: 'linear-gradient(45deg, #39674c 30%, #0c7b1f 90%)'
  },

  softred:{
    background: 'linear-gradient(45deg, #914956 30%, #78272d 90%)'
  },

  red: {
    background: 'linear-gradient(45deg, #803234 30%, #7b0c14 90%)',
  },

  darkblue: {
    background: 'linear-gradient(22deg, #2e2a99 30%, #250493 90%)'
  },

  purple: {
    background: 'linear-gradient(45deg, #8c89da 30%, #9e6db4 90%)'
  },

  orange: {
    background: 'linear-gradient(45deg, #c68b67 30%, #ce9696 90%)'
  },

  circle: {
    borderRadius: "100px"
  },

  teal: {
    background: 'linear-gradient(45deg, #0c547b 30%, #007896 90%)'
  },

  smallertext: {
    fontSize: 'clamp(0.7rem, -0.875rem + 2.333vh, 2rem);'
  }

};

function HigherOrderComponent(props) {
  const { 
    classes, 
    onClick, 
    children, 
    style, 
    blue, 
    green,
    darkgreen,
    softred,
    red,
    purple,
    disabled,
    orange,
    grey,
    circle,
    teal,
    darkblue,
    black,
    smallertext
  } = props;

  const large = useMediaQuery('(min-height:1305px)');

  let className = ( large ? 
    clsx(classes.bigButton, classes.large): 
    clsx(classes.bigButton, classes.small));

  if(grey) {
      className = clsx(classes.bigButton, classes.grey)
  } else if(blue){
    className = clsx(classes.bigButton, classes.blue);

  } else if(green){
    className = clsx(classes.bigButton, classes.green);

  } else if(red){
    className = clsx(classes.bigButton, classes.red);
  }  else if(purple){
    className = clsx(classes.bigButton, classes.purple);
  } else if(orange) {
    className = clsx(classes.bigButton, classes.orange)
  } else if(teal) {
    className = clsx(classes.bigButton, classes.teal)
  } else if(darkblue) {
    className = clsx(classes.bigButton, classes.darkblue)
  } else if(black) {
    className = clsx(classes.bigButton, classes.black)
  }  else if(darkgreen) {
    className = clsx(classes.bigButton, classes.darkgreen)
  }  else if(softred) {
    className = clsx(classes.bigButton, classes.softred)
  } 
  
  if(smallertext) {
    className = clsx(className, classes.smallertext)
  } 

  if(circle){
    className = clsx(className, classes.circle)
  }

  return <Button 
      className={className} 
      onClick={onClick} 
      disabled={disabled}
      style={style} >
        {children}
    </Button>;
}


export default withStyles(styles)(HigherOrderComponent);