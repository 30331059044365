import React, { useEffect, useState } from 'react';
import './SpellDescription.scss';
import imageMap from 'images/index.js';
const {actionCosts} = require("shared/cardConstants/costs")

const targets = require("shared/cardConstants/targets");


function Draw3Description( props ) {

    const { 
        onClick = ()=>{},
        onClose = ()=>{},
        showClose
    } = props;

    return <div className={`spellDescription open`} onClick={()=> onClick()}>
        <div className={`spellName`}>
             <div className={`nametext`}>
                Draw 3 More
             </div>
        </div>
       
        {/* {!isExpanded && <div className="readMore"> see details </div>} */}
        <div className="spellDescriptionText">

            { showClose && <div className="closeButton" onClick={()=>onClose()}>
                <span className="material-symbols-outlined">
                    close
                </span>
            </div> }
            
            <div className={`cardSpellDamage fire`}>
                <b>Cost: {actionCosts.draw3}</b>
            </div>
            <div className={`cardActualDescription`}>Draw 3 addition random spells. <br/> At least one will be advanced.</div>
        </div>
    </div>

}

export default Draw3Description;
