


let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Detonate",
    description: "Immediately deal all delayed damage to all enemies.",
    flair: "",
    spellType: spellTypes.fire,
    level: 2,
   // charges: 1,
    castTime: 1,
    cooldown: 20,
    duration: 0,
    damage: '',
    target: targets.selections.targetEnemy,
    image: "remote",
    playAoe: true,
    effectNumber: "1x",
    effects: [
        {
            action: actions.damage, // damage: ( target, damage, type)
            actionParams: [
                targets.allEnemies, 
                [specialDamageAmounts.timerCountMultiple, targets.selectedPlayer ,1, "fire"], spellTypes.fire],
        },
        {
            action: actions.removeTimer,
            actionParams: [targets.selectedPlayer, "fire"]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}


// {
//     action: actions.damage, // damage: ( target, damage, type)
//     actionParams: [
//     [playerTargets.allAliveExcept, playerTargets.me], 
//     [damageAmounts.timerCountMultiple, playerTargets.selectedPlayer, 1, "fire"], 
//     damageTypes.fire],
// },
// {
//     action: actions.removeTimer,
//     actionParams: [playerTargets.selectedPlayer, "fire"]
// }