import React, { useEffect, useState } from 'react';

export default function useGlitters(left = 0, top = 0, width = window.innerWidth, height = window.innerHeight - 20, maxGlitters = 300){

    const [glitters, setGlitters] = useState([]);

    useEffect(()=>{
         let myGlitters = [];
         let glitterCount =  width * 0.85;
         if(glitterCount > maxGlitters) glitterCount = maxGlitters;
         for(let i = 0; i < glitterCount; i++){
             let x = left + Math.random() * width;
             let y = top + Math.random() * height;
             myGlitters.push(<div key={i} className="glitter" style={{position: "absolute", left: `${x}px`, top: `${y}px`,  animationDelay: `${ (x * 1/150)}s`}}></div>)
         }
         setGlitters(myGlitters);

    }, []);

    return glitters;
}