
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Granite Shield",
    description: "Reflect earth spells, reduce all damage by 4.",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 0,
    cooldown: 10,
    damage: 0,
    duration: 3,
    target: targets.selections.targetSelf,
    image: "shield",
    flair: "",
    effectNumber: "-4",
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, {
                    buff: buffs.spellTypeReflect, 
                    buffParams: [100, spellTypes.earth]
                }, 3
            ],
        },
        { // give the buff for the time, or until condition is met
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, 
                {
                    buff: buffs.resistance, 
                    buffParams: [4]
                }, 
                3,
            ],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}