
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const studdedboots = require('./studdedboots');



module.exports = {
    name: "Enchanted Dagger of Hastey Hijinks",
    description: "Deal 2 extra damage.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "dagger",
    requires: studdedboots.name,
    effects: [
        // {
        //     buff: buffs.staticConditionalEffect, 
        //     id: "dagger of haste",
        //     action: {
        //         action: actions.damage,
        //         actionParams: [targets.triggeringPlayer, 10, spellTypes.ice],
        //     },
        //     condition: [conditions.spellDodged, null, targets.ice]
        {     
            buff: buffs.damageTypeBonus,
            buffParams: [2, spellTypes.earth]
        },
        {
            buff: buffs.damageTypeBonus,
            buffParams: [2, spellTypes.ice]
        },
        {
            buff: buffs.damageTypeBonus,
            buffParams: [2, spellTypes.fire]
        }
    ]
  
}