import { useEffect } from "react";
import { DiscordSDK } from "@discord/embedded-app-sdk";

const queryParams = new URLSearchParams(window.location.search);
const isInDiscord = queryParams.get('frame_id') != null;

console.log(process.env.NODE_ENV);

if(process.env.DISCORD_CLIENT_ID || process.env.REACT_APP_DISCORD_CLIENT_ID){
    console.log("have some client ID");
}
let discordSdk = null;
if(isInDiscord){
    discordSdk = new DiscordSDK(process.env.DISCORD_CLIENT_ID || process.env.REACT_APP_DISCORD_CLIENT_ID);
}

async function setupDiscordSdk(setInfo) {
    let info = {};
    await discordSdk.ready();
    setInfo({status: "Ready", loading: true});
    console.log("connected");
    const { code } = await discordSdk.commands.authorize({
        client_id: process.env.DISCORD_CLIENT_ID || process.env.REACT_APP_DISCORD_CLIENT_ID,
        response_type: "code",
        state: "",
        prompt: "none",
        scope: [
          'identify',
          'guilds'
        ],
    });
    console.log(code);
    setInfo({status: "Authorized", loading: true});

    const response = await fetch("/api/token", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            code,
        }),
    });

    const { access_token } = await response.json();

    console.log(access_token);

    let auth = await discordSdk.commands.authenticate({
        access_token,
    });

    setInfo({status: "Authenticated", loading: true});

    const channel = await discordSdk.commands.getChannel({channel_id: discordSdk.channelId});
   
    console.log(channel);

    if(channel){
        const { id, name } = channel;
        console.log("channel ID ", id);
        info = {
            status: `Connected to ${name}`,
            channel: { id, name },
            loading: true // still true because need player ID
        };
        setInfo({...info});
    }

   const playerDataResponse = await fetch("/api/me", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${access_token}`
        }
    });

    const playerData = await playerDataResponse.json();
    console.log(playerData);

    if(playerData){
        const { id, username, avatar, global_name } = playerData;
        info.playerData = { id, username, avatar, global_name };
        info.loading = false;
        // wait until we have player ID to say loading is finished.
        // this is because we will use the ID when talking to the server
        // (like joining initial room, etc)
        setInfo({...info});
    }
    
}

export default function useDiscord(setInfo){
   
    useEffect(() => {
        if(discordSdk){
            setInfo({status: "loading...", loading: true})
            setupDiscordSdk(setInfo);
        } else {
            setInfo(null);
        }
    }, []);

    return !!discordSdk;
}