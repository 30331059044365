

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Adios, Dude",
    description: "You can cast spells while channeling.",
    //flair: "",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    //cooldown: 1,
    duration: 30,
    damage: 0,
    level: 2,
    target: targets.selections.targetSelf,
    image: "long_island",
    item: true,
    alwaysCast: true,
    cost: 6,
    effects: [
        {
            action: actions.addTimerState,
            actionParams: [
                targets.me, 
                stateBuffs.canCastWhileChanneling, 
            30,
            ],
        },
        // {
        //     action: actions.addTimerState,
        //     actionParams: [
        //         targets.me, 
        //         stateBuffs.unstunnable, 
        //     30,
        //     ],
        // },
    ],
    xpGain: 0,
    manaCost: 1,
}
