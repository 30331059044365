
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Cinnamon Whiskey",
    description: "+1 fire damage, and +3 delayed damage.",
    spellType: spellTypes.item,
    charges: 1,
    level: 1,
    castTime: 0,
    damage: 0,
    duration: 20,
    target: targets.selections.targetSelf,
    image: "whiskey_glass",
    item: true,
    alwaysCast: true,
    cost: 3,
    effects: [
      {
        action: actions.addTimerBuff,
        actionParams: [
            targets.me, 
            {
                buff: buffs.damageTypeBonus,
                buffParams: [1, spellTypes.fire]
            }, 
            20, 
        ]
      },
      {
        action: actions.addTimerBuff,
        actionParams: [
            targets.me, {
                buff: buffs.bonusDelayedDamage, 
                buffParams: [3]
            }, 20
        ],
      }
    ],
    xpGain: 0,
    manaCost: 1,
}