
let roundTypes = require('../roundConstants/roundTypes');
let choosingAmount = require('../roundConstants/choosingAmount');
let choosingFor = require('../roundConstants/choosingFor');

module.exports = function(level){
    return  {
        forceLevel: level,
        roundType: roundTypes.choosingToAdd,
        choosingFor: choosingFor.addFromChoicesListToSpellList,
        spellsToDrawToChooseFrom: 3,
        timeLimit: 15
    }
}