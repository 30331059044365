
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const tunic = require('./tunic');



module.exports = {
    name: "Steel Chestplate of Severe Handsomeness",
    description: "Reduce damage taken by 1.",
    spellType: spellTypes.armor,
    level: 3,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "chestplate",
    // upgradesFrom: tunic.name,
    requires: "T-Shirt of Tipsy Energy",
    effects: [
        {
            buff: buffs.resistance, 
            buffParams: [1]
        }
    ]
}