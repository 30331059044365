import React, {useState, useEffect} from 'react';

import ResourceBar from '../game/components/ResourceBar.js';

import BigButton from './components/BigButton';

export default function PlayPage({createGame, createPracticeGame, createBearChallenge, createIceChallenge, createFireChallenge, pushTutorial = false, discordInfo, playerID, playerName, partyID}){

    // hardcoding override until tutorial fixed
    pushTutorial = false;
    const [showingMore, setShowingMore] = useState(false);

    return  <div className="playbuttons">

        { !discordInfo && partyID != playerID && <div className={"leavePartyButton"}>
            <span className="material-symbols-outlined" onClick={()=>{
                // navagate url
                // set state
                // emit leaveParty
            }}>
                exit_to_app
            </span>
        </div>}

       { !showingMore && <div className="experiencebar">
            <ResourceBar
                total={1}
                current={0}
                previousCurrent={0 + 1}
                className={"experience"}
            />
        </div>}
        {
            <div className="myNameArea">
                <div className="myName">
                    {playerName}
                </div>
            </div>
        }
        <div className="currentlevelArea">
            <div className="currentlevel">
                <div className="currentlevelText">
                    Level
                </div>
                <div className="currentlevelNumber">
                    1
                </div>
            </div>
        </div>
       
        {/* 
            <div className="currentrankArea">
                <span className="material-symbols-outlined" style={{color:"#848489"}}>
                    star
                </span>
                <span className="material-symbols-outlined" style={{color:"#848489"}}>
                    star
                </span>
                <span className="material-symbols-outlined" style={{color:"#848489"}}>
                    star
                </span>
            </div>  
        */ }

        {
            discordInfo && <div className='statusMessages'>
                <div className="statusMessage"><div className="text">{discordInfo.status}</div></div>
            </div>
        }

        {
            !discordInfo && partyID != playerID && <div className='statusMessages'>
                <div className="statusMessage"><div className="text">Party: {partyID}</div></div>
            </div>
        }

        {
            !pushTutorial && !showingMore && <div className="practicebutton">
                <BigButton 
                    grey 
                    smallertext
                    onClick={()=> setShowingMore(true)} >
                        More
                </BigButton>
            </div>
        }
        
        {!pushTutorial && !showingMore && <div className="playbutton">
                <BigButton 
                    blue 
                    onClick={createGame} >
                        <b>Play</b>
                </BigButton>
            </div>
        }

        {!pushTutorial && showingMore && <div className="otherbutton ">
                <BigButton 
                    grey 
                    onClick={createPracticeGame} >
                        Practice
                </BigButton>
            </div>
        }

        {!pushTutorial && showingMore && <div className="otherbutton two">
                <BigButton 
                    green 
                    onClick={createBearChallenge} >
                        Forrest
                </BigButton>
            </div>
        }

        {!pushTutorial && showingMore && <div className="otherbutton three">
                <BigButton 
                    teal 
                    onClick={createIceChallenge} >
                        Mountains
                </BigButton>
            </div>
        }

        {!pushTutorial && showingMore && <div className="otherbutton four">
                <BigButton 
                    softred 
                    onClick={createFireChallenge} >
                        Cave
                </BigButton>
            </div>
        }

        { pushTutorial && <div className="practicebutton">
                <BigButton 
                    grey 
                    smallertext 
                    onClick={createGame} >
                        <b>Skip</b>
                </BigButton>
            </div>
        }

        { pushTutorial && <div className="playbutton">
                <BigButton 
                    green 
                    onClick={createPracticeGame} >
                        <b>Tutorial</b>
                </BigButton>
            </div>
        }

    </div>
}