import React, { useState, useEffect, useReducer } from 'react';
import ResourceBar from '../ResourceBar'
import useMediaQuery from '@material-ui/core/useMediaQuery';
const spellTypes = require("shared/cardConstants/spellTypes");
import imageMap from 'images/index.js';
import './Player.scss'
import { Hats, Robes } from '../../../../../shared/hats';
import { target } from '../../../../../shared/cards/allCards/spells/burnSomeone';
let targets = require('shared/cardConstants/targets');

let spellsToShowHitAnim = ["fireball2", "thermostat", "lighter", "explosion", "eyes", "stick", "grenade", "slap", "shield", "splash", "icicle", "door", "snowball", "drainlife", "freeze"];
// let spellsToShowHitAnimMap = spellsToShowHitAnim.reduce((accum, item) => {
//     accum[item] = true;
// }, {})

function Player(props) {

    const {
        onClick,
        isSelected,
        isSelectable,
        allCastable,
        clientIsCastingOn,
        playerData,
        time,
        hasSelection,
        myCastingSpellData,
        willCastOn,
        isAlly,
        playAnimation,
        playReflectAnimation,
        playAoeAnimation,
        winningTeam,
        hasWon,
        totalDamage,
        totalHealing,
        showName,
        eventData
    } = props;

    const [castBarStart, setCastBarStart] = useState(0);
    const [castBarTimeToFill, setCastBarTimeToFill] = useState(5);

    useEffect(() => {
        if (!playerData) return;
        setCastBarStart(0);
        setCastBarTimeToFill(playerData.castingTimeLeft);

    }, [playerData?.castingIndex]);

    useEffect(() => {
        if (!playerData) return;

        setCastBarStart(playerData.percentCasted);
        setCastBarTimeToFill(playerData.castingTimeLeft);

    }, [playerData?.castModifier]);

    const [wands, setWands] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ duration: state[i].duration - data.time, key: state[i].key });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 6; i++) {
                    if (!found && !state[i].duration) {
                        newArray[i] = { duration: 2, key: data.time };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}]);

    const [damages, setDamages] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ damage: state[i].damage, duration: state[i].duration - data.time, key: state[i].key });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 7; i++) {
                    if (!found && !state[i].damage) {
                        newArray[i] = { damage: data.damage, duration: 1, key: data.time };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}, {}]);

    const [healing, setHealing] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ healing: state[i].healing, duration: state[i].duration - data.time, key: state[i].key });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 7; i++) {
                    if (!found && !state[i].healing) {
                        newArray[i] = { healing: data.healing, duration: 1, key: data.time };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}, {}]);

    const [dodges, setDodges] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ name: state[i].name, duration: state[i].duration - data.time, key: state[i].key });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 6; i++) {
                    if (!found && !state[i].name) {
                        newArray[i] = { name: data.name, duration: 1, key: data.time };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}]);


    // const [projectiles, setProjectiles] = useReducer((state, data) => {
    //     switch(data.type){
    //         case "tick": {
    //             let newArray = [];
    //             for(let i = 0; i < state.length; i++){
    //                 if(state[i].duration > 0){
    //                     newArray.push({target: state[i].target, duration: state[i].duration - 1});
    //                 } else {
    //                     newArray.push({});
    //                 }
    //             }
    //             return newArray;
    //         }
    //         case "push": {
    //             let newArray = [];
    //             let found = false;
    //             let index = -1;
    //             for(let i = 0; i < 6; i++){
    //                 if(!found && !state[i].target){
    //                     newArray[i] = {target: data.target, duration: 4, time: data.time, spellType: data.spellType};
    //                     found = true;
    //                     index = i;
    //                 } else {
    //                     newArray[i] = state[i];
    //                 }
    //             }
    //             setTimeout( () => {
    //                 setProjectiles({type: 'show', index})
    //             }, 200);
    //             return newArray;
    //         }
    //         case "show": {
    //             let newArray = [];
    //             for(let i = 0; i < 6; i++){
    //                 newArray[i] = state[i];
    //                 if(i == data.index){
    //                     newArray[i].visible = true;
    //                 }
    //             }
    //             return newArray;
    //         }
    //     }
    // }, [{}, {}, {}, {}, {}, {}]);

    const [spellsHitting, setSpellsHitting] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ duration: state[i].duration - data.time, spellType: state[i].spellType, spellDamage: state[i].spellDamage, key: state[i].key,  key: state[i].offsetX,  key: state[i].offsetY });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 6; i++) {
                    let range = i * 6;
                    if (!found && !state[i].spellType) {
                        newArray[i] = { 
                            duration: 2, 
                            initialTime: data.time, 
                            spellType: data.spellType, 
                            spellDamage: data.spellDamage, 
                            key: data.time ,
                            offsetX: Math.floor(Math.random() * range) - range/2,
                            offsetY: Math.floor(Math.random() * range) - range/2
                        };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}]);

    const [imageSpellsHitting, setImageSpellsHitting] = useReducer((state, data) => {
        switch (data.type) {
            case "tick": {
                let newArray = [];
                for (let i = 0; i < state.length; i++) {
                    if (state[i].duration > 0) {
                        newArray.push({ image: state[i].image, duration: state[i].duration - data.time, spellType: state[i].spellType, spellDamage: state[i].spellDamage, key: state[i].key });
                    } else {
                        newArray.push({});
                    }
                }
                return newArray;
            }
            case "push": {
                let newArray = [];
                let found = false;
                for (let i = 0; i < 6; i++) {
                    if (!found && !state[i].image) {
                        newArray[i] = { image: data.image, duration: 2, initialTime: data.time, spellType: data.spellType, spellDamage: data.spellDamage, key: data.time };
                        found = true;
                    } else {
                        newArray[i] = state[i];
                    }
                }
                return newArray;
            }
        }
    }, [{}, {}, {}, {}, {}, {}]);

    if (eventData.damageDetails) {

        eventData.damageDetails.map(damageInfo=>{
           // playAnimation(playerData.id, null, damageInfo.damageType);
            setDamages({ type: 'push', damage: damageInfo.damageDelt, time: new Date() });
            setSpellsHitting({
                type: 'push',
                time: new Date(),
                spellType: damageInfo.damageType,
                spellDamage: damageInfo.damageDelt
            });
         
        })

        if (soundsOn && eventData.amountDamaged >= 0) {
            // if (eventData.spellsHitting) {
            //     if (eventData.spellsHitting[0]) {
            //         if (eventData.spellsHitting[0].spellType == spellTypes.ice) {
            //             Sounds.hitice.play();
            //         } else if (eventData.spellsHitting[0].spellType == spellTypes.fire) {
            //             Sounds.hitfire.play();
            //         } else if (eventData.spellsHitting[0].spellType == spellTypes.earth) {
            //             Sounds.hitearth.play();
            //         }
            //     } else {
            //         Sounds.hitfire.play(); // more often than not its fire
            //     }
            // } else {

            if(eventData.amountDamaged > 3){
                Sounds.hitfire.play(); // more often than not its fire
            }
             //   Sounds.hitearth.play();
            //}
               
            //}
        }

        if (eventData.amountDamaged >= 20) {
            if (soundsOn) Sounds.ouch.play();
        }

        // if (eventData.spellsHitting) {
        //     setDamages({ type: 'push', damage: eventData.amountDamaged, time: new Date(), spell: eventData.spellsHitting[0].name });
        // } else {
        //     setDamages({ type: 'push', damage: eventData.amountDamaged, time: new Date(), spell: "unknown" });
        // }
        delete eventData.amountDamaged;
        delete eventData.damageDetails;
    }

    if (eventData.amountHealed) {
        if (eventData.amountHealed > 5) {
            if (soundsOn) Sounds.heal.play();
        }
        setHealing({ type: 'push', healing: eventData.amountHealed, time: new Date() });
        delete eventData.amountHealed;
    }

    if (eventData.reflections) {
        setDodges({ type: 'push', name: "Reflected!", time: new Date() });
        // playerData.reflections.map(info => {
        let info = eventData.reflections;
        if (info.target) {
            playReflectAnimation(playerData.id, info.target, info.type);
        }
        //})
        delete eventData.reflections;
    }

    if (eventData.didDodge) {
        setDodges({ type: 'push', name: "Dodged!", time: new Date() });
        delete eventData.didDodge;
    }

    if (eventData.spellsHitting) {
        eventData.spellsHitting.map(spell => {

            if(!spellsToShowHitAnim.includes(spell.image)){
                
                setImageSpellsHitting({
                    type: 'push',
                    image: spell.image,
                    time: new Date(),
                    spellType: spell.spellType,
                    spellDamage: spell.damage
                });
            }

            // else do nothing
            
            // if (spell.target != targets.selections.targetSelf) {
            //     playAnimation(playerData.id, null, spell.spellType, spell.cardIndex);
            // }
        })
        delete eventData.spellsHitting;
    }

    if (eventData.spellsCast) {
        setWands({ type: 'push', time: new Date() });
        eventData.spellsCast.map(spell => {

            // target needs to not be null even when game chooses a target
            if (!spell.noCastAnim) {
                if (spell.target) {
                    playAnimation(playerData.id, spell.target, spell.type, spell.cardIndex);

                    if (spell.playAoe) {
                        playAoeAnimation(playerData.team == 0 ? 1 : 0, spell.type);
                    }
                } else {
                    //console.log(playerData.team);
                    playAoeAnimation(playerData.team == 0 ? 1 : 0, spell.type);
                }
            }


            // var targetEl = document.getElementById(spell.target);
            // var myEl = document.getElementById(playerData.id);
            // if(targetEl){

            //     let targetPos= [targetEl.getBoundingClientRect().x, targetEl.getBoundingClientRect().y];
            //     let myPos = [myEl.getBoundingClientRect().x, myEl.getBoundingClientRect().y];
            //     // let offsetY = targetEl.getBoundingClientRect().height/2;
            //     // if(ally) offsetY = -targetEl.getBoundingClientRect().height/2;
            //     let offsetX = -targetEl.getBoundingClientRect().width/4;
            //     // setProjectiles({
            //     //     type: 'push', 
            //     //     target: [targetPos[0] - myPos[0] + offsetX, targetPos[1] - myPos[1] ], 
            //     //     time: new Date(),
            //     //     spellType: spell.type
            //     // });
            // }
        })
        delete eventData.spellsCast;
    }

    // buffs
    let dancing = playerData.dancing && !playerData.dead;

    let blood = playerData.blood && !playerData.dead;
    let flashbacks = playerData.flashbacks && !playerData.dead;
    let water = playerData.water && !playerData.dead;
    let esspresso_martini = playerData.espressoMartini && !playerData.dead;
    let adios = playerData.adios && !playerData.dead;
    let whiskey = playerData.whiskey && !playerData.dead;
    let hot_toddy = playerData.hotToddy && !playerData.dead;
    let margarita = playerData.margarita && !playerData.dead;


    let firemaster = playerData.firemaster && !playerData.dead;
    let icemaster = playerData.icemaster && !playerData.dead;
    let hyper = playerData.hyper && !playerData.dead;
    let blazin = playerData.flashbacks && !playerData.dead;
    let aiming = playerData.aiming && !playerData.dead;
    let freeze = playerData.freeze && !playerData.dead;

    let rum = playerData.canCastWhileChanneling && !playerData.dead;
    // from image
    let squirrel = playerData.squirrel && !playerData.dead;
    let weed = playerData.weed && !playerData.dead;
    let shield = playerData.shield && !playerData.dead;

    let blizzard = playerData.blizzard && !playerData.dead;
    let firestorm = playerData.firestorm && !playerData.dead;
    let bluetint = playerData.bluetint && !playerData.dead;
    let joint = playerData.joint && !playerData.dead;
    let heatin = playerData.heatin && !playerData.dead;
    let trap = false;//playerData.trap && !playerData.dead;
    let smoke = playerData.smoke && !playerData.dead;

    let earthBonus = !playerData.dead && playerData.earthBonus;
    let fireBonus = !playerData.dead && playerData.fireBonus;

    useEffect(() => {
        if (playerData.dead) {
            if (soundsOn) Sounds.death.play();
        }
    }, [playerData.dead])

    // blood = true;//playerData.blood ;
    // flashbacks = true;
    // firemaster = true;//playerData.firemaster ;
    // hyper = true;//playerData.hyper ;
    // blazin = true;//playerData.blazin ;
    // aiming = true;// playerData.aiming ;
    // rum = true;//playerData.diamond ;
    // squirrel = true;//playerData.squirrel ;
    // weed = true;//playerData.weed ;
    // joint = true;//playerData.joint ;
    // heatin = true;//playerData.heatin ;
    // trap = true;//playerData.trap ;
    // shield = true;
    // water = true;
    // earthBonus = 2;//playerData.earthBonus;
    // fireBonus = 2;//playerData.fireBonus;

    let [prevTime, setPrevTime] = useState(0);

    useEffect(() => {
        let timeElapsed = time - prevTime;
        setDamages({ type: 'tick', time: timeElapsed });
        setWands({ type: 'tick', time: timeElapsed });
        setHealing({ type: 'tick', time: timeElapsed });
        // setProjectiles({type: 'tick'});
        setImageSpellsHitting({ type: 'tick', time: timeElapsed });
        setSpellsHitting({ type: 'tick', time: timeElapsed });
        setDodges({ type: 'tick', time: timeElapsed });
        setPrevTime(time);
    }, [time]);

    const largeScreen = useMediaQuery('(min-height:1305px)');

    // const canCastOn = isSelectable && !isSelected && !hasSelection;

    let selectionCastType = "";
    if (myCastingSpellData && myCastingSpellData.target == targets.selections.targetAlly && isAlly && isSelected) {
        selectionCastType = myCastingSpellData.spellType;
    } else if (myCastingSpellData && myCastingSpellData.target == targets.selections.targetEnemy && !isAlly && isSelected) {
        selectionCastType = myCastingSpellData.spellType;
    }


    return <div className="PlayerWrapper" onClick={onClick}>

        {/* <div className="projectileHolder">
        { 
            projectiles.map(({target, duration, visible, spellType}, i) => {
                if(!target) return null;
                let style = {};
                if(visible){
                    style = {
                        transform: `translateX(${target[0]}px) translateY(${target[1]}px) scale(3)`,
                        WebkitAnimation: `projectileFade 3s ease-out`
                    };
                  
                } 

                return <div className={`projectile ${spellType}`} key={i} style={style}>
                       
                    </div>
            })
        }
        </div> */}

        <div className="damageHolder">
            {
                // loop through the 4 damages every time.  if empty, render an empty div.
                // needs to be a different type of div though so that animation works right

                damages.map(({ damage, key }, i) => {
                    if (!damage) {
                        return <div
                            className="damagePlaceholder"
                            key={i}
                        />
                    } else {
                        let fontSize = largeScreen ? 2 : 1;
                        if (damage >= 30) fontSize *= 3;
                        else if (damage >= 20) fontSize *= 2.5;
                        else if (damage >= 10) fontSize *= 2;
                        else fontSize *= (1 + (damage / 10));
                        return <div
                            className="damage"
                            key={key + i}
                            style={{ fontSize: fontSize + 'em' }}
                        >
                            -{damage}
                        </div>
                    }
                })
            }
        </div>

        <div className="healingHolder">
            {

                // loop through the 4 damages every time.  if empty, render an empty div.
                // needs to be a different type of div though so that animation works right

                healing.map(({ healing, key }, i) => {
                    if (!healing) {
                        return <div
                            className="healingPlaceholder"
                            key={i}
                        />
                    } else {
                        let fontSize = largeScreen ? 2.2 : 1.2;
                        //if(healing >= 10) fontSize *=2;
                        //else fontSize *= (1 + (healing/10));
                        if (healing >= 30) fontSize *= 3;
                        else if (healing >= 20) fontSize *= 2.5;
                        else if (healing >= 10) fontSize *= 2;
                        else fontSize *= (1 + (healing / 10));
                        return <div
                            className="heals"
                            key={key + i}
                            style={{ fontSize: fontSize + 'em' }}
                        >
                            +{healing}
                        </div>
                    }
                })
            }
        </div>

        <div className="dodgeHolder">
            {

                // loop through the 4 damages every time.  if empty, render an empty div.
                // needs to be a different type of div though so that animation works right

                dodges.map(({ name, key }, i) => {
                    if (!name) {
                        return <div
                            className="dodgePlaceholder"
                            key={i}
                        />
                    } else {
                        let fontSize = largeScreen ? 2.4 : 1.4;
                        return <div
                            className="dodgeText"
                            key={key + i}
                            style={{ fontSize: fontSize + 'em' }}
                        >
                            {name}
                        </div>
                    }
                })
            }
        </div>

        {wands.map(({ duration }, i) => {
            if (!duration || duration <= 0) return null;
            if (playerData.hat == "none") {
                // better animation here maybe
                return null;
            }
            return <img className="wand" key={i} src={imageMap["wand"]} />;
        })}

    
        {
            imageSpellsHitting.map(({ duration, image, spellType, spellDamage, key }, i) => {
                if (!duration || duration <= 0) return null;
                return <div className="spellHitting">
                    <img className="spellHittingImage" src={imageMap[image]} />
                </div>;
            })
        }

        {
            spellsHitting.map(({ duration, spellType, spellDamage, key, offsetX, offsetY }, i) => {
                    if (!duration || duration <= 0) return null;
                    let size = "small";
                    if(spellDamage > 5) size = "medium";
                    if(spellDamage > 12) size = "large";
                    if(spellDamage > 20) size = "huge";

                    return <div className={`spellHittingAnimation ${spellType} ${size}`} style={{translate: `${offsetX}px ${offsetY}px`}}>
                    </div>;
            })
        }

        {
            spellsHitting.map(({ duration, spellType, spellDamage, key, offsetX, offsetY }, i) => {
                if (!duration || duration <= 0) return null;
                if (blazin && spellType == spellTypes.fire && spellDamage > 0) {
                    return <div className="spellHitting" >
                        <img className="spellHittingImage"  src={imageMap["fire"]} />
                    </div>;
                }
                return null;
            })
        }




        <div id={playerData.id} className={`Player  ${playerData.dead ? 'dead' : ''} ${allCastable ? 'flat' : ''} ${playerData.isMe ? 'me' : ''} `}>

            { //clientIsCastingOn && <div className="castingAnimation"/> 
            }

            {willCastOn && <div className="castingAnimation future" />}

            {isSelected && winningTeam == -1 && <div className={`selected ${selectionCastType}`} />}

            {hasWon && <div className="haswon" />}

            { //canCastOn && <div className="castable"/> 
            }

            {
                //  playerData.debuff && playerData.states[playerData.debuff] &&
                //     <div className="debuff">
                //         <img className={`debuffImage ${playerData.states[playerData.debuff].image}`} src={imageMap[playerData.states[playerData.debuff].image]} />
                //         { !!playerData.states[playerData.debuff].amount && playerData.states[playerData.debuff].amount > 1 &&
                //             <div className={`defuffAmount ${playerData.states[playerData.debuff].image}`}> {playerData.states[playerData.debuff].amount}</div>
                //         }
                //     </div>
            }

            {/* {isSelected && <div className="target"><img src={target} width="100%" height="100%"></img></div>} */}


            {(showName || playerData.isMe) && !playerData.dead && <div className="name">{playerData.name}</div>}


            {!dancing && playerData.hat != Hats.none && <img className={`hat ${(playerData.hat.includes('hatbasic')) ? 'hatbasic' : playerData.hat} ${bluetint ? 'bluetint' : ''} ${playerData.dead && !hasWon ? 'dead' : ''} ${icemaster ? 'trans' : ''}`} width={100} src={imageMap[playerData.hat]} alt={playerData.hat} />}

            {!dancing && <img className={`player ${bluetint ? 'bluetint' : ''} ${playerData.dead && !hasWon ? 'dead' : ''} ${icemaster ? 'trans' : ''}`} width={100} src={imageMap[playerData.robe]} />}

            {freeze && <img className="player" width={80} style={{ zIndex: 500 }} src={imageMap["iceblock"]} />}

            {smoke && <img className="player" width={120} style={{ zIndex: 501, width: "100%", position: "absolute" }} src={imageMap["smoke_white"]} />}

            {flashbacks && <img className="flashbacks" src={imageMap["fire"]} />}

            {squirrel && <img className="squirrel" src={imageMap["squirrel"]} />}

            {heatin && <img className="heatin" src={imageMap["fireball"]} />}

            { //icemaster && <img className={`reflectshield`} src={imageMap["shieldblue"]} />
            }
            { //hyper && <img className={`reflectshield`} src={imageMap["shieldred"]} />
            }
            { //shield && <img className={`reflectshield`} src={imageMap["shieldgreen"]} />
            }

            {trap && <img className="trap" src={imageMap["trap"]} />}

            {water && <img className="water" src={imageMap["water"]} />}

            {esspresso_martini && <img className="water" src={imageMap["esspresso_martini"]} />}

            {blood && <img className="blood" src={imageMap["bloodymary"]} />}

            {hyper && <img className="firemaster" src={imageMap["firemaster"]} />}

            {icemaster && <img className="icemaster" src={imageMap["icemaster"]} />}

            {adios && <img className="rum" src={imageMap["long_island"]} />}

            {whiskey && <img className="rum shot" src={imageMap["whiskey_glass"]} />}

            {hot_toddy && <img className="rum toddy" src={imageMap["hot_toddy"]} />}

            {margarita && <img className="rum toddy" src={imageMap["margarita"]} />}

            {weed && <img className="weed" src={imageMap["weed"]} />}

            {weed && <div className="glow greenglow" />}

            {blizzard && <div className="glow blueglow" />}

            {firestorm && <div className="glow redglow" />}

            {joint && <img className="joint" src={imageMap["joint"]} />}

            {earthBonus > 0 && winningTeam == -1 && <div className="earthBonusWrapper">+{earthBonus}</div>}

            {fireBonus > 0 && winningTeam == -1 && <div className="fireBonusWrapper">+{fireBonus}</div>}

            {hyper && <img className="hyper" src={imageMap["eyes"]} />}

            {aiming && <img className="aiming" src={imageMap["target"]} />}

            {shield && <img className="shield" src={imageMap["shield"]} />}


            {
               playerData.casting && playerData.castBaseTime > 0 &&
                <div className="castingWrapper">
                    <ResourceBar
                        key={playerData.castingIndex}
                        total={1}
                        current={0}
                        previousCurrent={1}
                        preFillPercent={castBarStart}
                        timeToFillBar={castBarTimeToFill || 999}
                        className={`casting ${playerData.castingSpellType} small`}
                    />
                </div>
            }
            {!playerData.dead && <div className="health">
                <ResourceBar
                    current={playerData.dead ? 0 : playerData.currentHealth / Math.ceil(playerData.maxHealth / 20)}
                    total={playerData.maxHealth / Math.ceil(playerData.maxHealth / 20)}
                    previousCurrent={playerData.currentHealth / Math.ceil(playerData.maxHealth / 20)}
                />
            </div>}

        </div>

        <div className={`playerStates ${playerData.isMe ? 'me' : ''}`}>
            {winningTeam == -1 &&
                Object.keys(playerData.states).map(stateID => {
                    let playerState = playerData.states[stateID];
                    if (playerState.amount > 0 && playerState.image && playerState.isDebuff) {
                        return <div className="playerState" key={playerState.image}>
                            <div className={`stateImageArea ${playerState.image}`} key={playerState.image}>
                                <img className={`stateImage`} src={imageMap[playerState.image]} />
                            </div>
                            {playerState.amount > 1 && <div className={`stateAmount ${playerState.image}`}>
                                {playerState.amount}
                            </div>}
                        </div>
                    }
                })
            }

        </div>
        {winningTeam != -1 && <div className={`playerTotals ${hasWon ? 'victory' : ''}`}>
            {/* <div className="totaldamage">{totalDamage} damage</div>
                    <div className="totalhealing">{totalHealing} healing</div> */}
        </div>
        }
    </div>
}

export default Player;