

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Let in the Cold",
    description: "+1 ice-weakness",
    spellType: spellTypes.ice,
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 5,
    duration: 10,
    damage: 1,
    damageType: spellTypes.ice,
    target: targets.selections.targetEnemy,
    image: "door",
    effectImage: "frost",
    effectNumber: "/1",
    effects: [
        { // 1 ice damage
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.ice],
        },
        { // 1 ice weakness for 10 seconds
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: debuffs.damageTypeWeakness, 
                    buffParams: [1, spellTypes.ice]
                }, 10
            ],
        },
    ]
}