
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const chestplate = require('./chestplate');



module.exports = {
    name: "T-Shirt of Tipsy Energy",
    // "T-Shirt of Tipsy Energy"
    // "Leather Tunic of Lasting Liquor Stamina",
    description: "Gain 2 health every 10 seconds",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "tunic",
    // upgradesTo: "Leather Tunic of Lasting Liquor Stamina"
    effects: [
        {
            buff: buffs.healthRegen, 
            buffParams: [2]
        }
    ]
}