

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Whack 'em",
    description: "+1 Earth damage",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 5,
    duration: 10,
    damage: 4,
    target: targets.selections.targetEnemy,
    image: "stick",
    //bonusDamage: 6,
    doubleState: "stun",
    effectNumber: "/1",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 4, spellTypes.earth],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, 
                {
                    buff: buffs.damageTypeBonus,
                    buffParams: [1, spellTypes.earth]
                }, 
                10, 
            ]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}