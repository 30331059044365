

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const { alwaysCast } = require('./cleanse');

module.exports = {
    name: "Consuming Flames",
    description: "Remove Freeze. Convert ice weakness into bonus fire damage (for next spell).", // for next spell cast
    spellType: spellTypes.fire,
    level: 2,
   // charges: 1,
    castTime: 0.5,
    cooldown: 20,
    duration: 10,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "consumefire",
    alwaysCast: true,
    effectNumber: "/1x",
    effects: [
        // make this buff get removed after fire damage is done by me to someone.
        // lower cooldown.
        // now have 2 defensive specials and 2 offensive specials. (shards counts as defensive)
        // devensive spells come first
        {
            action: actions.addConditionalTimerBuff,
            actionParams: [
                targets.me, 
                {
                    buff: buffs.damageTypeBonus,
                    buffParams: [[specialDamageAmounts.timerCountMultiple, targets.me, 1, "damageTypeWeakness/ice"], spellTypes.fire]
                }, 
                10, 
                [conditions.spellBegunCasting, targets.me, null, spellTypes.fire],
            ]
        },
        {
            action: actions.removeTimer,
            actionParams: [targets.me, "damageTypeWeakness/ice"]
        },
        {
            action: actions.removeTimer,
            actionParams: [targets.me, "frozen"]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}