
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const trousers = require('./trousers');



module.exports = {
    name: "Elven Chained Pants of Evermore Raging",
    description: "Draw 3 extra items each round.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "pants",
    upgradesFrom: "Tights of Titilation",
    effects: [
        {
            buff: buffs.drawExtraItems, 
            buffParams: [2]
        }
    ]
}