
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const chestplate = require('./chestplate');
const pendant = require('./pendant');



module.exports = {
    name: "Lost Tome of Sloshed Spellcraft",
    description: "Can hold 1 extra spell in your hand.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "tome",
    requires: pendant.name,
   // upgradesTo: chestplate.name,
    effects: [
        {
            buff: buffs.extraSpellsInHand, 
            buffParams: [1]
        }
    ]
}