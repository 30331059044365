
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

// let name = "Wild Flame";

module.exports = {
  name: "Ask for a light",
  description: "After fire damage is done to you, heal twice that amount.",
  spellType: spellTypes.earth,
  castTime: 1.5,
  level: 2,
  cooldown: 30,
  duration: 10,
  damage: 0,
  target: targets.selections.targetSelf,
  image: "joint",
  effectNumber: "+?", 
  effects: [
    {
        action: actions.addTimerConditionPromptEffect,
        actionParams: [
            targets.me,
            {
                action: actions.gainHealth,
                actionParams: [targets.selectedPlayer, specialDamageAmounts.doubleTriggeringDamageDone],
                image: "joint"
            }, 
            [conditions.damageDone, null, targets.me, spellTypes.fire], // damage done to me
            10
        ]
    },
    // {
    //     action: actions.addConditionalConditionPromptEffect,
    //     actionParams: [
    //         targets.me,
    //         { // thing to be done when condition is met (add timer buff)
    //             action: actions.addConditionalBuff,
    //             actionParams: [
    //                 targets.me,
    //                 {
    //                     buff: buffs.damageTypeBonus,
    //                     buffParams: [2, spellTypes.fire]
    //                 }, 
    //                 [conditions.damageDone, targets.me, null, spellTypes.fire]
    //             ]
    //         },
    //         [conditions.damageDone, targets.me, null, spellTypes.fire],
    //         [conditions.damageDone, targets.me, null, spellTypes.fire]

    //     ]
    // }
    // { 
    //     action: actions.addConditionalBuff,
    //     actionParams: [
    //         targets.me,
    //         {
    //             buff: buffs.damageTypeBonus,
    //             buffParams: [3, spellTypes.fire]
    //         }, 
    //         [conditions.damageDone, targets.me, null, spellTypes.fire], // fire damage done by me, more than 5
    //     ]
    // }
    // { 
    //     action: actions.addTimerBuff,
    //     actionParams: [
    //         targets.me,
    //         {
    //             buff: buffs.damageTypeBonus,
    //             buffParams: [2, spellTypes.fire]
    //         }, 
    //         10
    //     ]
    // }
  ], 
  xpGain: 0,
  manaCost: 1,
}
