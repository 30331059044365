import { useEffect, useState } from 'react';

const matchOptions = require('shared/matchOptions.js');
const storageValues = require("../../src/storageValues");


export default function useGameOptions(playerID){
    const [enemyAICount, setEnemyAICount ] = useState(2);
    const [allyAICount, setAllyAICount ] = useState(1);
      
    const [allyAI, setAllyAI ] = useState(matchOptions.defaults.ai);
    const [enemyAI, setEnemyAI ] = useState(matchOptions.defaults.ai);
    const [roundStyle, setRoundStyle ] = useState(matchOptions.defaults.roundStyle);
    const [maxHealth, setMaxHealth] = useState(matchOptions.defaults.maxHealth);
    const [fightTime, setFightTime] = useState(matchOptions.defaults.fightTime);
    const [gameName, setGameName] = useState(null);

    useEffect(()=>{

        let lastOptions = localStorage.getItem(storageValues.gameOptions);
        if(!lastOptions || lastOptions === "undefined" || lastOptions == "[object Object]"){
            lastOptions = {};
        } else {
            lastOptions = JSON.parse(lastOptions);
        }
        if(lastOptions.enemyAICount)setEnemyAICount(lastOptions.enemyAICount);
        if(lastOptions.allyAICount)setAllyAICount(lastOptions.allyAICount);
    }, [])

    return {
        allyAI, setAllyAI,
        enemyAI, setEnemyAI,
        roundStyle, setRoundStyle,
        maxHealth, setMaxHealth,
        fightTime, setFightTime,
        gameName, setGameName,
    };
}