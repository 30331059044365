
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let stateDebuffs = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Freeze",
    description: "+4 ice-weakness and freeze target.",
    flair: "",
    spellType: spellTypes.ice,
    image: "freeze",
    level: 2,
   // charges: 1,
    castTime: 2.5,
    cooldown: 40,
    duration: 4,
    damage: 2,
    target: targets.selections.targetEnemy,
    effectImage: "frost",
    effectNumber: "/4",
    effects: [
        // this is here for the image
    //    {
    //         action: actions.addTimerConditionPromptEffect,
    //         actionParams: [
    //             targets.selectedPlayer,
    //             {
    //                 action: actions.gainHealth,
    //                 actionParams: [targets.selectedPlayer, 0],
    //                 image: "freeze"
    //             }, 
    //             [conditions.damageDone, null, targets.selectedPlayer, spellTypes.fire], // damage done to me
    //             4
    //         ]
    //     },
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 2, spellTypes.earth],
        },
        {
            action: actions.addTimerState,
            actionParams: [targets.selectedPlayer, stateBuffs.frozen, 4],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: debuffs.damageTypeWeakness, 
                    buffParams: [4, spellTypes.ice]
                }, 10
            ],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}