
import React from 'react';
import imageMap from '../../../images/index.js'
import "../../game/components/PlayerArea/Player.scss"

let vampireblood = require('shared/cards/allCards/spells/vampireblood');
let firemaster = require('shared/cards/allCards/spells/firemaster');
let firestorm = require('shared/cards/allCards/spells/firestorm');

let recovery = require('shared/cards/allCards/spells/recovery');

let icemaster = require('shared/cards/allCards/spells/coldmaster');
let bustamove = require('shared/cards/allCards/spells/bustamove');

let heal = require('shared/cards/allCards/spells/heal');

let heatinup = require('shared/cards/allCards/spells/heatinup');

let freeze = require('shared/cards/allCards/spells/freeze');

let fists = require('shared/cards/allCards/spells/fists');
let smokescreen = require('shared/cards/allCards/spells/smokescreen');
let meditate = require('shared/cards/allCards/spells/meditate');
let vines = require('shared/cards/allCards/spells/vines');
let squirrels = require('shared/cards/allCards/spells/squirrels');
let shield = require('shared/cards/allCards/spells/shield');
let askforalight = require('shared/cards/allCards/spells/askforalight');
let flashbacks = require('shared/cards/allCards/spells/flashbacks');
let blizzard = require('shared/cards/allCards/spells/blizzard');

let whiskey = require('shared/cards/allCards/potions/whiskey');
let adios = require('shared/cards/allCards/potions/adios');
let vodka = require('shared/cards/allCards/potions/vodka');
let esspressomartini = require('shared/cards/allCards/potions/esspressomartini');
let cinnomonwhiskey = require('shared/cards/allCards/potions/cinnomonwhiskey');
let hottoddy = require('shared/cards/allCards/potions/hottoddy');
let margarita = require('shared/cards/allCards/potions/margarita');
let bloodymary = require('shared/cards/allCards/potions/bloodymary');

let greenfairy = require('shared/cards/allCards/potions/everclear');

let alltrue = false;

export default function Wastey({robe, hat, selectedCard}){
    let isBigHat = !hat.includes("basic");

    let _blood = selectedCard?.name == bloodymary.name || alltrue;
    let _joint = selectedCard?.name == askforalight.name || alltrue;
   // let _firemaster = selectedCard?.name == firemaster.name || alltrue;
    let _icemaster = selectedCard?.name == icemaster.name || alltrue;

    let _weed = selectedCard?.name == meditate.name || alltrue;
    let _glasses = selectedCard?.name == firemaster.name || alltrue;
    let _aiming = selectedCard?.name == margarita.name || alltrue;
    let _shield = selectedCard?.name == shield.name || alltrue;
    let _squirrel = selectedCard?.name == squirrels.name || alltrue;
    let _heatin  = selectedCard?.name == heatinup.name || alltrue;
    let _esspresso_martini = selectedCard?.name == esspressomartini.name || alltrue;
    let _adios = selectedCard?.name == adios.name || alltrue;

    let _whiskey = selectedCard?.name == whiskey.name || alltrue;
    let _hot_toddy = selectedCard?.name == hottoddy.name || alltrue;
    let _firestorm = selectedCard?.name == firestorm.name || alltrue;

    let _freeze = selectedCard?.name == freeze.name;
    let _smoke = selectedCard?.name == smokescreen.name;
    let _dancing = selectedCard?.name == greenfairy.name;
    let _flashbacks = selectedCard?.name == flashbacks.name || alltrue;

    let _trap = false;//selectedCard?.name == vines.name || alltrue;
    let _vodka = selectedCard?.name == vodka.name || alltrue;
    let _blizzard = selectedCard?.name == blizzard.name || alltrue;


    return <React.Fragment>
       { !_dancing && <img className={`theWizardHat ${isBigHat?'bigger':''} ${_icemaster?'trans':''}`} src={imageMap[hat]} />}
       { !_dancing && <img className={`theWizard nofade ${isBigHat?'bigger':''} ${_icemaster?'trans':''}`} src={imageMap[robe]} />}

    

        <div className="Player">

            { //_icemaster && <img className={`reflectshield`} src={imageMap["shieldblue"]} />
            }
            { //_glasses && <img className={`reflectshield`} src={imageMap["shieldred"]} />
            }
            { //_shield && <img className={`reflectshield`} src={imageMap["shieldgreen"]} />
            }

            {_freeze && <img className="freeze" src={imageMap["iceblock"]} />}

            {_smoke && <img className="smoke" src={imageMap["smoke_white"]} />}

            {_flashbacks && <img className="flashbacks" src={imageMap["fire"]} />}

            {_squirrel && <img className="squirrel" src={imageMap["squirrel"]} />}

            {_heatin && <img className="heatin menu" src={imageMap["fireball"]} />}

            {_trap && <img className="trap" src={imageMap["trap_white"]} />}

            {_blood && <img className="blood" src={imageMap["bloodymary"]} />}

            {_glasses && <img className="firemaster" src={imageMap["firemaster"]} />
            }

            {_icemaster && <img className="icemaster" src={imageMap["icemaster"]} />}
            
            {_adios && <img className="rum" src={imageMap["long_island"]} />}

            {_whiskey && <img className="rum shot" src={imageMap["whiskey_glass"]} />}

            {_hot_toddy && <img className="rum toddy" src={imageMap["hot_toddy"]} />}

            {_esspresso_martini && <img className="water" src={imageMap["esspresso_martini"]} />}

            {_weed && <img className="weed" src={imageMap["weed"]} />}

            {_weed && <div className="glow greenglow menu" />}

            {_blizzard && <div className="glow blueglow menu" />}

            {_firestorm && <div className="glow redglow menu" />}

            {_joint && <img className="joint" src={imageMap["joint"]} />}

            {_glasses && <img className="hyper" src={imageMap["eyes"]} />}

            {_aiming && <img className="aiming" src={imageMap["target_white"]} />}

            {_shield && <img className="shield" src={imageMap["shield"]} />}
        </div>

        
    </React.Fragment>
}