
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Fluid Dancing",
    description: "Reflect ice spells, +20% dodge chance.",
    spellType: spellTypes.ice,
    level: 1,
   // charges: 1,
    castTime: 0,
    cooldown: 10,
    duration: 3,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "dancing",
    cooldownLocked: true,
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, {
                    buff: buffs.spellTypeReflect, 
                    buffParams: [100, spellTypes.ice]
                }, 3
            ],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: buffs.dodgeChance, 
                    buffParams: [20]
                }, 3
            ],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}