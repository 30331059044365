
import Bluecup from './bluecup.png'
import Dancing from './dancing.png'
import Diamond from './diamond.png'
import Door from './door.png'
import Explosion from './explosion.png'
import Fireball from './fireball.png'
import Firebonus from './fireBonus.png'
import Firehat from './firehat.png'
import Firemaster from './firemaster.png'
import Frost from './frost.png'
import GameName from './gameName.png'
import Hatbeer from './hatbeer.png'
import Hatbluestar from './hatbluestar.png'
import Hatbow from './hatbow.png'
import Hatcat from './hatcat.png'
import Hatfire from './hatfire.png'
import Hatpurpstar from './hatpurpstar.png'
import Icicle from './icicle.png'

import Joint from './joint.png'
import Lighter from './lighter.png'
import Lock from './lock.png'
import RedCup from './redCup.png'
import Icemaster from './icemaster.png'
import Shot from './shot.png'
import Shower from './shower.png'
import Smoke from './smoke.png'
import Splash from './splash.png'
import Squirrel from './squirrel.png'
import Stick from './stick.png'
import Stun from './stun.png'
import Sword from './sword.png'
import Target from './target.png'
import TargetWhite from './targetwhite.png'
import TargetAllies from './targetAllies.png'
import TargetAlly from './targetAlly.png'
import TargetEnemies from './targetEnemies.png'
import TargetEnemy from './targetEnemy.png'
import TargetSelf from './targetSelf.png'
import Thermostat from './thermostat.png'

import TheWizard from './theWizard.png'
import Wastey from './wastey.png'
import WasteyAngry from './wasteyAngry.png'

import Timebomb from './timebomb.png'
import Vines from './vines.png'
import Wand from './wand.png'
import Water from './water.png'
import TallWater from './tallWater.png'
import Rum from './rum.png'
import Weed from './weed.png'
import Powder from './powder.png'
import Fist from './fist.png'
import Slap from './slap.png'
import Fireball2 from './fireball2.png'
import Littlebomb from './littlebomb.png'
import Fire from './fire.png'
import EarthBonus from './earthBonus.png'
import Snowball from './snowball.png'
import Questionmark from './questionmark.png'
import Sun from './sun.png'
import Remote from './remote.png'

import Blizzard from './blizzard.png'
import Earthquake from './earthquake.png'
import Freeze from './freeze.png'
import Hotbox from './hotbox.png'
import Shatter from './shatter.png'
import Soap from './soap.png'
import Avalanche from './avalanche.png'
import Trap from './trap.png'
import Iceblock from './iceblock.png'
import Deck from './deck.png'
import Hat from './hat.png'
import Wasteynohat from './wasteynohat.png'
import Wasteynohatred from './wasteynohatred.png'
import Wasteynohatgreen from './wasteynohatgreen.png'
import Wasteynohatbrown from './wasteynohatbrown.png'
import Wasteynohatblue from './wasteynohatblue.png'
import Wasteynohatlightblue from './wasteynohatlightblue.png'
import Wasteynohatlightgreen from './wasteynohatlightgreen.png'
import Wasteynohatlightpurple from './wasteynohatlightpurple.png'
import Wasteynohatrainbow from './wasteynohatrainbow.png'
import Wasteynohatdragonred from './wasteynohatdragonred.png'
import Wasteynohatgrey from './wasteynohatgrey.png'
import Wasteybear from './wasteybear.png'
import Wasteyiced from './wasteyiced.png'
import Wasteyfired from './wasteyfired.png'


import Hatbasic from './hatbasic.png'
import Hatbasicgreen from './hatbasicgreen.png'
import Hatbasicred from './hatbasicred.png'
import Hatcoolblue from './hatcoolblue.png'
import Hatcoolgreen from './hatcoolgreen.png'
import Hatcoolpurple from './hatcoolpurple.png'
import Hatrainbow from './hatrainbow.png'
import Hatleaves from './hatleaves.png'
import Hatbeer2 from './hatbeer2.png'
import Hatflowersred from './hatflowersred.png'
import Hatflowersgreen from './hatflowersgreen.png'
import Hatflowerspurple from './hatflowerspurple.png'
import Hattree from './hattree.png'
import Hatmountain from './hatmountain.png'
import Hatbearfur from './hatbearfur.png'
import Hatdragonblue from './hatdragonblue.png'
import Hatdragonred from './hatdragonred.png'
import Hatwater from './hatwater.png'

import Eyes from './eyes.png'
import Shield from './shield.png'
import Rock from './rock.png'
import Shards from './shards.png'
import Drainlife from './drainlife.png'
import Blood from './blood.png'
import Heatin from './heatin.png'
import FireWhite from './firewhite.png'
import StunWhite from './stunwhite.png'
import SmokeWhite from './smokewhite.png'
import TrapWhite from './trapwhite.png'
import Whiskey from './whiskey.png'
import Everclear from './everclear.png'
import Everclear2 from './everclear2.png'
import Sac from './sac.png'
import Longstaff from './longstaff.png'
import Dagger from './dagger.png'
import Tome from './tome.png'
import Sash from './sash2.png'
import Pendant from './pendant.png'
import Pants from './pants.png'
import Ring from './ring.png'
import Belt from './belt.png'
import Trousers from './trousers.png'
import Tunic from './tunic.png'
import Chestplate from './chestplate.png'

import Beer from './beer.png'
import WhiskeyGlass from './whiskeyglass.png'
import EspressoMartini from './esspressomartini.png'
import WaterTall from './watertall.png'
import FireballShot from './fireballshot.png'
import HotToddy from './hottoddy.png'
import LongIsland from './longisland.png'
import TequilaShot from './tequilashot.png'
import Absinthe from './absinthe.png'
import Margarita from './margarita.png'

import Staff from './staff.png'
import Bloodymary from './bloodymary.png'
import Consumefire from './consumefire.png'
import Grenade from './grenade.png'

import Shieldgreen from './shieldgreen.png'
import Shieldblue from './shieldblue.png'
import Shieldred from './shieldred.png'

import Fireballs from './fireballs.png'
import Lantern from './lantern.png'
import ClothBoots from './clothboots.png'
import StuddedBoots from './studdedboots.png'

import Bow from './bow.png'
import ForceField from './forcefield.png'
import ForceField2 from './forcefield2.png'
 


export default {
    'bluecup': Bluecup,
    'dancing': Dancing,
    'diamond': Diamond,
    'door': Door,
    'explosion': Explosion,
    'fireball': Fireball,
    'fireBonus': Firebonus,
    'firehat': Firehat,
    'firemaster': Firemaster,
    'frost': Frost,
    'gameName': GameName,
    'hatbeer': Hatbeer,
    'hatbluestar': Hatbluestar,
    'hatbow': Hatbow,
    'hatcat': Hatcat,
    'hatfire': Hatfire,
    'hatpurpstar': Hatpurpstar,

    'hatcoolblue': Hatcoolblue,
    'hatcoolgreen': Hatcoolgreen,
    'hatcoolpurple': Hatcoolpurple,
    'hatflowersred': Hatflowersred,
    'hatflowersgreen': Hatflowersgreen,
    'hatflowerspurple': Hatflowerspurple,

    'hatrainbow': Hatrainbow,
    'hattree': Hattree,
    'hatbearfur': Hatbearfur,
    'hatdragonblue': Hatdragonblue,
    'hatdragonred': Hatdragonred,
    'hatmountain': Hatmountain,
    'hatwater': Hatwater,

    'icicle': Icicle,
    'joint': Joint,
    'lighter': Lighter,
    'lock': Lock,
    'redCup': RedCup,
    'icemaster': Icemaster,
    'shot': Shot,
    'shower': Shower,
    'smoke': Smoke,
    'splash': Splash,
    'squirrel': Squirrel,
    'stick': Stick,
    'stun': Stun,
    'sword': Sword,
    'target': Target,
    'target_white': TargetWhite,
    'targetAllies': TargetAllies,
    'targetAlly': TargetAlly,
    'targetEnemies': TargetEnemies,
    'targetEnemy': TargetEnemy,
    'targetSelf': TargetSelf,
    'thermostat': Thermostat,
    'theWizard': TheWizard,
    'wastey':Wastey,
    'wasteyAngry':WasteyAngry,
    'vines': Vines,
    'wand': Wand,
    'water': Water,
    'tallWater': TallWater,
    'rum': Rum,
    'weed': Weed,
    'powder': Powder,
    'fist': Fist,
    'slap': Slap,
    'fireball2': Fireball2,
    'littlebomb': Littlebomb,
    'timebomb': Timebomb,
    'fire': Fire,
    'earthBonus': EarthBonus,
    'snowball': Snowball,
    'questionmark': Questionmark,
    'sun': Sun,
    'remote': Remote,
    'blizzard': Blizzard,
    'earthquake': Earthquake,
    'freeze': Freeze,
    'hotbox': Hotbox,
    'shatter': Shatter,
    'soap': Soap,
    'avalanche': Avalanche,
    'trap': Trap,
    'iceblock': Iceblock,
    'deck': Deck,
    'hat': Hat,
    'wasteynohat': Wasteynohat,
    'wasteynohatgreen': Wasteynohatgreen,
    'wasteynohatred': Wasteynohatred,
    'hatbasic': Hatbasic,
    'hatbasicred': Hatbasicred,
    'hatbasicgreen': Hatbasicgreen,
    'hatleaves': Hatleaves,
    'hatbeer2': Hatbeer2,
    
    'wasteynohatbrown': Wasteynohatbrown,
    'wasteynohatblue': Wasteynohatblue,
    'wasteynohatlightblue': Wasteynohatlightblue,
    'wasteynohatlightgreen': Wasteynohatlightgreen,
    'wasteynohatlightpurple': Wasteynohatlightpurple,
    'wasteynohatrainbow': Wasteynohatrainbow,
    'wasteynohatdragonred': Wasteynohatdragonred,  
    'wasteynohatgrey': Wasteynohatgrey,
    'eyes': Eyes,
    'shield': Shield,
    'rock': Rock,
    'shards': Shards,
    'drainlife': Drainlife,
    'blood': Blood,
    'heatin': Heatin,
    'fire_white': FireWhite,
    'stun_white': StunWhite,
    'smoke_white': SmokeWhite,
    'trap_white': TrapWhite,
    'whiskey': Whiskey,
    'everclear': Everclear,
    'everclear2': Everclear2,
    'sac': Sac,
    'longstaff': Longstaff,
    'dagger': Dagger,
    'tome': Tome,
    'sash': Sash,
    'pendant': Pendant,
    'pants': Pants,
    'ring': Ring,
    'belt': Belt,
    'trousers': Trousers,
    'tunic': Tunic,
    'chestplate': Chestplate,
    'beer': Beer ,
    'whiskey_glass': WhiskeyGlass,
    'esspresso_martini': EspressoMartini ,
    'water_tall': WaterTall ,
    'fireball_shot': FireballShot ,
    'hot_toddy': HotToddy,
    'long_island': LongIsland,
    'tequila_shot': TequilaShot,
    'absinthe': Absinthe,
    'staff': Staff,
    'consumefire': Consumefire,
    'bloodymary': Bloodymary,
    'grenade': Grenade,
    'wasteybear': Wasteybear,
    'wasteyfired': Wasteyfired,
    'wasteyiced': Wasteyiced,
    'margarita': Margarita,

    'shieldgreen': Shieldgreen,
    'shieldblue': Shieldblue,
    'shieldred': Shieldred,
    'fireballs': Fireballs,
    'lantern': Lantern,
    'clothboots': ClothBoots,
    'studdedboots': StuddedBoots,
    'bow': Bow,
    'forcefield': ForceField,
    'forcefield2': ForceField2
}