let burnSomeone = require('../cards/allCards/spells/burnSomeone');
let consumingFlames = require('../cards/allCards/spells/consumingFlames');
let explosion = require('../cards/allCards/spells/explosion');
let turnUpTheHeat = require('../cards/allCards/spells/turnUpTheHeat');
let firemaster = require('../cards/allCards/spells/firemaster');
let littlebomb = require('../cards/allCards/spells/littlebomb');
let recovery = require('../cards/allCards/spells/recovery');

let icicles = require('../cards/allCards/spells/icicles');
let iceToFace = require('../cards/allCards/spells/iceToFace');
let letInTheCold = require('../cards/allCards/spells/letInTheCold');
let coldmaster = require('../cards/allCards/spells/coldmaster');
let bustamove = require('../cards/allCards/spells/bustamove');
let snowball = require('../cards/allCards/spells/snowball');

let vines = require('../cards/allCards/spells/vines');
let hitemwithastick = require('../cards/allCards/spells/hitemwithastick');
let heal = require('../cards/allCards/spells/heal');
let naturesStrength = require('../cards/allCards/spells/naturesStrength');
let smack = require('../cards/allCards/spells/smack');

let heatinup = require('../cards/allCards/spells/heatinup');

let flashbacks = require('../cards/allCards/spells/flashbacks')
let fireball = require('../cards/allCards/spells/fireball');
let firestorm = require('../cards/allCards/spells/firestorm');
let detonate = require('../cards/allCards/spells/detonate');

let coldShower = require('../cards/allCards/spells/coldShower');
let freeze = require('../cards/allCards/spells/freeze');
let drainlife = require('../cards/allCards/spells/drainlife');
let shards = require('../cards/allCards/spells/shards');

let blizzard = require('../cards/allCards/spells/blizzard');
let shatter = require('../cards/allCards/spells/shatter');
let avalanche = require('../cards/allCards/spells/avalanche');
let cleanse = require('../cards/allCards/spells/cleanse');

let fists = require('../cards/allCards/spells/fists');
let smokescreen = require('../cards/allCards/spells/smokescreen');
let meditate = require('../cards/allCards/spells/meditate')
let earthquake = require('../cards/allCards/spells/earthquake');
let squirrels = require('../cards/allCards/spells/squirrels');
let shield = require('../cards/allCards/spells/shield');
let grenade = require('../cards/allCards/spells/grenade');

let askforalight = require('../cards/allCards/spells/askforalight');

const tomeSpell = require('../cards/allCards/spells/tome');
const dagger = require('../cards/allCards/armor/dagger');
const magicSackSpell = require('../cards/allCards/spells/magicSack');

const margarita = require('../cards/allCards/potions/margarita');
let tequila = require('../cards/allCards/potions/tequila');
let water = require('../cards/allCards/potions/water');
let adios = require('../cards/allCards/potions/adios');
const vodka = require('../cards/allCards/potions/vodka');
const beer = require('../cards/allCards/potions/beer');
const everclear = require('../cards/allCards/potions/everclear');
let whiskey = require('../cards/allCards/potions/whiskey');


const cinnomonwhiskey = require('../cards/allCards/potions/cinnomonwhiskey');
const esspressomartini = require('../cards/allCards/potions/esspressomartini');
const hottoddy = require('../cards/allCards/potions/hottoddy');
const bloodymary = require('../cards/allCards/potions/bloodymary');

const belt = require('../cards/allCards/armor/belt');
const silksash = require('../cards/allCards/armor/silksash');
const ring = require('../cards/allCards/armor/ring');
const pendant = require('../cards/allCards/armor/pendant');
const trousers = require('../cards/allCards/armor/trousers');
const pants = require('../cards/allCards/armor/pants');
const tunic = require('../cards/allCards/armor/tunic');
const chestplate = require('../cards/allCards/armor/chestplate');
const lantern = require('../cards/allCards/armor/lantern');
const clothboots = require('../cards/allCards/armor/clothboots');
const studdedboots = require('../cards/allCards/armor/studdedboots');
const spellbook = require('../cards/allCards/armor/spellbook');
const forcefield1 = require('../cards/allCards/armor/forcefield1');
const forcefield2 = require('../cards/allCards/armor/forcefield2');
const bow = require('../cards/allCards/armor/bow');

// shared deck

// team 1 discard

// team 2 discard


const SharedBasicSpellCounts = {
    [icicles.name]: 1,
    [iceToFace.name]: 1,
    [letInTheCold.name]: 1,
    [drainlife.name]: 1,
    [coldmaster.name]: 1,
    [snowball.name]: 1,
   // [bustamove.name]: 1,
  
    [hitemwithastick.name]: 1,
    [vines.name]: 1,
    [heal.name]: 1,
    [shield.name]: 1,
    [smack.name]: 1,
    [grenade.name]: 1,

    [fireball.name]: 1,
    [turnUpTheHeat.name]: 1,
    [burnSomeone.name]: 1,
    [firestorm.name]: 1,
    [explosion.name]: 1,
    [firemaster.name]: 1
}

const SharedDefensiveSpellCounts = {
    [firemaster.name]: 1,
    [shield.name]: 1,
    [heal.name]: 1,
    [coldmaster.name]: 1,
    [snowball.name]: 1,
}

const SharedDefensiveSpecialSpellCounts = {
    [shards.name]: 1,
    [shatter.name]: 1,
   
    [askforalight.name]: 1,
    [meditate.name]: 1,

    [consumingFlames.name]: 1,
    [smokescreen.name]: 1,
}

const SharedSpecialSpellCounts = {
  [shards.name]: 1,
  [shatter.name]: 1,
  [blizzard.name]: 1,

  [askforalight.name]: 1,
  [squirrels.name]: 1,
  [meditate.name]: 1,
  
  [flashbacks.name]: 1,
  [consumingFlames.name]: 1,
  [smokescreen.name]: 1,
  
}

const SharedLevel3SpellCounts = {
  [freeze.name]: 1,
  [earthquake.name]: 1,
  [heatinup.name]: 1,
}

function StartingDeck(){
    return {
      [explosion.name]: true,
      [burnSomeone.name]: true,
      [fireball.name]: true,
      [turnUpTheHeat.name]: true,
      [firemaster.name]: true,
      [firestorm.name]: true,

      [hitemwithastick.name]: true,
      [heal.name]: true,
      [smack.name]: true,
      [grenade.name]: true,
      [shield.name]: true,
      [vines.name]: true,

      [letInTheCold.name]: true,
      [icicles.name]: true,
      [snowball.name]: true,
      [iceToFace.name]: true,
      [coldmaster.name]: true,
      [drainlife.name]: true,
    

     // [detonate.name]: true,
      [smokescreen.name]: true,
      [consumingFlames.name]: true,
      [flashbacks.name]: true,
      [heatinup.name]: true,
      
      [askforalight.name]: true,
      [meditate.name]: true,
      [earthquake.name]: true,
      [squirrels.name]: true,
      
      [shatter.name]: true,
      [shards.name]: true,
      [blizzard.name]: true,
      [freeze.name]: true,
     

      // [daggerSpell.name]: true,
      // [tomeSpell.name]: true,
      // [magicSackSpell.name]: true,
    }
}

// learn
function Tutorial1(){
  return {
    [icicles.name]: true,
    [iceToFace.name]: true,
    [letInTheCold.name]: true,
  }
}

function Tutorial2(){
  return {
    [freeze.name]: true,
    [shards.name]: true,
    [shatter.name]: true,
  }
}

function Tutorial3(){
  return {
    [burnSomeone.name]: true,
    [turnUpTheHeat.name]: true,
    [fireball.name]: true,
    [explosion.name]: true,
    [heatinup.name]: true,
    [flashbacks.name]: true,
  }
}

function TutorialAI(){
  return {
    [letInTheCold.name]: true,
    [iceToFace.name]: true,
    [recovery.name]: true,
    [bustamove.name]: true,
    [squirrels.name]: true,
    [askforalight.name]: true,
    //[turnUpTheHeat.name]: true,
    //[smokescreen.name]: true,
  }
}

function TutorialAI2(){
  return {
    [letInTheCold.name]: true,
    [iceToFace.name]: true,
    [recovery.name]: true,
    [bustamove.name]: true,
    [squirrels.name]: true,
   // [askforalight.name]: true,
    [turnUpTheHeat.name]: true,
    //[smokescreen.name]: true,
  }
}


// 1v1 out tactical
function IceOnly(){
  return {
    [icicles.name]: true,
    [letInTheCold.name]: true,
    [iceToFace.name]: true,
    [drainlife.name]: true,
    [coldmaster.name]: true,
    [snowball.name]: true,

    [freeze.name]: true,
    [shards.name]: true,
    [shatter.name]: true,
    [blizzard.name]: true
  }
}

// 2v2 ice and earth
function EarthOnly(){
  return {
    [vines.name]: true,
    [hitemwithastick.name]: true,
    [heal.name]: true,
    [smack.name]: true,
    [grenade.name]: true,

    [squirrels.name]: true,
    [askforalight.name]: true,
    [meditate.name]: true,
    [earthquake.name]: true,
  }
}

// 1v2 low health fireballers
function FireOnly(){
  return {
    [fireball.name]: true,
    [turnUpTheHeat.name]: true,
    [explosion.name]: true,
    [burnSomeone.name]: true,
    [smokescreen.name]: true,

    [heatinup.name]: true,
    [flashbacks.name]: true,
    [detonate.name]: true,
    [consumingFlames.name]: true
  }
}

function DamageTest(){
  return {
    [recovery.name]: true,
    [explosion.name]: true,
    [hitemwithastick.name]: true,
    [smack.name]: true,
    [icicles.name]: true,
    [iceToFace.name]: true
  }
}

function HealTest(){
  return {
    [fireball.name]: true,
    [shards.name]: true,
    [grenade.name]: true,
    [bustamove.name]: true,
    [vines.name]: true,
    [meditate.name]: true,
  }
}

function HealTest2(){
  return {
    [fireball.name]: true,
    [explosion.name]: true,
    [turnUpTheHeat.name]: true,
    [meditate.name]: true,
    [askforalight.name]: true,
    [squirrels.name]: true,
  }
}

function Items(){
  return [

    esspressomartini.name,
    whiskey.name,
    //cinnomonwhiskey.name,
    
    margarita.name,

    // healing 3
    beer.name,
    water.name, // healing potion
    bloodymary.name,
   
    // bloody mary - heal when fire damage done
    //hottoddy.name,

    //
   
    //vodka.name,
    everclear.name,
    tequila.name,
    adios.name,
    
  ]
}


function Weapons(){
  return [
    //daggerSpell.name,
    //tomeSpell.name,
    //magicSackSpell.name,
  ]
}

// Basic armor is shown in tree from beginning.
// Some basic armor requires other armors.
function BasicArmor(){
  return [
    ring.name,
    pendant.name,
    spellbook.name,
   // belt.name,

  //  tunic.name, // no reqs
  //  chestplate.name, // requires tunic
  //  forcefield1.name,

   // trousers.name,
   // ring.name, // no reqs, upgrades to pendant
    trousers.name,
    belt.name, // requires pendant
    lantern.name,
    //pants.name, // requires pendant

    clothboots.name,
    dagger.name,
    bow.name
  ]
}

function AdvancedArmor(){
  return [
    silksash.name, // ugrades from belt
    studdedboots.name,
    pants.name,
  //  forcefield2.name,

  
   // pants.name,
  //  pendant.name, // upgrades from ring
   // chestplate.name,
  ]
}

module.exports = {
   StartingDeck: StartingDeck,
   Tutorial1: Tutorial1,
   Tutorial2: Tutorial2,
   Tutorial3: Tutorial3,
   TutorialAI: TutorialAI,
   TutorialAI2: TutorialAI2,
   HealTest: HealTest,
   HealTest2: HealTest2,
   IceOnly: IceOnly,
   EarthOnly: EarthOnly,
   FireOnly: FireOnly,
   DamageTest: DamageTest,
   Items: Items,
   Weapons: Weapons,
   BasicArmor: BasicArmor,
   AdvancedArmor: AdvancedArmor,
   Armor: () => BasicArmor().concat(AdvancedArmor()),

   SharedBasicSpellCounts,
   SharedDefensiveSpellCounts,
   SharedSpecialSpellCounts,
   SharedDefensiveSpecialSpellCounts,
   SharedLevel3SpellCounts
}