
module.exports = {
    spellCast: "spellCast",
    spellBegunCasting: "spellBegunCasting",
    damageDone: "damageDone",
    healingDone: "healingDone",
    spellReflected: "spellReflected",
   // stateAdded: "stateAdded", // TODO
    spellDodged: "spellDodged",
    spellMissed: "spellMissed",
    damageDoneByThisSpell: "damageDoneByThisSpell"
}