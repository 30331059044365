

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Earthquake",
    description: "Stun all enemies.",
    spellType: spellTypes.earth,
    image: "earthquake",
    level: 2,
   //charges: 1,
    castTime: 1.5,
    duration: 4,
    cooldown: 30,
    damage: 6,
    target: targets.selections.targetEnemies,
    doubleState: "stun",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.allEnemies, 6, spellTypes.earth],
        },
        {
            action: actions.addTimerState,
            actionParams: [
                targets.allEnemies, 
                states.stun,
                4
            ],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}