
import React, {useState, useEffect, useReducer, cloneElement, useCallback} from 'react';
import './SettingsPage.scss';
import BigButton from '../menu/components/BigButton';
import TextField from '../menu/components/TextField'
import { useNavigate } from "react-router-dom";

const storageValues = require("../storageValues");
const serverMessages = require("../../../shared/serverMessages");

let {Hats} = Object.keys(require("shared/hats"));

export default function SettingsPage( props ) {

    let { me, name, setName, ioConnection, hideSettings, gameID} = props;

   
    const [myHat, setHat] = useState(me?.hat || "hatleft");
    let navigate = useNavigate();

    return <React.Fragment>
        <div className="gameSettingsBackground"></div>
        <div className="gameSettingsPage">

      
            {/* <div className="gameID">
                <div className="gameIDTitle">Game ID:</div>
                <div className="gameIDName">{gameID}</div>
            </div>

            <div className="changeName">
                <TextField
                    label={"Your player name"}
                    value={name}
                    error={""}
                    helperText={""}
                    onChange={(e) => {
                        setName(e.target.value);
                        ioConnection.emit(serverMessages.requests.changeName, {name: e.target.value});
                    }}
                />
            </div>


            <div className={`hatChoices`}>
                {Hats.map(hat => {
                    return <div className={`hatChoice ${hat==myHat?'selected':''}`} onClick={()=>{
                            setHat(hat);
                            ioConnection.emit(serverMessages.requests.changeHat, {hat});
                        }}>
                        <img src={`./images/${hat}.png`}/>
                    </div>
                })}
            </div> */}

            <div className="bottomButtonArea">
                {/* <div className="leaveButton">
                    <BigButton 
                        red 
                        onClick={()=>{
                            ioConnection.emit(serverMessages.requests.leave, {});
                            hideSettings();
                        }} >
                            Leave Game
                    </BigButton>
                </div> */}

                <div className="returnButton">
                    <BigButton 
                        red 
                        onClick={()=>{
                            ioConnection.emit(serverMessages.requests.leave, {});
                            hideSettings();
                            navigate('/')
                        }} >
                            Main Menu (Leave)
                    </BigButton>
                </div>
            </div>

            <div className="footerWrapper">
                <div className="footer">
                    <div className="settingsButton" onClick={()=>{
                            hideSettings();
                        }}> 
                        <span className="material-symbols-outlined">
                                close
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}