
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Cold Shower",
    description: "Remove all effects from an enemy.",
    spellType: spellTypes.ice,
    level: 2,
   // charges: 1,
    castTime: 2,
    cooldown: 12,
    damage: 0,
    target: targets.selections.targetEnemy,
    image: "shower",
    cooldownLocked: true,
    flair: "With great shower comes great responsibility.",
    effects: [
        {
            action: actions.removeTimer,
            actionParams: [targets.selectedPlayer]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}