




let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Flashbacks",
    description: "Add 3 delayed damage each time fire damage is done to the target.",
    spellType: spellTypes.fire,
    level: 2,
    castTime: 1.5,
    cooldown: 30,
    damage: 0,
    duration: 10,
    target: targets.selections.targetEnemy,
    image: "timebomb",
    effectNumber: "/3x",
    effects: [
        {
            action: actions.addTimerConditionPromptEffect,
            actionParams: [
                targets.selectedPlayer,
                {
                    action: actions.addTimerPeriodicEffect,
                    image: "flashbacks",
                    actionParams: [
                        targets.selectedPlayer, 
                        {
                            action: actions.damage,
                            actionParams: [3, spellTypes.fire],
                            image: "fire",
                            isDebuff: true
                        }, 
                        20, 
                        20
                    ],
                },
                [conditions.damageDone, null, targets.selectedPlayer, spellTypes.fire], // damage done to me
                10
            ]
          }
    ],
    xpGain: 0,
    manaCost: 1,
}
