import React, {useState, useEffect, useReducer} from 'react';
import './PlayerArea.scss'


export default function CastBall( props ) {

    const {
        myKey,
        animations, 
        left,
        top,
        delay,
        addedClass
    } = props;


    let keyTokens = myKey.split("_");
    keyTokens.pop();
    let parentKey = keyTokens.join("_"); 

    let animColor = "";
    if(animations[parentKey]){
        animColor = animations[parentKey]
    }

    if(!animColor) return null;

    return  <div 
        className={`castBall ${addedClass} ${animColor}`} 
        style={{
            position: "absolute", 
            left: `${left}px`, 
            top: `${top}px`,  
            animationDelay: `${delay}s`
        }}>
    </div>
}