

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Turn up the Heat",
    description: "You gain +2 fire damage.",
    spellType: spellTypes.fire,
    level: 1,
   // charges: 1,
    castTime: 2,
    cooldown: 20,
    duration: 10,
    damage: 3,
    target: targets.selections.targetEnemies,
    image: "thermostat",
    effectNumber: "/2",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.allEnemies, 3, spellTypes.fire],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, 
                {
                    buff: buffs.damageTypeBonus,
                    buffParams: [2, spellTypes.fire]
                }, 
                10, 
            ]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}

