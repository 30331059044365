
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "The Green Fairy",
    description: "Go invisible for 10 seconds.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    damage: 0,
    level: 2,
    duration: 10,
    target: targets.selections.targetSelf,
    image: "absinthe",
    item: true,
    cost: 9,
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
              targets.me, 
              {
                buff: buffs.dodgeChance,
                buffParams: [100]
              }, 
              10, 
            ],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}