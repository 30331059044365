
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Margarita",
    description: "Your spells cannot be reflected.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    damage: 0,
    level: 1,
    duration: 10,
    target: targets.selections.targetSelf,
    image: "margarita",
    item: true,
    cost: 3,
    effects: [
        {
            action: actions.addTimerState,
            actionParams: [
                targets.me, 
                stateBuffs.penetrate, 
                10,
            ],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}