import React, {useState} from 'react';
import BigButton from '../../menu/components/BigButton'
import './LobbyButtons.scss'

const serverMessages = require("shared/serverMessages");
const {Tutorial1, Tutorial2, Tutorial3, TutorialAI, TutorialAI2, EarthOnly, FireOnly, IceOnly} = require('shared/decks/providedDecks');

let {Hats, Robes} = require("shared/hats");

function LobbyButtons ( props ) {

    const {
        ioConnection,
        selectedAlly,
        selectedEnemy,
        otherPlayers,
        setSelectedAlly,
        setSelectedEnemy,
        isHost,
        gameID,
        playersLocked
    } = props;

    let twoSelections = false;
    let selectedOtherPlayer = null;
    if(selectedAlly && !selectedEnemy){
        selectedOtherPlayer = selectedAlly;
    } else if(!selectedAlly && selectedEnemy){
        selectedOtherPlayer = selectedEnemy;
    } else if(selectedAlly && selectedEnemy){
        twoSelections = true;
    }

    const [copied, setCopied] = useState(false)

    if(!isHost){
        return null
    }
   
    return <div className="LobbyButtonArea">
            <div className="LobbyButtons">

                 <div className="ActionButtons">
                   
            
                        { playersLocked === false && !copied && Object.keys(otherPlayers).length < 5 && <div className="LobbyButton">
                            <BigButton  
                                disabled={false}
                                onClick={()=> { 
                                    setCopied(true); 
                                    window.copyToClipboard(location.protocol + '//' + location.host + '/game/' + gameID);
                                    setTimeout(()=> setCopied(false), 4000); 
                                }} >
                                    Invite
                            </BigButton>
                        </div> }

                        { playersLocked === false && copied && <div className="LobbyButton">
                            <BigButton  
                                disabled={false}
                                blue
                                onClick={()=> {}} >
                                    Copied!
                            </BigButton>
                        </div> }

                        {/* { selectedOtherPlayer != null && otherPlayers[selectedOtherPlayer] && !otherPlayers[selectedOtherPlayer].isAIPlayer && Object.keys(otherPlayers).length < 5 && <div className="LobbyButton">
                            <BigButton  
                                disabled={selectedOtherPlayer == null}
                                purple
                                onClick={()=> {}} >
                                    Switch Team
                            </BigButton>
                        </div>} */}

                        {/* { selectedOtherPlayer != null && otherPlayers[selectedOtherPlayer] && otherPlayers[selectedOtherPlayer].isAIPlayer && <div className="LobbyButton">
                            <BigButton  
                                disabled={selectedOtherPlayer == null}
                                purple
                                onClick={()=> {}} >
                                    Edit AI
                            </BigButton>
                        </div>} */}

                        { playersLocked === false && selectedOtherPlayer != null && otherPlayers[selectedOtherPlayer] && <div className="LobbyButton">
                            <BigButton  
                                disabled={selectedOtherPlayer == null}
                                orange
                                onClick={()=> {
                                    ioConnection.emit(serverMessages.requests.removePlayer, {id: otherPlayers[selectedOtherPlayer].id})
                                    setSelectedAlly(null);
                                    setSelectedEnemy(null);
                                }} >
                                    Remove
                            </BigButton>
                        </div>}

                        { playersLocked === false && !twoSelections && (selectedOtherPlayer == null || !otherPlayers[selectedOtherPlayer]) && Object.keys(otherPlayers).length < 5 &&  <div className="LobbyButton">
                            <BigButton  
                            disabled={false}
                            grey
                            onClick={()=> ioConnection.emit(serverMessages.requests.addAIPlayer, {
                                // deck: EarthOnly(),
                                // name: "Grizzle",
                                // hat: Hats.bearfur,
                                // robe: Robes.brown
                            })} >
                                Add AI
                        </BigButton>
                        </div>}
                    </div>

                   {Object.keys(otherPlayers).length != 0 && <div className="StartButton">

                
                            <BigButton 
                                green 
                                disabled={Object.keys(otherPlayers).length == 0}
                                onClick={()=> {
                                    if(soundsOn)Sounds.click.play();
                                    ioConnection.emit(serverMessages.requests.ready, {});
                                    setSelectedAlly(null);
                                    setSelectedEnemy(null);
                                }} >
                                    Start Game!
                            </BigButton>
                      

                    </div>}
        
            </div>
                

    </div>
}


export default LobbyButtons;