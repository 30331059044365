import React, {useState, useEffect, useReducer, useRef} from 'react';
import Player from './Player.js';
import './PlayerArea.scss'
import CastBall from './CastBall.js'
let targets = require('shared/cardConstants/targets');
const results = require('shared/outcomes.js');
const serverMessages = require("shared/serverMessages");


function PlayerArea( props ) {

    const {
        gameType,
        me,
        time,
        ioConnection,
        otherPlayers,
        enemies = [],
        allies = [],
        selectedAlly,
        selectedEnemy,
        setSelectedAlly,
        setSelectedEnemy,
        myCastingSpellData,
        selectedSpellData,
        castingData,
        isLobby,
        castSelectedSpell,
        nextCastSelectedSpell,
        nextCastTarget,
        isFighting,
        countdown,
        fadeOut,
        winningTeam,
        currEventData,
        winningPlayers = {},
        gameTotals = {[results.totalDamage]:0, [results.totalHealing]:0},
    } = props;

    // TODO game type will determine player arrangment

    let cannotCast= false;
    
    if(selectedSpellData){
        cannotCast = selectedSpellData.onCooldown || (!me.unstunnable && me.stun && !selectedSpellData.alwaysCast) || castingData;
    }

    if(!isFighting || countdown != 0 ){
        cannotCast = true;
    }

   // const ableToCast = selectedSpellData && !castingData;
    

    const canSelectEnemies = selectedSpellData && selectedSpellData.target == targets.selections.targetEnemies;
    const canSelectEnemy = selectedSpellData && selectedSpellData.target == targets.selections.targetEnemy;
    const canSelectAlly = selectedSpellData && selectedSpellData.target == targets.selections.targetAlly;
    const canSelectAllies = selectedSpellData && selectedSpellData.target == targets.selections.targetAllies;
    const canSelectSelf = selectedSpellData && selectedSpellData.target == targets.selections.targetSelf;



    const [castBalls, setCastBalls] = useState({});

    const [reflectBalls, setReflectBalls] = useState({});

    const [hitBalls, setHitBalls] = useState({});

    const [aoeBalls, setAoeBalls] = useState({});

    const [animations, setAnimations] = useReducer((state, data) => {
        if(data){
            let newAnimations = {...state};
            if(data.target){
                // this allows us to hold 2 casts in one ball line
                if(newAnimations[`${data.caster}_${data.target}`] && data.type ){
                    newAnimations[`${data.caster}_${data.target}`] = newAnimations[`${data.caster}_${data.target}`] + ` double _${data.type}`
                } else {
                    newAnimations[`${data.caster}_${data.target}`] = data.type;
                }
                
            } else {
                if(newAnimations[`${data.caster}`] && data.type ){
                    newAnimations[`${data.caster}`] =  newAnimations[`${data.caster}`] + ` double _${data.type}`
                } else {
                    newAnimations[`${data.caster}`] = data.type;
                }
               
            }
            return newAnimations;
        }
    }, {})

    const [reflectAnimations, setReflectAnimations] = useReducer((state, data) => {
        if(data){
            let newAnimations = {...state};
            if(data.target){
                // this allows us to hold 2 casts in one ball line
                if(newAnimations[`${data.caster}_${data.target}`] && data.type ){
                    newAnimations[`${data.caster}_${data.target}`] = newAnimations[`${data.caster}_${data.target}`] + ` double _${data.type}`
                } else {
                    newAnimations[`${data.caster}_${data.target}`] = data.type;
                }
                
            } 
            return newAnimations;
        }
    }, {});

    const [aoeAnimations, setAoeAnimations] = useReducer((state, data) => {
        if(data){
            let newAnimations = {...state};
            newAnimations[`${data.team}`] = data.type;
            return newAnimations;
        }
    }, {})

    const animTimer = useRef();
    const reflectTimer = useRef();
    useEffect(()=>{
        animTimer.current = {};
        reflectTimer.current = {};
    }, [])

    const playAnimation = (caster, target, type) => {
        setAnimations({caster, target, type});
        if(me.id && caster == me.id && target != me.id) if(soundsOn) Sounds.whoosh.play();
        
        if(animTimer.current[`${caster}_${target}`]){
            clearTimeout(animTimer.current[`${caster}_${target}`]);
        } 
        animTimer.current[`${caster}_${target}`] = setTimeout(()=> {
            setAnimations({caster, target, type: false});
        }, 780);
    }

    const playReflectAnimation = (caster, target, type) => {
        if(soundsOn)Sounds.reflect.play();
        setReflectAnimations({caster, target, type});
        if(reflectTimer.current[`${caster}_${target}`]){
            clearTimeout(reflectTimer.current[`${caster}_${target}`]);
        } 
        reflectTimer.current[`${caster}_${target}`] = setTimeout(()=> {
            setReflectAnimations({caster, target, type: false});
        }, 780);
    }

    const playAoeAnimation = (team, type) => {
        if(soundsOn)Sounds.reflect.play();
        setAoeAnimations({team, type});
        // if(reflectTimer.current[`${caster}_${target}`]){
        //     clearTimeout(reflectTimer.current[`${caster}_${target}`]);
        // } 
        setTimeout(()=> {
            setAoeAnimations({team, type, type: false});
        }, 780);
    }

    useEffect(()=>{
        setTimeout(()=>{
        // this is all render balls
        let castBalls = {};
        let reflectBalls = {};
        let hitBalls = {};
        let aoeBalls = {};

        let playerpositions = {};
        let targetpositions = {};

        let team0minX = 1000000;
        let team0maxX = 0;
        let team0minY = 1000000;
        let team0maxY = 0;

        let team1minX = 1000000;
        let team1maxX = 0;
        let team1minY = 1000000;
        let team1maxY = 0;


        otherPlayers && Object.keys(otherPlayers).map(id => {
            var playerEl = document.getElementById(id);
            if(!playerEl) return;
            let xOffset = playerEl.getBoundingClientRect().width - playerEl.getBoundingClientRect().width/4;
            let yOffset = playerEl.getBoundingClientRect().height/4;
            let myX = playerEl.getBoundingClientRect().x - playerEl.parentElement.parentElement.getBoundingClientRect().x;
            let myY = playerEl.getBoundingClientRect().y;
            playerpositions[id] = [myX + xOffset, myY + yOffset];
            targetpositions[id] = [myX + xOffset - playerEl.getBoundingClientRect().width/4, myY + yOffset];

            let bottomY = myY + playerEl.getBoundingClientRect().height/2;
            let farRight = myX + playerEl.getBoundingClientRect().width;
            if(otherPlayers[id].team == 0){
                if(myX < team0minX) team0minX = myX;
                if(myY < team0minY) team0minY = myY;
                if(farRight > team0maxX) team0maxX = farRight;
                if(bottomY > team0maxY) team0maxY = bottomY;
            } else {
                if(myX < team1minX) team1minX = myX;
                if(myY < team1minY) team1minY = myY;
                if(farRight > team1maxX) team1maxX = farRight;
                if(bottomY > team1maxY) team1maxY = bottomY;
            }
       
            // the balls for 'hitting' animation (over players)
            // var playerEl = document.getElementById(id);
            for(let i = 0; i < 1; i++){
                let key = `${id}_${i}`;
                hitBalls[key] = {
                    key: key,
                    left: targetpositions[id][0] - playerEl.getBoundingClientRect().width/6, 
                    top: targetpositions[id][1] - playerEl.getBoundingClientRect().height/6, 
                    delay: 0
                }
            }
        });
        if(me && me.id){
            var myEl = document.getElementById(me.id);
            let xOffset = myEl.getBoundingClientRect().width - myEl.getBoundingClientRect().width/4;
            let yOffset = myEl.getBoundingClientRect().height/4;
            let myX = myEl.getBoundingClientRect().x - myEl.parentElement.parentElement.getBoundingClientRect().x;
            let myY = myEl.getBoundingClientRect().y;
            playerpositions[me.id] = [myX + xOffset, myY + yOffset];
            targetpositions[me.id] = [myX + xOffset - myEl.getBoundingClientRect().width/4, myY + yOffset];

            let bottomY = myY + myEl.getBoundingClientRect().height/2;
            let farRight = myX + myEl.getBoundingClientRect().width;
            if(me.team == 0){
                if(myX < team0minX) team0minX = myX;
                if(myY < team0minY) team0minY = myY;
                if(farRight > team0maxX) team0maxX = farRight;
                if(bottomY > team0maxY) team0maxY = bottomY;
            } else {
                if(myX < team1minX) team1minX = myX;
                if(myY < team1minY) team1minY = myY;
                if(farRight > team1maxX) team1maxX = farRight;
                if(bottomY > team1maxY) team1maxY = bottomY;
            }

            for(let i = 0; i < 1; i++){
                let key = `${me.id}_${i}`;
                hitBalls[key] = {
                    key: key,
                    left: targetpositions[me.id][0] - myEl.getBoundingClientRect().width/6, 
                    top: targetpositions[me.id][1] - myEl.getBoundingClientRect().height/6, 
                    delay: 0
                }
            }
        }

      

        // for each player
        Object.entries(playerpositions).map(([id, pos]) => {


            // draw line of render balls to each target for casting animation
            Object.entries(targetpositions).map(([targ_id, targ_pos]) => {
                if(id == targ_id) return;
                // distance between
                let dist = Math.sqrt(Math.pow(targ_pos[0] - pos[0], 2) + Math.pow(targ_pos[1] - pos[1], 2));
                // step length
                let steps = 17;
                let stepDist = dist / steps;
              
                // create first render ball
                castBalls[ `${id}_${targ_id}_${0}`] = {
                    key: `${id}_${targ_id}_${0}`, 
                    left: pos[0], 
                    top: pos[1],  
                    delay: 0
                }

                // loop step amount
                let xNorm = (targ_pos[0] - pos[0]) / dist;
                let yNorm = (targ_pos[1] - pos[1]) / dist;
                let xStep = xNorm * stepDist;
                let yStep = yNorm * stepDist;

                for(let i = 1; i < steps; i++){
                    let key = `${id}_${targ_id}_${i}`;
                    castBalls[key] = {
                        key: key,
                        left: pos[0] + xStep,
                        top: pos[1] + yStep, 
                        delay: (i * 1/25)
                    }
                    xStep += xNorm * stepDist;
                    yStep += yNorm * stepDist;
                }
                
            });
        });

        Object.entries(targetpositions).map(([id, pos]) => {
            // draw line of render balls to each target for casting animation
            Object.entries(targetpositions).map(([targ_id, targ_pos]) => {
                if(id == targ_id) return;
                // distance between
                let dist = Math.sqrt(Math.pow(targ_pos[0] - pos[0], 2) + Math.pow(targ_pos[1] - pos[1], 2));
                // step length
                let steps = 17;
                let stepDist = dist / steps;
              
                // create first render ball
                reflectBalls[ `${id}_${targ_id}_${0}`] = {
                    key: `${id}_${targ_id}_${0}`, 
                    left: pos[0], 
                    top: pos[1],  
                    delay: 0
                }

                // loop step amount
                let xNorm = (targ_pos[0] - pos[0]) / dist;
                let yNorm = (targ_pos[1] - pos[1]) / dist;
                let xStep = xNorm * stepDist;
                let yStep = yNorm * stepDist;

                for(let i = 1; i < steps; i++){
                    let key = `${id}_${targ_id}_${i}`;
                    reflectBalls[key] = {
                        key: key,
                        left: pos[0] + xStep,
                        top: pos[1] + yStep, 
                        delay: (i * 1/25)
                    }
                    xStep += xNorm * stepDist;
                    yStep += yNorm * stepDist;
                }
            })
        })

        let team0Xsize = team0maxX - team0minX;
        let team0Ysize = team0maxY - team0minY;
        let team1Xsize = team1maxX - team1minX;
        let team1Ysize = team1maxY - team1minY;

        aoeBalls[0] = [];
        // team 0 balls
        for(let i = 0; i < 20; i++){
            let x = (Math.random() * team0Xsize) + team0minX;
            let y = (Math.random() * team0Ysize) + team0minY;
            aoeBalls[0].push([x, y, Math.random()]);
        }

        aoeBalls[1] = [];
        for(let i = 0; i < 20; i++){
            let x = (Math.random() * team1Xsize) + team1minX;
            let y = (Math.random() * team1Ysize) + team1minY;
            aoeBalls[1].push([x, y, Math.random()]);
        }

        setCastBalls(castBalls);
        setReflectBalls(reflectBalls);
        setHitBalls(hitBalls);
        setAoeBalls(aoeBalls);

    }, 200);

    }, [me && me.id, otherPlayers && Object.keys(otherPlayers).length]);


    return <div className={`PlayerArea ${fadeOut?'fadeOut ':''} ${!isFighting?'notfighting ':''} ${!me || !me.id?'fullscreen':''}`}>
        <div className="SpellEffectArea">

            {
                otherPlayers && Object.values(castBalls).map(({key, left, top, delay}) => {
                    if(!key) return null;
                    return <CastBall 
                        myKey={key}
                        key={key} 
                        left={left}
                        top={top}
                        delay={delay}
                        animations={animations}
                    />
                })
            }

            {
                otherPlayers && Object.values(reflectBalls).map(({key, left, top, delay}) => {
                    if(!key) return null;
                    return <CastBall 
                        myKey={key}
                        key={key} 
                        left={left}
                        top={top}
                        delay={delay}
                        animations={reflectAnimations}
                    />
                })
            }

            {
                otherPlayers && Object.values(hitBalls).map(({key, left, top, delay}) => {
                    if(!key) return null;
                    return <CastBall 
                        addedClass={"hitBall"}
                        myKey={key}
                        key={key} 
                        left={left}
                        top={top}
                        delay={delay}
                        animations={animations}
                    />
                })
            }

            {
                aoeBalls[0] && Object.values(aoeBalls[0]).map((pos, i) => {
                    let left = pos[0];
                    let top = pos[1];
                    let animColor = aoeAnimations[0] || '';
                    return <div 
                        key={"team0"+i}
                        className={`castBall ${animColor} aoe` } 
                        style={{
                            position: "absolute", 
                            left: `${left}px`, 
                            top: `${top}px`,  
                            animationDelay: `${0.1 + pos[2]/5}s`
                        }}>
                    </div>
                })
            }
            {
                aoeBalls[1] && Object.values(aoeBalls[1]).map((pos, i) => {
                    let left = pos[0];
                    let top = pos[1];
                    let animColor = aoeAnimations[1] || '';

                    return <div 
                        key={"team0"+i}
                        className={`castBall ${animColor} aoe`} 
                        style={{
                            position: "absolute", 
                            left: `${left}px`, 
                            top: `${top}px`,  
                            animationDelay: `${0.1 + pos[2]/5}s`
                        }}>
                    </div>
                })
            }

        </div>
       <div className={`enemyPlayers`}>
           <React.Fragment>
           { (canSelectEnemies || nextCastTarget == 'enemies') && <div className={`castableArea ${nextCastTarget == 'enemies'?'willCast':''}`} onClick={() => {
                if(!cannotCast){
                    castSelectedSpell("enemies");
                } else {
                    nextCastSelectedSpell("enemies");
                }
           }}/>}
           { castingData && castingData.target == "enemies" && <div className="animWrapper"><div className="selectionAnimation"/></div>}
           {
               enemies.map(id => {
                    if(!otherPlayers || !otherPlayers[id]) return null;
                    return <Player 
                        showName={!isFighting || isLobby || winningTeam != -1}
                        key={id} 
                        isAlly={false}
                        eventData={currEventData[id] || {}}
                        winningTeam={winningTeam}
                        hasWon={winningPlayers[id]}
                        myCastingSpellData={myCastingSpellData}
                        totalDamage={gameTotals[results.totalDamage][id] || 0}
                        totalHealing={gameTotals[results.totalHealing][id] || 0}
                        onClick={()=> {
                            //  if(canSelectEnemy){
                            //      if(!cannotCast){
                            //          castSelectedSpell(id);
                            //      } else {
                            //         if(selectedOtherPlayer == id){
                            //             setSelectedOtherPlayer(null);
                            //         } else {
                            //             setSelectedOtherPlayer(id);
                            //         }
                            //      }
                            // } else 
                            // } else if(canSelectEnemies) {
                            //     if(!cannotCast){
                            //         castSelectedSpell('enemies');
                            //     } else {
                            //         nextCastSelectedSpell( 'enemies' );
                            //     }
                            // } else if(isLobby){
                                
                                    if(selectedEnemy == id){
                                        setSelectedEnemy(null);
                                    } else {
                                        setSelectedEnemy(id);
                                    }
                                
                                
                            //}
                        }}
                        isSelected={selectedAlly == id || selectedEnemy == id}
                        playerData={otherPlayers[id]}
                        hasSelection={ false}
                        playAnimation={playAnimation}
                        playReflectAnimation={playReflectAnimation}
                        playAoeAnimation={playAoeAnimation}
                       // isSelectable={((!otherPlayers[selectedOtherPlayer] || !otherPlayers[selectedOtherPlayer]?.isEnemy) && selectedSpellData && selectedSpellData.target == targets.selections.targetEnemy)}
                        allCastable={canSelectEnemies || nextCastTarget == 'enemies'}
                        clientIsCastingOn={castingData && (castingData.target == id || castingData.target == "enemies")}
                        willCastOn={nextCastTarget == id || nextCastTarget == 'enemies'}
                        time={time}
                    />
               })
           }
           </React.Fragment>
       </div>
       {props.children}
       <div className={`teamMates`}>
            <React.Fragment>
                { (canSelectAllies || nextCastTarget == 'allies') && <div className={`castableArea ${nextCastTarget == 'allies'?'willCast':''}`} onClick={()=> {
                    if(!cannotCast){
                        castSelectedSpell("allies");
                    } else {
                        nextCastSelectedSpell("allies");
                    }
                }}/>}
                { castingData && castingData.target == "allies" &&  <div className="animWrapper"><div className="selectionAnimation"/></div>}
                <React.Fragment>
                    {me && me.id && <Player 
                        showName={!isFighting || isLobby || winningTeam != -1}
                        eventData={currEventData[me.id] || {}}
                        key={me.id} 
                        isAlly={true}
                        myCastingSpellData={myCastingSpellData}
                        onClick={()=> {
                            if(selectedAlly == me.id){
                                setSelectedAlly(null);
                            } else {
                                setSelectedAlly(me.id);
                            }
                        }}
                        totalDamage={gameTotals[results.totalDamage][me.id] || 0}
                        totalHealing={gameTotals[results.totalHealing][me.id] || 0}
                        winningTeam={winningTeam}
                        hasWon={winningPlayers[me.id]}
                        playerData={me}
                        hasSelection={ false}
                        isSelected={selectedAlly == me.id}
                        playAnimation={playAnimation}
                        playReflectAnimation={playReflectAnimation}
                        playAoeAnimation={playAoeAnimation}
                        allCastable={canSelectAllies || nextCastTarget == 'allies'}
                        //isSelectable={((!otherPlayers[selectedOtherPlayer] || !otherPlayers[selectedOtherPlayer]?.isAlly) && selectedSpellData && (selectedSpellData.target == targets.selections.targetAlly || selectedSpellData.target == targets.selections.targetSelf) )}
                        ally
                        clientIsCastingOn={castingData && (castingData.target == me.id || castingData.target == "allies")}
                        willCastOn={nextCastTarget == me.id || nextCastTarget == 'allies'}
                        time={time}
                    />
                    }
                    {
                        allies.map( id => {
                            if( me && id == me.id){
                                return; // don't render me
                            } else {
                                if(!otherPlayers || !otherPlayers[id]) return null;
                                return <Player 
                                    isAlly={true}
                                    showName={!isFighting || isLobby || winningTeam != -1}
                                    eventData={currEventData[id] || {}}
                                    key={id} 
                                    myCastingSpellData={myCastingSpellData}
                                    onClick={()=> {
                                        // if(canSelectAlly){
                                        //     if(!cannotCast){
                                        //         castSelectedSpell(id);
                                        //     } else {
                                        //         nextCastSelectedSpell( id );
                                        //     }
                                        //  } else 
                                        // if(canSelectAllies){
                                        //     if(!cannotCast){
                                        //         castSelectedSpell('allies');
                                        //     } else {
                                        //         nextCastSelectedSpell('allies');
                                        //     }
                                        // } else if(isLobby) {
                                        
                                                if(selectedAlly == id){
                                                    setSelectedAlly(null);
                                                } else {
                                                    setSelectedAlly(id);
                                                }
                                            
                                        //}
                                    }}
                                    winningTeam={winningTeam}
                                    hasWon={winningPlayers[id]}
                                    hasSelection={ false}
                                    isSelected={selectedAlly == id || selectedEnemy == id}
                                    playerData={otherPlayers[id]}
                                    playAnimation={playAnimation}
                                    playReflectAnimation={playReflectAnimation}
                                    playAoeAnimation={playAoeAnimation}
                                    ally
                                    allCastable={canSelectAllies}
                                    //isSelectable={((!otherPlayers[selectedOtherPlayer] || !otherPlayers[selectedOtherPlayer]?.isAlly) && selectedSpellData && selectedSpellData.target == targets.selections.targetAlly)}
                                    totalDamage={gameTotals[results.totalDamage][id] || 0}
                                    totalHealing={gameTotals[results.totalHealing][id] || 0}
                                    clientIsCastingOn={castingData && (castingData.target == id || castingData.target == "allies")}
                                    willCastOn={nextCastTarget == id}
                                    time={time}
                                />
                            }
                        })
                    }
                </React.Fragment>
            </React.Fragment>
       </div>
    </div>
}

export default PlayerArea;