

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Puff Puff Smack",
    description: "Heal yourself equal to damage done",
    spellType: spellTypes.earth,
   // flair: "Ummmm... ouch.",
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 10,
    damage: 3,
    target: targets.selections.targetEnemy,
    image: "slap",
    doubleState: "stun",
   // effectNumber: "/+3",
    effects: [
        // this adds a state to target where the next damage heals you for damage amount
        {
            action: actions.addConditionalConditionPromptEffect,
            actionParams: [
                targets.selectedPlayer,
                { // thing to be done when condition is met (add timer buff)
                    action: actions.gainHealth,
                    actionParams: [targets.triggeringPlayer, specialDamageAmounts.triggeringDamageDone],
                    image: "smackHeals"
                },
                [conditions.damageDone, null, targets.selectedPlayer],
                [conditions.damageDone, null, targets.selectedPlayer],
                true
            ]
        }, // and now we immediately do damage, triggering it
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 3, spellTypes.earth],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}