import React from 'react';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

let fontS = 'clamp(1.5rem, -0.875rem + 5.333vh, 2.9rem);';

const styles = {
    field: {
        width: '100%',
    },

    text: {
        fontSize: fontS,
    },

    inputLable: {
        display: 'none'
    },

    label: {
        width: '100%',
        fontSize: fontS,
        marginTop: '20px',
        marginBottom: '-12px'
    },

    redLabel: {
        color: '#e45b5b'
    },

    helperText: {
        fontSize: fontS,
    },

};


function TextFieldWrapper(props) {
    const { 
        classes,
        id,
        label,
        onChange,
        value,
        disabled,
        error = false,
        helperText
    } = props;

    const textClass =  classes.text;

    let labelClass =  classes.label;

    let helperClass = classes.helperText;

    if(error){
        labelClass = clsx(labelClass, classes.redLabel);
    }


    return <React.Fragment>
        <InputLabel 
            className={labelClass} 
            id={id}>
                {label}
        </InputLabel>
        <TextField
            error={error}
            className={classes.field}
            id={id}
            label={label}
            onChange={onChange}
            value={value}
            disabled={disabled}
            helperText={helperText}
            InputProps={{
                className: textClass
            }}
            InputLabelProps={{
                className: classes.inputLable
            }}
            FormHelperTextProps={{
                className: helperClass
            }}
        />
    </React.Fragment>

}


export default withStyles(styles)(TextFieldWrapper);