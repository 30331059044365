
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Exploding Rock?",
    description: "Do 3 extra damage to a random enemy.",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 20,
    damage: 2,
    target: targets.selections.targetEnemies,
    image: "grenade",
    flair: "",
    effects: [

        {
            action: actions.damage,
            actionParams: [targets.allEnemies, 2, spellTypes.earth],
        },

        {
            action: actions.damage,
            actionParams: [targets.randomAliveEnemy, 3, spellTypes.earth],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}