import React, { useEffect, useState } from 'react';
import './SpellDescription.scss';
import imageMap from 'images/index.js';

const targets = require("shared/cardConstants/targets");


function SpellDescription( props ) {

    const { 
        spellData,
        isExpanded = true,
        onClick = ()=>{},
        onClose = ()=>{},
        button,
        showClose = false,
        isItem = false
    } = props;

    let underlineRechargeClass = "green";
    if(spellData.cooldown >= 20){
        underlineRechargeClass = "red";
    } else if(spellData.cooldown >= 10){
        underlineRechargeClass = "yellow";
    }  

    return <div className={`spellDescription ${isExpanded?"open":""}`} onClick={()=> onClick()}>
        <div className={`spellName ${spellData.spellType}`}>
             <div className={`nametext`}>
                {spellData.name}
             </div>
             {!isExpanded && <div className={`spellmore`}>touch for details</div>}
        </div>
       
        {/* {!isExpanded && <div className="readMore"> see details </div>} */}
        {isExpanded && <div className="spellDescriptionText">

            { showClose && <div className="closeButton" onClick={()=>onClose()}>
                <span className="material-symbols-outlined">
                    close
                </span>
            </div> }
           {/* { <div className="targetImg"> <img height={"100%"} src={`./images/${spellData.target}.png`}/></div> } */}
            {/* {!!spellData.castTime && <div className="casttime"><b>{spellData.castTime} second cast</b></div>}  */}
            {!!spellData.cooldown && <div className={`castcooldown ${underlineRechargeClass}`}><b>{spellData.cooldown}s recharge</b></div>}
            {isItem && <div className={`castcooldown`}><b>One time cast</b></div>}
            {!!spellData.castTime && <div className="casttime"><b>{spellData.castTime}s cast</b></div>}
            {!!spellData.duration && !spellData.channeled && <div className="castduration"><b>{spellData.duration}s duration</b></div>}
            {!!spellData.channelTime && <div className={`castduration channel ${spellData.spellType}`}><b>{spellData.channelTime}s channel</b></div>}

            <div className={`descImage`}>
                <img src={imageMap[spellData.image]}/>
            </div>
            { !!spellData.damage && <div className={`cardSpellDamage ${spellData.spellType} ${(spellData.description=="" && !spellData.flair)?"only":""}`}>
                <b>{spellData.damage} {spellData.spellType} damage</b>

                {spellData.target == targets.selections.targetEnemies && <div className={`damageToAll`}>
                    to all enemies
                </div>}
            </div>}
            <div className={`cardActualDescription ${(!spellData.damage && !spellData.flair && spellData.description.length < 40)?"only":""}`}>{spellData.description}</div>
            {spellData.flair && <div className={`cardActualDescription flair ${spellData.description?'':'nopad'}`}>{spellData.flair}</div>}
            {button && button()}
        </div>}
    </div>

}

export default SpellDescription;
