import { useEffect, useState } from 'react';

const {StartingDeck} = require('shared/decks/providedDecks');
const {Hats, Robes} = require('shared/hats');

const storageValues = require('../storageValues.js');

const serverMessages = require("../../../shared/serverMessages");

const forceDefaultDeck = true;
const forceDefaultClothes = false;


export default function useProfile(ioConnection, playerID){

    const [myDeck, setDeck ] = useState(false);
    const [myHat, setHatWrapped ] = useState(Hats.purple);
    const [myRobe, setRobeWrapped ] = useState(Robes.purple);

    const setHat = (hat) => {
        setHatWrapped(hat);
        localStorage.setItem(storageValues.myHat, hat);
        ioConnection.emit(serverMessages.requests.changeHat, {playerID, hat});
    }

    const setRobe = (robe) => {
        setRobeWrapped(robe);
        localStorage.setItem(storageValues.myRobe, robe);
    }

    useEffect(()=>{
      
        let myDeck = localStorage.getItem(storageValues.playerDeck);
        let myHat = localStorage.getItem(storageValues.myHat);
        let myRobe = localStorage.getItem(storageValues.myRobe);

        if(forceDefaultDeck || !myDeck || myDeck === "undefined" || myDeck == "[object Object]" || myDeck == "false" || myDeck == "true" || myDeck == "null"){
            myDeck = StartingDeck();
        } else {
            myDeck = JSON.parse(myDeck);
        }
        setDeck(myDeck);

        if(forceDefaultClothes || !myHat || myHat === "undefined" || myHat == "[object Object]" || myHat == "false" || myHat == "true" || myHat == "null"){
            myHat = Hats.green;
        }
        setHat(myHat);

        if(forceDefaultClothes || !myRobe || myRobe === "undefined" || myRobe == "[object Object]" || myRobe == "false" || myRobe == "true" || myRobe == "null"){
            myRobe = Robes.green;
        }
        setRobe(myRobe);
        
        localStorage.setItem(storageValues.playerDeck, JSON.stringify(myDeck));
    }, [])

    return {
        myDeck, setDeck,
        myHat, setHat,
        myRobe, setRobe
    }
}