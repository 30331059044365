import React, {useState, useEffect} from 'react';

import PaginatedSelector from './components/PaginatedSelector.js';
import imageMap from '../../images/index.js'

const {Hats, Robes, HatsList, RobesMap} = require('shared/hats');

window.ProfileTabs = {
    Hats: 0,
    Robes: 1
}

export default function ProfilePage({myHat, myRobe, setHat, setRobe}){

    const [currentTab, setCurrentTab] = useState(ProfileTabs.Hats);

    return <div className="profilePage"> 
    
        <div className="menuSelectorArea">
            { //currentTab == ProfileTabs.Hats &&
                <PaginatedSelector
                    list={HatsList}
                    pageAmount={6}
                    Card={({item})=>{
                        let imageKey = Hats[item];
                        let scale =  "0.8";
                        if(imageKey.includes('basic')){
                            scale = "1.1";
                        }
                        return <div className={`selectionItem`} key={imageKey}
                            onClick={()=>{
                                if(soundsOn)Sounds.softclick.play();
                                setHat(imageKey)
                                setRobe(Robes[RobesMap[imageKey]]);
                            }}
                        >
                            <img className={`selectionItemImage ${imageKey}`} 
                                src={imageMap[imageKey]}
                                style={{scale}}
                            />
                        </div>
                    }}
                    
                />
            }
            {/*currentTab == ProfileTabs.Robes &&
                <PaginatedSelector
                    list={RobesList}
                    pageAmount={6}
                    Card={({item})=>{
                        let imageKey = Robes[item];
                        let scale =  "1 0.9";
                        let marginTop = "-8%";
                        return <div className={`selectionItem`} key={imageKey}
                            onClick={()=>{
                                if(soundsOn)Sounds.softclick.play();
                                setRobe(imageKey)
                            }}
                        >
                            <img className={`selectionItemImage ${imageKey}`} 
                                src={imageMap[imageKey]}
                                style={{scale, marginTop}}
                            />
                        </div>
                    }}
                  
                />*/
            }
        </div>

        {/* <div className="menuTabArea">
            <div className="menuTab">
                <div className={`menuTabItem left ${currentTab==ProfileTabs.Hats?'active':'' }`} 
                    onClick={()=>{
                        if(soundsOn)Sounds.softclick.play();
                        setCurrentTab(ProfileTabs.Hats)
                    }}
                >
                    Hats
                </div> 
                <div className={`menuTabItem right ${currentTab==ProfileTabs.Robes?'active':'' }`}
                    onClick={()=>{
                        if(soundsOn)Sounds.softclick.play();
                        setCurrentTab(ProfileTabs.Robes)
                    }}
                >
                    Robes
                </div> 
            </div>  
        </div> */}

    </div>

}