
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Unstoppable Fists",
    description: "Cannot be stunned.  +1 Earth damage.",
  //  flair: "sobriety looks good on you.",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 1,
    cooldown: 12,
    damage: 0,
    duration: 9,
    noQueue: true,
    target: targets.selections.targetSelf,
    image: "fist",
    alwaysCast: true,
    effects: [
        // {
        //     action: actions.removeTimer,
        //     actionParams: [targets.selectedPlayer, "silence"]
        // },
        {
            action: actions.addTimerState,
            actionParams: [
                targets.me, 
                stateBuffs.unstunnable, 
            9,
            ],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, 
                {
                    buff: buffs.damageTypeBonus,
                    buffParams: [1, spellTypes.earth]
                }, 
                9 
            ]
        },
    ],
    xpGain: 0,
    manaCost: 1,
}