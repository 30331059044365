
const requests = {
    joinParty: "joinParty",
    leaveParty: "leaveParty",

    createGame: "createGame",
    joinGame: "joinGame",
    joinOrCreateGame: "joinOrCreateGame",
    addAIPlayer: "addAIPlayer",
    changeName: "changeName",
    changeHat: "changeHat",

    // TODO only use ready to start rounds.
    // card choosing should all advance as it can.
    // game creator is able to start game whenever
    startGame: "startGame",
    chooseCard: "chooseCard",
    buyItem: "buyItem",
    buyGear: "buyGear",
    buyDraw3: "buyDraw3",
    buyRedraw: "buyRedraw",

    removeFromSpellQueue: "removeFromSpellQueue",
    removeFromCanWhenReady: "removeFromCanWhenReady",

    ready: "ready",
    cast: "cast",
    castList: "castList",
    clearSpellQueue: "clearSpellQueue",
    setAllyTarget: "setAllyTarget",
    setEnemyTarget: "setEnemyTarget",
    aiCast: "aiCast",
    leave: "leave",
    removePlayer: "removePlayer",

    changeTeams: "changeTeams",
    observe: "observe"
}

const responses = {
    partyJoined: "partyJoined",
    gameCreated: "gameCreated",
    gameLeft: "gameLeft",
    gameJoined: "gameJoined",
    currFrameData: "currFrameData",
    eventData: "eventData",
    myData: "myData",
    handData: "handData",
    otherPlayersData: "otherPlayersData",
    victory: "victory",
    error: "error",
    aiCountChanged: "aiCountChanged"
}


const errors = {

    "joinParty": {
        partyDoesntExist: "partyDoesntExist"
    },
    "createGame": {
        gameNameTaken: "gameNameTaken"
    },
    "joinGame": {
        gameDoesntExist: "gameDoesntExist",
        gameIsPrivate: "gameIsPrivate",
        gameIsFull: "gameIsFull"
    },
    "changeName": {
        gameDoesntExist: "gameDoesntExist",
        playerNameTaken: "nameTaken"
    },
    "changeHat": {
        gameDoesntExist: "gameDoesntExist",
    },
    "startGame": {
        gameDoesntExist: "gameDoesntExist",
        notCreator: "notCreator"
    },
    "chooseCard": {
        gameDoesntExist: "gameDoesntExist",
    },
    "ready": {
        gameDoesntExist: "gameDoesntExist",
    },
    "cast": {
        gameDoesntExist: "gameDoesntExist",
        playerDoesntExist: "playerDoesntExist",
        spellIndexNotFound: "spellIndexNotFound",
        gameHasVictor: "gameHasVictor",
        casterIsDead: "casterIsDead",
        roundNotStarted: "roundNotStarted",
        playerIsSilenced: "playerIsSilenced",
        playerIsStunned: "playerIsStunned",
        notEnoughMana: "notEnoughMana",
        notEnoughHealth: "notEnoughHealth",
        notRequiredLevel: "notRequiredLevel",
        playerAlreadyCasting: "playerAlreadyCasting",
        roundNotStarted: "roundNotStarted",
        spellOnCooldown: "spellOnCooldown"
    },
    "leave": {
        gameDoesntExist: "gameDoesntExist",
    },
    "removePlayer": {
        gameDoesntExist: "gameDoesntExist",
        notCreator: "notCreator"
    },
    "onDisconnect": {
        gameDoesntExist: "gameDoesntExist"
    }

    // TODO fix the error system for the in-round stuff
}

const errorMessages = {
    gameNameTaken: "Game name is taken",
    gameIsPrivate: "Game is private",
    gameDoesntExist: "Game not found",
    playerNameTaken: "Player name is taken",
    notCreator: "Only the host can do this",
    gameIsFull: "The game is full",
    roundNotStarted: "The round has not started",

    playerAlreadyCasting: "Aleady casting",
    playerDoesntExist: "Player does not exist",
    spellIndexNotFound: "Index not found",
    gameHasVictor: "The game has ended",
    casterIsDead: "Caster is dead",
    roundNotStarted: "Round not started",
    playerIsSilenced: "Silenced",
    playerIsStunned: "Stunned",
    notEnoughMana: "Not enough mana",
    notEnoughHealth: "Not enough health",
    notRequiredLevel: "Not high enough level",
    spellOnCooldown: "Spell on cooldown"
}

module.exports = {
    requests,
    responses, 
    responses,
    errors,
    errorMessages
}