

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Just Great Water",
    description: "Heal 20 health.",
    flair: "Always a good choice.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 2,
    level: 1,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "water_tall",
    item: true,
    cost: 5,
    effects: [
        {
            action: actions.gainHealth,
            actionParams: [targets.selectedPlayer, 20]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}
