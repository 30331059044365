

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Blizzard",
    description: "Enemy cast time slowed. Growing damage and ice weakness each second.",
    flair: "",
    spellType: spellTypes.ice,
    image: "blizzard",
    level: 2,
    //charges: 1,
    channeled: true,
    channelTime: 6,
    brokenByDamage: true,
    castTime: 2,
    cooldown: 30,
    duration: 6,
    target: targets.selections.targetSelf,
    playAnimationOnEnemies: true,
    effectImage: "frost",
    bonusState: "damageTypeWeakness/ice",
    effectNumber: "12/6",
    effects: [
        
        { // thing to be done when condition is met (add timer buff)
            action: actions.addTimerConditionState,
            actionParams: [
                targets.me, 
                stateBuffs.channeling,
                6, // maxtime 
                [conditions.damageDone, null, targets.me, null]
            ]
        },
        { // need : actions.addEffectToGroup
            action: actions.addConditionalPeriodicEffect,
            actionParams: [
                targets.me, 
                {
                    action: actions.damage,
                    actionParams: [1, spellTypes.ice],
                    image: "blizzard",
                    description: "hit by blizzard",
                    overrideTarget: targets.allEnemies,

                }, 
                2,  // deal damage every 2 seconds
                [conditions.damageDone, null, targets.me, null], 
                6 // maxtime
            ],
        },
        { // need : actions.addEffectToGroup
            action: actions.addConditionalPeriodicEffect,
            actionParams: [
                targets.me, 
                {
                    action: actions.addTimerBuff,
                    overrideTarget: targets.allEnemies,
                    actionParams: [
                        {
                            buff: debuffs.damageTypeWeakness, 
                            buffParams: [1, spellTypes.ice]
                        }, 10
                    ],
                    image: "blizzard2",
                    description: "hit by blizzard",
                    overrideTargetCastEffect: true,
                }, 
                1, //  every 2 second
                [conditions.damageDone, null, targets.me, null], 
                6 // maxtime
            ],
        },
        { // need : actions.addEffectToGroup
            action: actions.addConditionalPeriodicEffect,
            actionParams: [
                targets.me, 
                {
                    action: actions.addTimerBuff,
                    overrideTarget: targets.allEnemies,
                    actionParams: [
                        {
                            buff: buffs.slowerCastTime, 
                            buffParams: [1, spellTypes.ice]
                        }, 3
                    ],
                    image: "blizzard3",
                    description: "hit by blizzard",
                }, 
                1, 
                [conditions.damageDone, null, targets.me, null],
                6 // maxtime
            ],
        },

        // Turn below into slower cast time

        // { // thing to be done when condition is met (add timer buff)
        //         action: actions.addConditionalBuff,
        //         actionParams: [
        //             targets.me,
        //             {
        //                 buff: buffs.damageTypeBonus,
        //                 buffParams: [2, spellTypes.fire]
        //             }, 
        //             [conditions.damageDone, targets.me, null, spellTypes.fire]
        //         ]
        // },





        // {
        //     action: actions.damage,
        //     actionParams: [targets.allEnemies, 1, spellTypes.ice], 
        // },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.allEnemies, {
        //             buff: debuffs.damageTypeWeakness, 
        //             buffParams: [3, spellTypes.ice]
        //         }, 8
        //     ],
        // },
        
    ],
    xpGain: 0,
    manaCost: 1,
}