
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Hot Toddy",
    description: "Reduce ice damage by 4.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    damage: 0,
    level: 1,
    duration: 20,
    target: targets.selections.targetSelf,
    image: "hot_toddy",
    item: true,
    cost: 6,
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.me, {
                    buff: buffs.damageTypeResistance, 
                    buffParams: [4, spellTypes.ice]
                }, 20
            ],
        },
    ],
    xpGain: 0,
    manaCost: 1,
}