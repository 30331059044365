import React, { useState, useEffect, useReducer, useRef, cloneElement, useCallback } from 'react';
import './GamePage.scss';

import SpellList from './components/SpellList/SpellList'
import PlayerArea from './components/PlayerArea/PlayerArea'
import LobbyButtons from './components/LobbyButtons'
import ResourceBar from './components/ResourceBar'
import SettingsPage from './SettingsPage'
import CardSelectionPage from './cardSelectionPage/CardSelectionPage'
import VictoryPage from './VictoryPage'
import CardDescription from './cardSelectionPage/components/CardDescription'
import settingsIcon from '../../icons/settings.svg'
import { damageTypeBonus } from '../../../shared/cardConstants/buffs';
import { me } from '../../../shared/cardConstants/targets';
import BigButton from '../menu/components/BigButton';

import imageMap from 'images/index.js';
let targets = require('shared/cardConstants/targets');

const serverMessages = require("shared/serverMessages");
const roundTypes = require("shared/roundConstants/roundTypes");
import { useNavigate } from "react-router-dom";
const storageValues = require("../storageValues");
const singlePlayer = require("shared/singlePlayer.js");
const { Items } = require("shared/decks/providedDecks.js");
const AllSpells = require("shared/decks/available.js");
 

function GamePage(props) {

    const { playerID, gameID, partyID, ioConnection, isDisconnected, setGameID, playerName, currentLevel, setCurrentLevel } = props;

    const autoCastingOn = true;

    const [currFrameData, setCurrFrameData] = useState({});

    const [myData, setMyData] = useState({});

    const [handData, setHandData] = useState([]);

    const [castData, setCastData] = useState([]);

    const [castBarStart, setCastBarStart] = useState(0);
    const [castBarTimeToFill, setCastBarTimeToFill] = useState(5);

    const [channelBars, setChannelBars] = useState([]);

    const [gameError, setGameError] = useState();
    // casting
    // castSpellIndex
    // castTime

    const [otherPlayersData, setOtherPlayersData] = useState({});

    const [showHandDelayPassed, setShowHandDelayPassed] = useState(false);
    const [showHandDelayStarted, setShowHandDelayStarted] = useState(false);

    const [selectedAlly, setSelectedAllyWrapped] = useState(null);
    const [selectedEnemy, setSelectedEnemyWrapped] = useState(null);

    const setSelectedAlly = (target) => {
        ioConnection.emit(serverMessages.requests.setAllyTarget, {
            target: target
        });
        setSelectedAllyWrapped(target);
    }
    const setSelectedEnemy = (target) => {
        ioConnection.emit(serverMessages.requests.setEnemyTarget, {
            target: target
        });
        setSelectedEnemyWrapped(target);
    }

    const [selectedSpellIndex, setSelectedSpellIndex] = useState(-1);


    const [error, setError] = useState();

    const [showingSettings, setShowingSettings] = useState(false);

    const [winningTeam, setWinningTeam] = useState(-1);
    const [winningPlayers, setWinningPlayers] = useState({});
    const [history, setHistory] = useState();
    const [gameTotals, setGameTotals] = useState();

    const [spellDescriptionExpanded, setSpellDescriptionExpanded] = useState(false);

    const [choosingHidden, setChoosingHidden] = useState(false);

    const [name, setName] = useState();

    const [twoClickCast, setTwoClickCast] = useState(false);

    const [spellQueue, setSpellQueue] = useReducer((state, data) => {
        switch (data.type) {
            case "add": {
                let newState = [...state];
                if (!newState.includes(data.index)) newState.unshift(data.index);
                return newState;
            }
            case "remove": {
                let newState = [...state];
                newState.splice(newState.indexOf(data.index), 1);
                return newState;
            }
            case "clear": {
                return [];
            }
        }
    }, [])

    const setSpellsCasted = (data) => {
        setSpellsCastedWrapped(data);
        setSpellQueue(data);
        // if we are adding to queue, remove it from castWhenReady
        if (data.type == "add") {
            setSpellsCastingWhenReady({ type: "remove", index: data.index });
        }
    }


    const [spellsCasted, setSpellsCastedWrapped] = useReducer((state, data) => {
        switch (data.type) {
            case "add": {
                let newState = { ...state };
                newState[data.index] = true;
                return newState;
            }
            case "remove": {
                let newState = { ...state };
                newState[data.index] = false;
                return newState;
            }
            case "clear": {
                return {};
            }
        }
    }, {})

    const [spellsCastingWhenReady, setSpellsCastingWhenReady] = useReducer((state, data) => {
        switch (data.type) {
            case "add": {
                let newState = [...state];
                newState.unshift(data.index);
                return newState;
            }
            case "remove": {
                if (state.indexOf(data.index) == -1) return [...state];
                let newState = [...state];
                newState.splice(newState.indexOf(data.index), 1);
                return newState;
            }
            case "clear": {
                return [];
            }
        }
    }, [])

    const [currEventData, setCurrEventData] = useState({});

    const [soundsOnState, setSoundsOnState] = useState(window.soundsOn);

    useEffect(() => {
        if (winningPlayers[myData?.id]) {
            if (soundsOn) Sounds.trumpet.play();
            setTimeout(() => { if (soundsOn) Sounds.cheering.play() }, 3)
        }
    }, [myData?.id, Object.keys(winningPlayers).length]);

    let channelsStr = "";
    if(myData && myData.channels){
        Object.keys( myData.channels).map(spellName => {
            channelsStr += spellName;
            channelsStr += myData.channels[spellName].count;
        })
    }

    useEffect(()=>{
        if(!myData || !myData.channels || !currFrameData) return;

        // if we are not fighting, we need to stop the movement of the channel bars
        let newChannelObj = {...myData.channels};
        let changed = false;
        if(currFrameData.gameState?.roundType != roundTypes.fighting || currFrameData.countdown != 0){
            Object.keys(newChannelObj).map(spellName => {
                if(!newChannelObj[spellName].frozen){
                    newChannelObj[spellName].frozen = true;
                    changed = true;
                }
            })
        } else {
            changed = true;
        }
        if(changed){
            setChannelBars(newChannelObj);
        }
    }, [channelsStr, currFrameData?.gameState?.roundType, currFrameData?.countdown]);

    useEffect(()=>{
        if(!myData) return;
        setCastBarStart(myData.percentCasted);
        setCastBarTimeToFill(myData.castingTimeLeft);
       // console.log(myData.castingTimeLeft);

    }, [myData?.castingIndex]);

    useEffect(()=>{
        if(!myData) return;

        setCastBarStart(myData.percentCasted);
       
        setCastBarTimeToFill(myData.castingTimeLeft);

    }, [myData?.castModifier]);

    // useEffect(()=>{
    //     if(!myData || !myData.casting) return;
    //     console.log("casting changed");
   
    //     // determine (locally) what percent through we are
    //     let diff = new Date() - castBarStartTime;
    //     let fraction = (diff / 1000) / castBarTimeToFill;
    //      setCastBarStart(fraction * 100);
    //      console.log("bar start: ", fraction);

    //      setCastBarTimeToFill(myData.timeLeft);
    //      console.log("time Left: ", myData.timeLeft);

    // }, [myData?.fractionCasted, myData?.timeLeft, castBarTimeToFill])

    useEffect(() => {
        let countdown = currFrameData?.countdown;
        if (countdown == 3) {
            if (soundsOn) Sounds.three.play();
        } else if (countdown == 2) {
            if (soundsOn) Sounds.two.play();
        } else if (countdown == 1) {
            if (soundsOn) Sounds.one.play();
        }
    }, [currFrameData?.countdown]);

    const castHideTimer = useRef();
    const beginCastEffect = (spellData, index) => {
        let castTime = spellData.castTime;
        // todo need to subtract out cast time, if we use this
        if (myData.fasterCastTime > 0) {
            castTime -= myData.fasterCastTime;
            if (castTime < 0) castTime = 0;
        }

        let realCastTime = 0;
        if (castTime > 0) realCastTime = castTime * 1000 + 200;
        if (castTime == 0) {
            realCastTime = 700;
        }
        // this is for the network part
        realCastTime += 300;

        setCastData({ casting: true, castTime: realCastTime / 1000, castSpellIndex: index })
        if (castHideTimer.current) clearInterval(castHideTimer.current);
        castHideTimer.current = setTimeout(() => setCastData({}), realCastTime);
    }

    if (myData && myData.id && currEventData[myData.id] && currEventData[myData.id].queueCleared && spellQueue.length != 0) {
        //console.log("clearing queue");
        setSpellsCasted({ type: "clear" });
        setSpellsCastingWhenReady({ type: "clear" });
    }
    if (myData && myData.id && myData.casting && myData.castingIndex && spellsCasted[myData.castingIndex]) {
        // I'm not sure now why this is here, and not sure if it should be...
        //if(spellQueue.length > 1)
        setSpellsCasted({ type: "remove", index: myData.castingIndex });

    }

    // TODO next
    // remove from casted list when the card is onCooldown.
    // ( setSpellsCasted({type: 'remove', index}) - pass down to spells?)

    const [cardBoundingBoxes, setCardBoundingBoxes] = useReducer((state, data) => {
        let newState = [...state];
        newState[data.index] = data.box;
        return newState;
    }, [])

    let checkIfSpellTouched = (x, y) => {
        let index = -1;
        cardBoundingBoxes.map((box, i) => {
            if (x > box.x && x < box.x + box.width
                && y > box.y && y < box.y + box.height) {
                index = i
            }
        });
        return index;
    }


    useEffect(() => {

        ioConnection.on("interval", (data) => {
            let hd = data[serverMessages.responses.handData];
            let md = data[serverMessages.responses.myData];
            let opd = data[serverMessages.responses.otherPlayersData];
            let cfd = data[serverMessages.responses.currFrameData];
            let ed = data[serverMessages.responses.eventData];
            let err = data.error;
            if (err) setGameError(err);

            // TODO only set when not fighting
            if (hd) setHandData(hd);

            if (md) setMyData(md);
            if (opd) setOtherPlayersData(opd);
            if (cfd) {
                setCurrFrameData(cfd);
                if (cfd.winningTeam != -1) {
                    setWinningTeam(cfd.winningTeam);
                    let winningPlayerIds = {};
                    cfd.winningPlayers.map(playerData => {
                        winningPlayerIds[playerData.id] = true;
                    })
                    setWinningPlayers(winningPlayerIds);
                }
                if (cfd.gameTotals) {
                    setGameTotals(cfd.gameTotals);
                }
            }
            setCurrEventData(ed);
        });

        // ioConnection.on(serverMessages.responses.eventData, (data)=>{
        //     setCurrEventData(data);
        // });

        // ioConnection.on(serverMessages.responses.handData, (data)=>{
        //     setHandData(data);
        // });

        // ioConnection.on(serverMessages.responses.myData, (data)=>{
        //     setMyData(data);
        // });

        // ioConnection.on(serverMessages.responses.otherPlayersData, (data)=>{
        //     setOtherPlayersData(data);
        // });

        // ioConnection.on(serverMessages.responses.currFrameData, (data)=>{
        //     //console.log(data);
        //     setCurrFrameData(data);

        //     // if(window.parent?.parent){
        //     //     libAI_sendData(data);
        //     // }
        //     // set this from game data incase someone re-joins right at the
        //     // victory screen.  
        //     if(data.winningTeam != -1){
        //         setWinningTeam(data.winningTeam);
        //         let winningPlayerIds = {};
        //         data.winningPlayers.map(playerData => {
        //             winningPlayerIds[playerData.id] = true;
        //         })
        //         setWinningPlayers(winningPlayerIds);
        //     }
        //     if(data.gameTotals){
        //         setGameTotals(data.gameTotals);
        //     }
        // });

        ioConnection.on(serverMessages.responses.error, (message) => {
            if (serverMessages.errors.cast[message]) {
                setError(null)
                setTimeout(() => setError(message), 100);
            }
        });

        ioConnection.on(serverMessages.responses.victory, function ({ winningTeam, winningPlayers, history, gameTotals }) {

            setHistory(history);
            setWinningTeam(winningTeam);
            let winningPlayerIds = {};
            winningPlayers.map(playerData => {
                winningPlayerIds[playerData.id] = true;
            })
            setWinningPlayers(winningPlayerIds);
        });


        // This is only used for AI engine.  Listen for AI messages
        // and send actions
        window.addEventListener("message",
            function receiveMessage(message) {

                //console.log(message.data);
                let players = message.data.players;
                let frame = message.data.frame;
                players && players.map(info => {
                    let id = info.playerObject.player_id;
                    let actionObject = info.action[Object.keys(info.action)[0]];
                    if (actionObject) {
                        // find the target object in the frame to get the target id
                        let targetId = frame[actionObject._target].player_id;
                        let index = frame[actionObject._id].spell_index;
                        if (info.playerObject.player_canCast == 1) {
                            libAI_sendAction(id, index, targetId);
                        }
                    }
                })
            }
            , false);

        const libAI_sendAction = (playerId, spellIndex, targetId) => {
            if (!sendingMessage) {
                sendingMessage = true;
                //console.log(playerId, spellIndex, targetId);
                ioConnection.emit(serverMessages.requests.aiCast, {
                    id: playerId,
                    index: spellIndex,
                    target: targetId,
                    time: new Date()
                });
                setTimeout(() => sendingMessage = false, 1000);
            }
        }
    }, []);

    //const [nextCastSpellIndex, setNextCastSpellIndex] = useState(-1);
    //const [nextCastTarget, setNextCastTarget] = useState(null);

    // const nextCastSelectedSpell = (target) => {
    //     // pre validated
    //     setNextCastSpellIndex(selectedSpellIndex);
    //     setNextCastTarget(target);
    //     setSelectedSpellIndex(-1);
    // }

    // 

    //console.log("rendergamepage");



    const castSelectedSpell = (target) => {
        castSpell(selectedSpellIndex, target);
    }

    const cancelSpellQueue = () => {
        setSpellsCasted({ type: "clear" });
        setSpellsCastingWhenReady({ type: "clear" });
        ioConnection.emit(serverMessages.requests.clearSpellQueue, {});
    }

    const castSpell = (index, target, isClick = true) => {
        let spellData = handData[index];
        if (index >= handData.length) {
            spellData = myData.items[index % handData.length];
        }
        let owner = myData;
        let countdown = currFrameData.countdown;
        const onCooldown = spellData.onCooldown;
        // is stunned comes from server, it has the logic to check unstunnable etc..
        // also includes channeling / silenced
        const stunned = owner.isStunned && !spellData.alwaysCast;

        // if already in queue or casting when ready, 
        // then remove
        if(isClick){
            if(spellQueue.includes(index)){
                ioConnection.emit(serverMessages.requests.removeFromSpellQueue, {
                    index: index
                });
                setSpellQueue({ type: 'remove', index });
                setSpellsCasted({ type: 'remove', index });
                return;
            }
            else if(spellsCastingWhenReady.includes(index)){
                ioConnection.emit(serverMessages.requests.removeFromCanWhenReady, {
                    index: index
                });
                setSpellsCastingWhenReady({ type: 'remove', index });
                return;
            }
        }
        
        
        if (countdown != 0 || onCooldown || stunned) {
            //if(spellsCastingWhenReady.includes(index) && isClick){
            //    setSpellsCastingWhenReady({type: 'remove', index});
            //} else {
            setSpellsCastingWhenReady({ type: 'add', index });
            //}

            ioConnection.emit(serverMessages.requests.cast, {
                index: index,
                target: target,
                time: new Date()
            });
        }
        else
            if (!spellsCasted[index]) {
                setSpellsCasted({ type: 'add', index });
                setSpellDescriptionExpanded(false);

                //let message = !isNext? serverMessages.requests.cast : serverMessages.requests.castNext

                ioConnection.emit(serverMessages.requests.cast, {
                    index: index,
                    target: target,
                    time: new Date()
                });

                if (spellQueue.length == 0 && !castData.casting) {
                    //beginCastEffect(spellData, index);
                    // let castTime = spellData.castTime;
                    // if( myData.fasterCastTime > 0){
                    //     castTime -= myData.fasterCastTime;
                    //     if(castTime < 0) castTime = 0;
                    //   }

                    // let realCastTime = 0;
                    // if(castTime > 0) realCastTime = castTime*1000 + 200;
                    // if( castTime == 0){
                    //   realCastTime = 700;
                    // }
                    // // this is for the network part
                    // realCastTime += 300;

                    // setCastData({casting: true, castTime: realCastTime / 1000, castSpellIndex: index})
                    // setTimeout(()=>setCastData({}), realCastTime);
                }

                // setTimeout(()=> setCastingData((state)=> ({...state, canFlip: true}) ), 550);

                // if(currFrameData.countdown <= 0){
                //     if(handData[index].charges <= 1){
                //         setSelectedSpellIndex(-1);
                //     }
                //     setCastingData({target, index});
                // }
            }
    }

    if (me && me.name && me.name != name) {
        setName(me.name);
    }

    const isFighting = currFrameData.gameState?.roundType == roundTypes.fighting || currFrameData.gameState?.canFight;
    const isLobby = currFrameData.gameState && currFrameData.gameState.roundType == roundTypes.lobby;


    if (handData && myData && currFrameData) {

        if (currFrameData?.gameState?.roundType == roundTypes.choosingToAdd && spellQueue.length > 0) {
            cancelSpellQueue();
        } else {
            let spellsToCast = [];
            // this bit needs to exist on the client and the server
            // (where stuff gets moved from one list to the other)
            // But these changes should not be synced!
            spellsCastingWhenReady.slice(0).reverse().map((index) => {
                let owner = myData;
                let spell = handData[index];
                if (spell) {

                  //  const stunned = (owner.silence && !owner.unstunnable && !spell.alwaysCast) || (owner.stun && !owner.unstunnable && !spell.alwaysCast);
                    const stunned = owner.isStunned && !spell.alwaysCast;
                    const onCooldown = spell.onCooldown;
                    let countdown = currFrameData.countdown;

                    if (countdown == 0 && !onCooldown && !stunned) {
                        // beginCastEffect(spell, index);
                        setSpellsCastingWhenReady({ type: "remove", index });
                        setSpellsCasted({ type: 'add', index });
                        spellsToCast.push(index);

                    }
                }
            })

            // if(spellsToCast.length > 0){
            //     ioConnection.emit(serverMessages.requests.castList, { 
            //         indexes: spellsToCast, 
            //         time: new Date()
            //     });
            // }
        }
    }


    // if(currFrameData && myData && (nextCastSpellIndex != -1 || selectedSpellIndex != -1)){
    //     let canCastNextSpell = true;

    //     if(castingData){
    //         canCastNextSpell = false;
    //     }

    //     let nextCastSpellData;
    //     if(nextCastSpellIndex != -1){
    //         nextCastSpellData = handData[nextCastSpellIndex];
    //     } else {
    //         nextCastSpellData = handData[selectedSpellIndex];
    //     }



    //     if(nextCastSpellData){

    //         if(nextCastSpellData.onCooldown){
    //             canCastNextSpell = false;
    //         }

    //         if((myData.stun || myData.silence) && !myData.unstunnable && !nextCastSpellData.alwaysCast){
    //             canCastNextSpell = false;
    //         }

    //         if(currFrameData.countdown > 0){
    //             canCastNextSpell = false;
    //         }

    //         if(!isFighting && !isLobby){
    //             if(nextCastSpellIndex != -1) setNextCastSpellIndex(-1);
    //             if(nextCastTarget) setNextCastTarget(null);
    //             canCastNextSpell = false;
    //         }

    //         let targetIndex = nextCastTarget || selectedOtherPlayer;
    //         // double check that I can cast on target, if not set target to -1
    //         let selectedPlayer = selectedOtherPlayer?otherPlayersData[selectedOtherPlayer] || myData: null
    //         if(selectedPlayer && selectedPlayer.isEnemy && nextCastSpellData.target != targets.selections.targetEnemy){
    //             targetIndex = -1;
    //         }

    //         if(selectedPlayer && selectedPlayer.isAlly && nextCastSpellData.target != targets.selections.targetAlly){
    //             targetIndex = -1;
    //         }

    //         if(spellsCasted[nextCastSpellIndex]) canCastNextSpell = false;

    //         if(canCastNextSpell){
    //                 castSpell(nextCastSpellIndex != -1?nextCastSpellIndex:selectedSpellIndex, targetIndex);
    //                 setNextCastSpellIndex(-1);
    //                 setNextCastTarget(null);
    //         }
    //     }
    // }

    // if(currFrameData && myData && !myData.casting && !myData.warmingUp && castingData && castingData.canFlip){
    //     setCastingData(null)
    // }



    if (currFrameData?.gameState?.roundType != roundTypes.choosingToAdd && showHandDelayPassed) {
        setShowHandDelayPassed(false);
    }


    if (currFrameData?.gameState?.roundType == roundTypes.choosingToAdd && !choosingHidden && currFrameData?.goingToWinTeam == -1 && showHandDelayStarted == false && showHandDelayPassed == false) {
        setShowHandDelayStarted(true);
        setTimeout(() => {
            setShowHandDelayPassed(true);
            setShowHandDelayStarted(false);
        }
            , 2000);
    }


    //////////////////////////
    //// VICTORY PAGE  ///////
    //////////////////////////

    // if(winningTeam != -1){
    //     return <VictoryPage
    //         winningTeam={winningTeam}
    //         winningPlayers={winningPlayers}
    //         history={history}
    //         gameTotals={gameTotals}
    //         ioConnection={ioConnection}
    //         gameName={gameID}
    //         playerID={playerID}
    //         allPlayers={{...otherPlayersData, [myData.id]: myData}}
    //         myName={name || playerName}
    //         setGameID={setGameID}
    //         currentLevel={currentLevel}
    //         setCurrentLevel={setCurrentLevel}
    //     />;
    // } 

    let navigate = useNavigate();
    const [lastOptions, setLastOptions] = useState();
    const [lastDeck, setLastDeck] = useState();
    let myName = name || playerName;

    useEffect(() => {

        let _lastOptions = localStorage.getItem(storageValues.gameOptions);
        let _lastDeck = localStorage.getItem(storageValues.playerDeck);
        if (_lastOptions && _lastOptions != "undefined") {
            _lastOptions = JSON.parse(_lastOptions);
            setLastOptions(_lastOptions);
            // if(_lastOptions.playersLocked){
            //     console.log(winningTeam);
            //     console.log(winningPlayers);
            //     if(winningTeam == 0){
            //         setCurrentLevel(parseInt(currentLevel) + 1);
            //     }
            // }
        }
        if (_lastDeck && _lastDeck != "undefined") {
            _lastDeck = JSON.parse(_lastDeck);
            setLastDeck(_lastDeck);
        }
    }, [winningTeam]);


    //////////////////////////
    //// CHOOSING PAGE ///////
    //////////////////////////

    if (currFrameData?.gameState?.roundType == roundTypes.choosingToAdd && !choosingHidden && currFrameData?.goingToWinTeam == -1 && showHandDelayPassed) {
        return <div className={`GamePage`}>
            {showingSettings &&
                <SettingsPage
                    me={myData}
                    ioConnection={ioConnection}
                    hideSettings={() => setShowingSettings(false)}
                    gameID={gameID}
                    name={name}
                    setName={setName}
                />
            }
            <CardSelectionPage
                me={myData}
                otherPlayers={otherPlayersData}
                spells={handData}
                ioConnection={ioConnection}
                gameState={currFrameData.gameState}
            />
            {!showingSettings &&
                <div className="footerWrapper">
                    <div className="footer">

                        <div className="settingsButton flipView" onClick={() => setChoosingHidden(true)}>
                            <span className="material-symbols-outlined">
                                keyboard_arrow_up
                            </span>
                        </div>
                        <div className="settingsButton" onClick={() => setShowingSettings(true)}>
                            <span className="material-symbols-outlined">
                                exit_to_app
                            </span>
                        </div>
                        {soundsOnState && <div className="settingsButton" onClick={() => {
                            setSoundsOnState(false);
                            window.soundsOn = false;
                        }}>
                            <span className="material-symbols-outlined">
                                volume_up
                            </span>
                        </div>
                        }
                        {!soundsOnState && <div className="settingsButton" onClick={() => {
                            checkSounds();
                            setSoundsOnState(true);
                            window.soundsOn = true;
                            window.Sounds.softclick.play();
                        }}>
                            <span className="material-symbols-outlined">
                                volume_off
                            </span>
                        </div>
                        }
                    </div>
                </div>
            }
        </div>
    }



    //////////////////////////
    //// GAME PAGE     ///////
    //////////////////////////
    //console.log(myData.castSpellIndex)

    let card = handData[myData.castSpellIndex];
    if (!card && myData.castSpellIndex >= handData.length) {
        card = myData.items[myData.castSpellIndex % handData.length];
    }
    let isInturrupted = myData && ((showHandDelayStarted && !showHandDelayPassed) || (myData.isStunned && !card?.alwaysCast));

    // just an easy way to prevent crash on context switch to choose page
    let castingSpellData = {
        item: false,
        spellType: "none",
        image: false
    };
    if (myData && handData) {
        if (myData.castSpellIndex >= handData.length && myData.items[myData.castSpellIndex % handData.length]) {
            castingSpellData = myData.items[myData.castSpellIndex % handData.length];
        } else if (handData[myData.castSpellIndex]) {
            castingSpellData = handData[myData.castSpellIndex];
        }
    }

    // if there is no winner yet...
    return <div className={`GamePage`} onTouchMove={(e) => {
        let changedTouches = e.changedTouches;
        let x = changedTouches[0].clientX;
        let y = changedTouches[0].clientY;
        let index = checkIfSpellTouched(x, y);
        if (index != -1) castSpell(index, null, false);
    }}>
        {showingSettings &&
            <SettingsPage
                me={myData}
                ioConnection={ioConnection}
                hideSettings={() => setShowingSettings(false)}
                gameID={gameID}
                name={name}
                setName={setName}
            />
        }

        {gameError &&
            <div className="errorOverlay">
                <div className="errorMessageArea">

                    <div className="errorMessageTitle">

                        <div className="errorIcon">
                            <span className="material-symbols-outlined" style={{ color: "white" }}>
                                error
                            </span>
                        </div>

                        <div>{gameError.message}</div>

                        <div className="errorStack">{gameError.stack}</div>
                    </div>
                </div>
            </div>
        }

        <div className={`PlayerAreaWrapper ${!myData || !myData.id?'fullscreen':''}`}>
            <PlayerArea
                fadeOut={showHandDelayStarted && !showHandDelayPassed}
                gameType={currFrameData.gameType}
                me={myData}
                myCastingSpellData={castingSpellData}
                time={currFrameData.time}
                ioConnection={ioConnection}
                otherPlayers={otherPlayersData}
                enemies={currFrameData.enemies}
                currEventData={currEventData}
                allies={currFrameData.allies}
                selectedAlly={selectedAlly}
                selectedEnemy={selectedEnemy}
                setSelectedAlly={setSelectedAlly}
                setSelectedEnemy={setSelectedEnemy}
                winningTeam={winningTeam}
                winningPlayers={winningPlayers}
                gameTotals={gameTotals}
                isFighting={isFighting}
                countdown={currFrameData.countdown}
                selectedSpellData={(selectedSpellIndex != -1 && myData) ? handData[selectedSpellIndex] : null}
                isLobby={currFrameData.gameState && currFrameData.gameState.roundType == roundTypes.lobby}
                castSelectedSpell={castSelectedSpell}
            >
                {
                    //error && 
                    //    <div className="errorMessage">{serverMessages.errorMessages[error]}</div>
                }
                {!!currFrameData.countdown &&
                    <div className="countdown">{Math.ceil(currFrameData.countdown)}</div>
                }
                {
                    !!currFrameData.timeLeft && currFrameData.timeLeft < 10 && currFrameData?.goingToWinTeam == -1 &&
                    <div className='countdownBar'>
                        <ResourceBar
                            total={1}
                            current={0}
                            previousCurrent={1}
                            timeToFillBar={10}
                            className="countdownBarClass"
                        />
                    </div>
                    // <div className="countdown">{currFrameData.timeLeft || 5}</div>
                }
                {currFrameData.gameState && currFrameData.gameState.roundType == roundTypes.fighting && currFrameData.countdown == 0 &&
                    <div className="countdown go">FIGHT!</div>
                }
            </PlayerArea>
        </div>

        {
            myData && currFrameData?.goingToWinTeam == -1 &&
            <div className={`castingAreaWrapper`}>
                <div className={`castingArea`}>
                    {myData && currFrameData?.goingToWinTeam == -1 &&
                        <div className={`castBarArea`}>
                            {
                                
                            }
                            {/* <div className={`spellCastImageArea ${castingSpellData.item?"item":castingSpellData.spellType}`}>
                                <div className="spellCastImage">
                                    {castingSpellData.image && <img src={imageMap[castingSpellData.image]}/>}
                                </div>
                            </div> */}
                            { channelBars && Object.keys(channelBars).length > 0 &&
                                <div className="channelBarArea">
                                    {Object.keys(channelBars).map(key => {
                                        let channelObj = channelBars[key];
                                        let fillPercent = (channelObj.timeTotal - channelObj.timeLeft ) / channelObj.timeTotal;
                                        let timeToFill = channelObj.timeLeft;
                                        let frozen = channelObj.frozen;
                                        return <div className={`myCastBar channeling`}>
                                            <ResourceBar
                                                key={key}
                                                total={1}
                                                current={1}
                                                previousCurrent={0}
                                                preFillPercent={(fillPercent * 100) + 1} // for some reason this cant be 0
                                                timeToFillBar={(frozen)?-1:timeToFill || 0.1 }
                                                className={`casting ${channelObj.spellType}`}
                                                inverted
                                            />
                                        </div>

                                    })}
                                </div>
                            }

                            { !myData.dead && !myData.frozen && myData.stun && !myData.unstunnable && !myData.casting &&
                                <div className={`castLabelArea`}>
                                    <div className="castLabel stunned">Stunned!</div>
                                </div>
                            }

                            { !myData.dead && myData.frozen && !myData.casting &&
                                <div className={`castLabelArea`}>
                                    <div className="castLabel frozen">Frozen!</div>
                                </div>
                            }

                            { myData.dead && !myData.casting &&
                                <div className={`castLabelArea`}>
                                    <div className="castLabel dead">Too ded.</div>
                                </div>
                            }

                            { myData.casting && 
                                <div className={`myCastBar ${isInturrupted ? 'inturrupted' : ''}`}>
                                <ResourceBar
                                    key={myData.castingIndex}
                                    total={1}
                                    current={0}
                                    previousCurrent={1}
                                    preFillPercent={castBarStart}
                                    timeToFillBar={isInturrupted ? 999 : castBarTimeToFill || 0.1 }
                                    className={isInturrupted ? "inturrupted" : `casting ${castingSpellData.item ? "item" : castingSpellData.spellType}`}
                                />
                            </div>}
                        </div>}
                </div>
            </div>
        }

        {
            // <div className={`castingAreaWrapper`}>
            // <div className={`castingArea`}>
            //     <div className={`castBarArea`}>
            //         {/* <div className={`spellCastImageArea ${"earth"}`}>
            //             <div className="spellCastImage">
            //                 <img src={imageMap["stick"]}/>
            //             </div>
            //         </div> */}
            //          <div className="channelBarArea">
                                   
            //                             <div className={`myCastBar channeling`}>
            //                                 <ResourceBar
            //                                     key={'1'}
            //                                     total={1}
            //                                     current={0}
            //                                     previousCurrent={1}
            //                                     preFillPercent={3}
            //                                     timeToFillBar={8}
            //                                     className={`casting ice`}
            //                                     inverted
            //                                 />
            //                             </div>
            //                             <div className={`myCastBar channeling`}>
            //                                 <ResourceBar
            //                                     key={'1'}
            //                                     total={1}
            //                                     current={0}
            //                                     previousCurrent={1}
            //                                     preFillPercent={3}
            //                                     timeToFillBar={10}
            //                                     className={`casting earth`}
            //                                     inverted
            //                                 />
            //                             </div>
            //                             <div className={`myCastBar channeling`}>
            //                                 <ResourceBar
            //                                     key={'1'}
            //                                     total={1}
            //                                     current={0}
            //                                     previousCurrent={1}
            //                                     preFillPercent={3}
            //                                     timeToFillBar={5}
            //                                     className={`casting earth`}
            //                                     inverted
            //                                 />
            //                             </div>
                                      

            //                     </div>
            //         <div className={`myCastBar ${isInturrupted?'inturrupted':''}`}>
            //             <ResourceBar
            //                 key={0}
            //                 total={1}
            //                 current={0}
            //                 preFillPercent={50}
            //                 previousCurrent={1}
            //                 timeToFillBar={isInturrupted?999: 555 || 0.5}
            //                 className={isInturrupted?"inturrupted":`casting ${"earth"}`}
            //             />
            //         </div>
            //     </div>
            // </div>
            // </div>
        }

        {
            twoClickCast && myData && !myData.casting && handData[selectedSpellIndex] &&
            <div className="cardDescriptionArea">
                <div className="spellDescriptionWrapper">
                    <CardDescription
                        spellData={handData[selectedSpellIndex]}
                        isExpanded={spellDescriptionExpanded}
                        onClick={() => setSpellDescriptionExpanded(!spellDescriptionExpanded)}
                    />
                </div>
            </div>
        }

        {myData && myData.id && winningPlayers[myData?.id] && <div className="persistantMessage victory">Victory!</div>}

        {myData && myData.id && winningTeam != -1 && !winningPlayers[myData?.id] && <div className="persistantMessage defeat">Defeat.</div>}

        {(!myData || !myData.id) && winningTeam != -1 && <div className="persistantMessage observing">Game Over.</div>}


        {myData && currFrameData.gameState?.tutorial && currFrameData.gameState?.roundType == roundTypes.fighting && currFrameData.timeLeft > 18 &&
            <div className="tutorialAreaRow">
                <div className="tutorialAreaWrapper">
                    <div className={`effectImageArea tutorialMessage`}>
                        Touch a spell to cast it
                    </div>
                </div>
            </div>
        }
        {myData && currFrameData.gameState?.tutorial && currFrameData.gameState?.roundType == roundTypes.fighting && currFrameData.timeLeft < 20 && currFrameData.timeLeft > 9 &&
            <div className="tutorialAreaRow">
                <div className="tutorialAreaWrapper">
                    <div className={`effectImageArea tutorialMessage`}>
                        You can also touch spells to cast them when ready
                    </div>
                </div>
            </div>
        }
        {myData && currFrameData.gameState?.tutorial && currFrameData.gameState?.roundType == roundTypes.fighting && currFrameData.timeLeft < 10 &&
            <div className="tutorialAreaRow">
                <div className="tutorialAreaWrapper">
                    <div className={`effectImageArea tutorialMessage`}>
                        You can also drag your finger to cast
                    </div>
                </div>
            </div>
        }
        {
            // myData && currFrameData.gameState?.tutorial && currFrameData.gameState?.roundType == roundTypes.fighting && currFrameData.timeLeft < 7 && 
            //     <div className="tutorialAreaRow">
            //         <div className="tutorialAreaWrapper">
            //             <div className={`effectImageArea tutorialMessage`}>
            //                 Cooldowns reset between each round
            //             </div>
            //         </div>
            //     </div>
        }


        {myData && currFrameData?.goingToWinTeam == -1 && currFrameData.gameState.roundType == roundTypes.fighting &&
            <div className="SpellListAreaWrapper">
                <SpellList
                    owner={myData}
                    spells={handData}
                    items={myData.items}
                    selectedSpellIndex={selectedSpellIndex}
                    setCardBoundingBoxes={setCardBoundingBoxes}
                    setSelectedSpellIndex={setSelectedSpellIndex}
                    //castSpell={castSpell}
                    autoCastingOn={autoCastingOn}
                    isLobby={currFrameData.gameState && currFrameData.gameState.roundType == roundTypes.lobby}
                    spellQueue={spellQueue}
                    castingIndex={myData.castingIndex}
                    refresher={true}
                    spellsCasted={spellsCasted}
                    spellsCastingWhenReady={spellsCastingWhenReady}
                    setSpellsCasted={setSpellsCasted}
                    setSpellsCastingWhenReady={setSpellsCastingWhenReady}
                    selectedAlly={selectedAlly}
                    selectedEnemy={selectedEnemy}
                    //selectedPlayer={selectedOtherPlayer?otherPlayersData[selectedOtherPlayer] || myData: null}
                    isFighting={currFrameData.gameState.roundType == roundTypes.fighting}
                    countdown={currFrameData.countdown}
                    // onSelect={()=>setSpellDescriptionExpanded(false)}
                    onClickSpell={(index) => {
                        //setSpellDescriptionExpanded(false);
                        let spellData = handData[index];
                        if (index >= handData.length) {
                            spellData = myData.items[index % handData.length];
                        }

                        if (spellData && spellData.notCastable) {
                            return;
                        }
                        castSpell(index, myData.id, true);
                    }}
                    makeSpaceAboveSpells={currFrameData.gameState.tutorial && handData.length == 3}
                />
            </div>
        }


        {myData && myData.id && currFrameData.gameState && !currFrameData.gameState.tutorial && currFrameData.gameState.roundType == roundTypes.lobby && myData &&
        <React.Fragment>
            <LobbyButtons
                selectedAlly={selectedAlly}
                selectedEnemy={selectedEnemy}
                otherPlayers={otherPlayersData}
                setSelectedAlly={setSelectedAlly}
                setSelectedEnemy={setSelectedEnemy}
                ioConnection={ioConnection}
                isHost={true}
                gameID={gameID}
                playersLocked={currFrameData.playersLocked}
                isTutorial={currFrameData.gameState.tutorial}
            />
             <div className="endgamebuttonswrapper">
                <div className="endgamebuttons">
                    <div className="observe" 
                        onClick={() => {
                            ioConnection.emit(serverMessages.requests.observe, {id: playerID});
                        }}>
                        <BigButton
                            teal >
                            Observe
                        </BigButton>
                    </div>
                    <div className="changeTeams">
                        <BigButton
                            purple 
                            onClick={() => {
                                ioConnection.emit(serverMessages.requests.changeTeams, {id: playerID});
                            }}>
                            Change Teams
                        </BigButton>
                    </div>

                </div>
            </div>
            </React.Fragment>
        }

        {currFrameData.gameState && currFrameData.gameState.tutorial && currFrameData.gameState.roundType == roundTypes.lobby && myData &&
            <div className="TutorialButtonRow">
                <div className="TutorialButtonWrapper">
                    <div className="TutorialStartButton">

                        <BigButton
                            green
                            onClick={() => {
                                if (soundsOn) Sounds.click.play();
                                ioConnection.emit(serverMessages.requests.ready, {});
                                setSelectedAlly = { setSelectedAlly }
                                setSelectedEnemy = { setSelectedEnemy }
                            }} >
                            Start
                        </BigButton>
                    </div>
                </div>
            </div>
        }

        {myData && winningTeam != -1 &&
            <div className="endgamebuttonswrapper">
                <div className="endgamebuttons">
                    <div className="mainmenu" onClick={() => {
                        ioConnection.emit(serverMessages.requests.leave, {})
                        navigate('/')
                        Sounds.cheering.pause();
                        Sounds.cheering.currentTime = 0;
                    }}>
                        <BigButton
                            teal >
                            Main Menu
                        </BigButton>
                    </div>
                    {!currFrameData.gameState?.tutorialEnd && <div className="playagain">
                        <BigButton
                            purple onClick={() => {
                                // if(lastOptions.playersLocked){
                                //         ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameID, playerID, myName, gameOptions: singlePlayer(currentLevel)});
                                //         setGameID("");
                                //         localStorage.setItem(storageValues.currentGame, "");
                                // } else {
                                let myHat = localStorage.getItem(storageValues.myHat);
                                let myRobe = localStorage.getItem(storageValues.myRobe);
                                let myGameName = gameID;
                                if(!myGameName) myGameName = partyID;
                                ioConnection.emit(serverMessages.requests.joinOrCreateGame, { gameName: myGameName, playerID, myName, myDeck: lastDeck, gameOptions: lastOptions, myHat, myRobe });
                                setGameID("");
                                localStorage.setItem(storageValues.currentGame, "");
                                Sounds.cheering.pause();
                                Sounds.cheering.currentTime = 0;
                                //}    
                            }}>
                            Play Again!
                        </BigButton>
                    </div>}

                </div>
            </div>
        }

        {
            // winningTeam != -1 && history &&
            //     <div className="history">
            //         { history.map(thing => {
            //             if(thing && thing.includes("died!")){
            //             return <div className="thing death">{thing}</div>
            //             } else if(thing && thing.includes("round")){
            //             return <div className="thing round">{thing}</div>
            //             } else if(thing && thing.includes("damage")){
            //             return <div className="thing ddmg">{thing}</div>
            //             } else if(thing && thing.includes("HUZZAH")){
            //             return <div className="thing huzzah">{thing}</div>
            //         } else {
            //             return <div className="thing">{thing}</div>
            //             }
            //         })}
            //     </div>
        }

        {!showingSettings && winningTeam == -1 &&
            <div className="footerWrapper">
                <div className="footer">
                    {currFrameData.gameState?.roundType == roundTypes.fighting &&
                        <div className="queueArea" onClick={() => cancelSpellQueue()}>
                            {myData && spellQueue.map(i => {
                                if (!handData[i]) return null;
                                return <div className={`castQueueItem`} key={i}>
                                    <img className={`castQueueImage`} src={imageMap[handData[i].image]} />
                                </div>
                            })}
                        </div>
                    }
                    {currFrameData?.gameState?.roundType == roundTypes.choosingToAdd &&
                        <div className="settingsButton flipView" onClick={() => setChoosingHidden(false)}>
                            <span className="material-symbols-outlined">
                                keyboard_arrow_down
                            </span>
                        </div>
                    }

                    <div className="settingsButton" onClick={() => setShowingSettings(true)}>
                        <span className="material-symbols-outlined">
                            exit_to_app
                        </span>
                    </div>
                    {soundsOnState && <div className="settingsButton" onClick={() => {
                        setSoundsOnState(false);
                        window.soundsOn = false;
                    }}>
                        <span className="material-symbols-outlined">
                            volume_up
                        </span>
                    </div>
                    }
                    {!soundsOnState && <div className="settingsButton" onClick={() => {
                        checkSounds();
                        setSoundsOnState(true);
                        window.soundsOn = true;
                        window.Sounds.softclick.play();
                    }}>
                        <span className="material-symbols-outlined">
                            volume_off
                        </span>
                    </div>
                    }
                </div>
            </div>
        }

    </div>
}


export default GamePage;


// if we are connected to the AI engine, send up the game data
const libAI_sendData = (data) => {
    let ai_data = {}

    Object.keys(data.otherPlayers).map((pid, i) => {
        let player = data.otherPlayers[pid];
        //console.log(player);
        ai_data[player.id] = {
            player_index: i,
            player_id: player.id,
            player_name: player.name,
            player_team: player.team,
            player_debuff: player.debuff,
            player_casting: player.casting,
            player_castStartTime: player.castStartTime,
            player_castTarget: player.castTarget,
            player_warmingUp: player.warmingUp,
            player_currentLevel: player.currentLevel,
            player_currentHealth: player.currentHealth,
            player_maxHealth: player.maxHealth,
            player_currentMana: player.currentMana,
            player_maxMana: player.maxMana,
            player_ready: player.ready,
            player_hat: player.hat,
            player_canCast: player.ifAttemptCast == false
        }

        Object.keys(player.states).map(key => {
            ai_data[player.id + "_state_" + key] = {
                state_amount: player.states[key].amount,
                state_key: key,
                state_duration: player.states[key].duration,
                state_player: player.id
            }
        });

        if (player.spells) {
            player.spells.map((spell, i) => {
                ai_data[player.id + "_spell_" + i] = {
                    spell_index: i,
                    spell_name: spell.name,
                    spell_owner: player.id,
                    spell_spellType: spell.spellType,
                    spell_damageType: spell.damageType,
                    spell_target: spell.target,
                    spell_damage: spell.damage,
                }
            })
        }
    });

    if (data.me) {

        ai_data[data.me.id] = {
            player_is_ai_controller: true,
            player_id: data.me.id,
            player_name: data.me.name,
            player_team: data.me.team,
            player_debuff: data.me.debuff,
            player_casting: data.me.casting,
            player_castStartTime: data.me.castStartTime,
            player_castTarget: data.me.castTarget,
            player_warmingUp: data.me.warmingUp,
            player_currentLevel: data.me.currentLevel,
            player_currentHealth: data.me.currentHealth,
            player_maxHealth: data.me.maxHealth,
            player_currentMana: data.me.currentMana,
            player_maxMana: data.me.maxMana,
            player_ready: data.me.ready,
            player_hat: data.me.hat,
        }

        data.me.spells.map((spell, i) => {

            ai_data[data.me.id + "_spell_" + i] = {
                spell_index: i,
                spell_name: spell.name,
                spell_owner: data.me.id,
                spell_spellType: spell.spellType,
                spell_damageType: spell.damageType,
                spell_target: spell.target,
                spell_damage: spell.damage,
            }
        });
    }

    ai_data["rountInfo"] = {
        game_countdown: data.countdown || 0,
        game_time: data.time,
        game_timeLeft: data.endRoundTime - data.time,
        game_gameState_roundType: data.gameState.roundType,
        game_stateIndex: data.stateIndex,
        game_endRoundTime: data.endRoundTime,
    };

    ai_data.type = "ai-frame-data";

    window.parent.parent.postMessage(ai_data, "*");
}