
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Whiskey, Neat.",
    description: "You can still cast spells when stunned.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    damage: 0,
    duration: 40,
    level: 1,
    target: targets.selections.targetSelf,
    image: "whiskey_glass",
   // flair: "That's a no bullshit drink.",
    item: true,
    alwaysCast: true,
    cost: 3,
    effects: [
        // {
        //     action: actions.addTimerState,
        //     actionParams: [
        //         targets.me, 
        //         stateBuffs.penetrate, 
        //     60,
        //     ],
        // },
        {
            action: actions.addTimerState,
            actionParams: [
                targets.me, 
                stateBuffs.unstunnable, 
                40,
            ],
        },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             buff: buffs.damageTypeBonus,
        //             buffParams: [1, spellTypes.fire]
        //         }, 
        //         60, 
        //     ]
        // },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             buff: buffs.damageTypeBonus,
        //             buffParams: [1, spellTypes.earth]
        //         }, 
        //         40, 
        //     ]
        // },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             buff: buffs.damageTypeBonus,
        //             buffParams: [1, spellTypes.ice]
        //         }, 
        //         60, 
        //     ]
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}