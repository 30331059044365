
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');



module.exports = {
    name: "Leather Bag of Gettin' Lucky",
    description: "Gain 1 exta money each round.",
    spellType: spellTypes.weapon,
    level: 1,
    notCastable: true,
    canHoldMultiple: true,
    noDiscardDeck: true,
    extraRoundMoney: 1,
    image: "sac",
    flair: "For the 'long' game"
}