import React, {useState, useEffect} from 'react';

const AllSpells = require('shared/decks/available');

const spellTypes = require('shared/cardConstants/spellTypes');
let {Weapons, BasicArmor, AdvancedArmor, Armor, Items} = require('shared/decks/providedDecks');
const magicSack = require('shared/cards/allCards/spells/magicSack');

import Spell from '../game/components/SpellList/Spell'
import CardDescription from '../game/cardSelectionPage/components/CardDescription'
import BigButton from './components/BigButton';
import PaginatedSelector from './components/PaginatedSelector.js';
import imageMap from '../../images/index.js'


window.DeckTabs = {
    Basic: 0,
    Specials: 1,
    Unused: 2
}



export default function DeckPage({myDeck, setDeck, selectedCard, setSelectedCard}){

    let level1Ice = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 1 && AllSpells[key].spellType == spellTypes.ice;
    })

    let level1Fire = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 1 && AllSpells[key].spellType == spellTypes.fire;
    })
    
    let level1Earth = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 1 && AllSpells[key].spellType == spellTypes.earth;
    })

    let weapons = [...Weapons()];

    let level2Ice = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 2 && AllSpells[key].spellType == spellTypes.ice;
    })

    let level2Fire = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 2 && AllSpells[key].spellType == spellTypes.fire;
    })
    
    let level2Earth = Object.keys(myDeck).filter(key => {
        return AllSpells[key].level == 2 && AllSpells[key].spellType == spellTypes.earth;
    })

    let Armor1 = BasicArmor().slice(0, 6);

    let Armor2 = BasicArmor().slice(6, 12);

    let Armor3 = AdvancedArmor();

    let level1Potions = Items().filter(key => {
        return AllSpells[key].level == 1;
    })

    let level2Potions = Items().filter(key => {
        return AllSpells[key].level == 2;
    })



    return <div className="profilePage"> 

        { selectedCard && selectedCard.effectImage &&
            <div className={`effectImageArea`}>

                <div className="effectImageTitle">
                    {selectedCard.effectImage == "stun" &&  <img src={imageMap["stun_white"]}/>}
                    {selectedCard.effectImage == "frost" &&  <img src={imageMap["frost"]}/>}
                    {selectedCard.effectImage == "fire" &&  <img src={imageMap["fire_white"]}/>}
                   
                    <div className="effectImageTitleText">
                        {selectedCard.effectImage == "stun" && "Stun"}
                        {selectedCard.effectImage == "frost" && "Ice Weakness"}
                        {selectedCard.effectImage == "fire" && "Delayed Damage"}
                    </div>
                </div>
                
                {selectedCard.effectImage == "stun" && <div className="effectImageText">
                    Cannot cast spells. Earth damage doubled.
                </div>}
                {selectedCard.effectImage == "frost" && <div className="effectImageText">
                    Increased ice damage for 10 seconds.
                </div>}
                {selectedCard.effectImage == "fire" && <div className="effectImageText">
                    Will take fire damage in 20 seconds.
                </div>}
            </div>
        }
    
        { selectedCard &&
            <div className={`selectedCardArea`}>
                <CardDescription
                    spellData={selectedCard}
                    onClose={()=>setSelectedCard(null)}
                    showClose
                />
            </div>
        }
    
       {  <div className="spellsSelectArea" style={{visibility:(selectedCard?'hidden':'')}}>
          
                <PaginatedSelector
        
                    sections={[
                        ["Fire Spells", level1Fire],
                        ["Earth Spells", level1Earth],
                        ["Ice Spells", level1Ice],
                        ["Fire Specials", level2Fire],
                        ["Earth Specials", level2Earth],
                        ["Ice Specials", level2Ice],
                        ["Potions I", level1Potions],
                        ["Potions II", level2Potions],
                        ["Armor I", Armor1],
                        ["Armor II", Armor2],
                        ["Armor III", Armor3],
                       // ["Items", weapons], 
                    ]}
                    pageAmount={6}
                    Card={({item})=>{
                        let spell = AllSpells[item];
                        // let image = spell.image;
                        return <div className={`selectionItem spell`} key={item}
                            onClick={()=>{
                                setSelectedCard(spell);
                            }}
                        >
                            <Spell 
                                grey
                                darkBackground
                                animated={false}
                                spellData={spell}
                                key={item} 
                            />
                        </div>
                    }}
                />
            </div>
        }
    </div>
}



/*
function DeckPageOld({myDeck, setDeck}){
    const [spellDescriptionStyle, setSpellDescriptionStyle] = useState({});

    const [selectedSpellName, setSelectedSpellName] = useState(false);

    const [spellTriples, setSpellTriples] = useState([]);

    const [deckIssue, setDeckIssue] = useState("");

    useEffect(()=>{
        let allSpells = Object.values(AllSpells);
        let spellTriples = [];
        let ind = 0;
        while(ind < allSpells.length){
             if(!allSpells[ind+1] || allSpells[ind].level < allSpells[ind+1].level ){
                 spellTriples.push([allSpells[ind].name, null, null]);
                 ind+=1;
             } else if(!allSpells[ind+2] || allSpells[ind+1].level < allSpells[ind+2].level){
                 spellTriples.push([allSpells[ind].name, allSpells[ind+1].name, null]);
                 ind+=2;
             } else {
                 spellTriples.push([allSpells[ind].name, allSpells[ind+1].name, allSpells[ind+2].name]);
                 ind+=3;
             }
            
        }
        setSpellTriples(spellTriples);
    }, []);


    const AddRemoveCard = (selectedSpellName) => {
        if(!myDeck) return;
        let newDeck = {...myDeck};
        if(newDeck[selectedSpellName]){
            delete newDeck[selectedSpellName];
        } else {
            newDeck[selectedSpellName] = true;
        }
        let newDeckIssue = "";
        let numBasic = 0;
        let numSpecial = 0;
        let badCards = []; // migration for when things are removed
        Object.keys(newDeck).map((name)=>{
            let card = AllSpells[name];
            if(!card){
                badCards.push(name);
                return;
            }
            if(card.level == 1){
                numBasic++;
            } else if(card.level == 2){
                numSpecial++;
            }
        })
        for(let i = 0; i < badCards.length; i++){
            delete newDeck[badCards[i]];
        }
        if(numBasic < 9){
            newDeckIssue = "Minimum 9 Basic Spells Required";
        } else if(numSpecial < 9){
            newDeckIssue = "Minimum 9 Specials Required";
        }
       
        setDeck(newDeck);

        if(!newDeckIssue){
            localStorage.setItem(storageValues.playerDeck, JSON.stringify(newDeck));
        }
        setDeckIssue(newDeckIssue);
    } 

  

    return <div className="deckPage">
        { selectedSpellName && myDeck &&
            <div  className="spellChooseDescriptionArea" style={spellDescriptionStyle}>
                <div className="spellChooseDescriptionWrapper">
                    <CardDescription
                        spellData={AllSpells[selectedSpellName]}
                        isExpanded={true}
                        onClose={()=>setSelectedSpellName(false)}
                        button={()=>
                            <div className={"deckaddbuttonwrapper"}>
                                <div className={"deckaddbutton"}>
                                    <BigButton 
                                            onClick={()=>{
                                                AddRemoveCard(selectedSpellName);
                                                setSelectedSpellName(false);
                                            }}
                                            black={myDeck[selectedSpellName]}
                                            blue={!myDeck[selectedSpellName] && AllSpells[selectedSpellName].spellType == "ice"} 
                                            red={!myDeck[selectedSpellName] && AllSpells[selectedSpellName].spellType == "fire"} 
                                            green={!myDeck[selectedSpellName] && AllSpells[selectedSpellName].spellType == "earth"} 
                                            >
                                                {(myDeck[selectedSpellName])?"Remove":"Add"}
                                    </BigButton> 
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
        }
        <div className={`ScrollableSpellList DeckPage`}>
        
                {spellTriples.map((threespells, j)=>
                    <React.Fragment>
                        {j == 0 && <div className="spellsection top">Basic Spells</div>}
                        {j == 6 && <div className="spellsection">Specials</div>}
                        <div className={`SpellListRelative DeckPage`}>
                            {threespells.map((spellname, i)=>{
                                if(!AllSpells[spellname]){
                                    console.error("Spell not found! name:"+spellname);
                                    return null;
                                }
                                return myDeck && <Spell 
                                    isDeckPage
                                    isSelected={selectedSpellName == spellname}
                                    isChosen={myDeck[spellname]}
                                    onClick={(e)=> {
                                        let myY = e.currentTarget.getBoundingClientRect().top;
                                        // if(!myY&& e.parentElement) myY = e.parentElement.y;
                                        // if(!myY) myY = e.clientY;
                                        let height = 232;
                                        if(window.innerHeight > 850 && window.innerWidth > 450){
                                            height = 310;
                                        }
                                    
                                        if(myY < height + 20){
                                            setSpellDescriptionStyle({top: myY + e.currentTarget.offsetHeight + 10});
                                        } else {
                                            setSpellDescriptionStyle({bottom: window.innerHeight - myY + 5});
                                        }

                                    
                                        if(selectedSpellName == spellname){
                                            setSelectedSpellName(null);
                                        } else {
                                            setSelectedSpellName(spellname);
                                        }
                                    }}
                                    key={i} 
                                    spellData={AllSpells[spellname]}
                                
                                />
                            })}
                        </div>
                    </React.Fragment>
                )}
        
        </div>
            <div className="deckbackbuttonrow">
                <div className="deckbackbutton">
                    {deckIssue == "" && <BigButton 
                            onClick={()=>setShowDeckPage(false)}
                            teal >
                                Back
                    </BigButton> }

                    {deckIssue != "" && deckIssue}
                </div>
            </div> 
        </div>
}
*/