
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
let ring = require('./ring');


module.exports = {
    name: "Pendant of Plastered Passions",
    description: "Draw 2 extra basic spells each round",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "pendant",
    requires: ring.name,
    // upgradesTo: Amulet of 

    effects: [
        {
            buff: buffs.drawExtraBasicCards, 
            buffParams: [2]
        },
        // {
        //     buff: buffs.damageTypeBonus,
        //     buffParams: [1, spellTypes.fire]
        // },
        // {
           
        //     buff: buffs.damageTypeBonus,
        //     buffParams: [1, spellTypes.earth]
        // },
        // {
           
        //     buff: buffs.damageTypeBonus,
        //     buffParams: [1, spellTypes.ice]
        // }
        // {
        //     buff: buffs.damageTypeBonus,
        //     buffParams: [1, spellTypes.fire]
        // }
    ]
}