
module.exports = {
    damage: "damage",
    stateConditionalDamage: "stateConditionalDamage",
    buffTypeAmountConditionalDamage: "buffTypeAmountConditionalDamage",
    
    gainExperience: "gainExperience",
    gainHealth: "gainHealth",
    gainMana: "gainMana",
    //addSpell: "addSpell",
    //addGear: "addGear",
    removeTimer: "removeTimer",
    restoreCooldown: "restoreCooldown",
    
    addTimerState: "addTimerState",
    addTimerBuff: "addTimerBuff",
    addTimerPeriodicEffect: "addTimerPeriodicEffect",
    addTimerConditionPromptEffect: "addTimerConditionPromptEffect",
    addTimerConditionPromptBuff: "addTimerConditionPromptBuff",
    
    addTimerConditionState: "addTimerConditionState",
    
    addConditionalBuff: "addConditionalBuff",
    addConditionalState: "addConditionalState",
    addConditionalPeriodicEffect: "addConditionalPeriodicEffect",
    addConditionalConditionPromptEffect: "addConditionalConditionPromptEffect",
    addConditionalTimerBuff: "addConditionalTimerBuff",
    
    addDamageBuffer: "addDamageBuffer",
    drainMana: "drainMana",
}