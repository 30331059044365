
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Snowball",
    description: "Slow cast time by 1s; 1 ice weakness; 1 ice damage.",
    spellType: spellTypes.ice,
    level: 1,
   // charges: 2,
    castTime: 1.5,
    cooldown: 10,
    //damage: 2,
    duration: 5,
    damageType: spellTypes.ice,
    target: targets.selections.targetEnemy,
    //flair: "Harmless, mostly.",
    image: "snowball",
    effectNumber: "1/1",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.ice],
        },
        { // 1 ice weakness for 10 seconds
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: debuffs.damageTypeWeakness, 
                    buffParams: [1, spellTypes.ice]
                }, 10
            ],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer,
                {
                    buff: buffs.slowerCastTime, 
                    buffParams: [1, spellTypes.ice]
                }, 
                5
            ],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}