

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Subtle Recovery",
    description: "Gain 1 health every 2 seconds and increase dodge chance by 5%.",
   // flair: "You will want to cast regularly",
    spellType: spellTypes.earth,
    level: 1,
   // charges: 1,
    castTime: 2.5,
    cooldown: 10,
    duration: 10,
    damage: 0,
    target: targets.selections.targetAlly,
    image: "eyes",
    effectNumber: "+5",
    effects: [
        {
            action: actions.addTimerPeriodicEffect,
            actionParams: [
                targets.selectedPlayer,
                {
                    action: actions.gainHealth,
                    actionParams: [1],
                    image: "eyes"
                },
                2, 
                10
            ],
        },
        {
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: buffs.dodgeChance, 
                    buffParams: [5]
                }, 10
            ],
        }
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, {
        //             buff: buffs.fasterCastTime, 
        //             buffParams: [1]
        //         }, 11
        //     ],
        // },
    ],
    xpGain: 0,
    manaCost: 1,
}
