
module.exports = {
    playerID: "playerID",
    partyID: "partyID",
    playerName: "playerName",
    playerDeck: "playerDeck",
    currentGame: "currentGame",
    currentMenuPage: "currentMenuPage",
    gameOptions: "gameOptions",
    currentLevel: "currentLevel",
    myHat: "myHat",
    myRobe: "myRobe"
}