let burnSomeone = require('../cards/allCards/spells/burnSomeone');
let consumingFlames = require('../cards/allCards/spells/consumingFlames');
let explosion = require('../cards/allCards/spells/explosion');
let turnUpTheHeat = require('../cards/allCards/spells/turnUpTheHeat');
let firemaster = require('../cards/allCards/spells/firemaster');
let littlebomb = require('../cards/allCards/spells/littlebomb');
let recovery = require('../cards/allCards/spells/recovery');

let icicles = require('../cards/allCards/spells/icicles');
let iceToFace = require('../cards/allCards/spells/iceToFace');
let letInTheCold = require('../cards/allCards/spells/letInTheCold');
let coldmaster = require('../cards/allCards/spells/coldmaster');
let bustamove = require('../cards/allCards/spells/bustamove');
let snowball = require('../cards/allCards/spells/snowball');

let vines = require('../cards/allCards/spells/vines');
let hitemwithastick = require('../cards/allCards/spells/hitemwithastick');
let heal = require('../cards/allCards/spells/heal');
let naturesStrength = require('../cards/allCards/spells/naturesStrength');
let smack = require('../cards/allCards/spells/smack');

let heatinup = require('../cards/allCards/spells/heatinup');

let flashbacks = require('../cards/allCards/spells/flashbacks')
let fireball = require('../cards/allCards/spells/fireball');
let firestorm = require('../cards/allCards/spells/firestorm');

let detonate = require('../cards/allCards/spells/detonate');

let coldShower = require('../cards/allCards/spells/coldShower');
let freeze = require('../cards/allCards/spells/freeze');
let drainlife = require('../cards/allCards/spells/drainlife');
let shards = require('../cards/allCards/spells/shards');

let blizzard = require('../cards/allCards/spells/blizzard')
let shatter = require('../cards/allCards/spells/shatter');
let avalanche = require('../cards/allCards/spells/avalanche');
let cleanse = require('../cards/allCards/spells/cleanse');

let fists = require('../cards/allCards/spells/fists');
let smokescreen = require('../cards/allCards/spells/smokescreen');
let meditate = require('../cards/allCards/spells/meditate')
let earthquake = require('../cards/allCards/spells/earthquake');
let squirrels = require('../cards/allCards/spells/squirrels');
let whiskey = require('../cards/allCards/potions/whiskey');
let shield = require('../cards/allCards/spells/shield');
let grenade = require('../cards/allCards/spells/grenade');

let askforalight = require('../cards/allCards/spells/askforalight');

const magicSack = require('../cards/allCards/spells/magicSack');
const dagger = require('../cards/allCards/armor/dagger');
const tome = require('../cards/allCards/spells/tome');

const margarita = require('../cards/allCards/potions/margarita');
let tequila = require('../cards/allCards/potions/tequila');
let vodka = require('../cards/allCards/potions/vodka');
let beer = require('../cards/allCards/potions/beer');
let water = require('../cards/allCards/potions/water');
let adios = require('../cards/allCards/potions/adios');
const everclear = require('../cards/allCards/potions/everclear');

const cinnomonwhiskey = require('../cards/allCards/potions/cinnomonwhiskey');
const esspressomartini = require('../cards/allCards/potions/esspressomartini');
const hottoddy = require('../cards/allCards/potions/hottoddy');
const bloodymary = require('../cards/allCards/potions/bloodymary');

const belt = require('../cards/allCards/armor/belt');
const silksash = require('../cards/allCards/armor/silksash');
const ring = require('../cards/allCards/armor/ring');
const pendant = require('../cards/allCards/armor/pendant');
const trousers = require('../cards/allCards/armor/trousers');
const pants = require('../cards/allCards/armor/pants');
const tunic = require('../cards/allCards/armor/tunic');
const chestplate = require('../cards/allCards/armor/chestplate');
const lantern = require('../cards/allCards/armor/lantern');
const clothboots = require('../cards/allCards/armor/clothboots');
const studdedboots = require('../cards/allCards/armor/studdedboots');
const forcefield1 = require('../cards/allCards/armor/forcefield1');
const forcefield2 = require('../cards/allCards/armor/forcefield2');
const spellbook = require('../cards/allCards/armor/spellbook');
const bow = require('../cards/allCards/armor/bow');


module.exports = {
  
    // ICE default
    [icicles.name]: icicles,
    [letInTheCold.name]: letInTheCold,
    [iceToFace.name]: iceToFace,
    [drainlife.name]: drainlife,
    [cleanse.name]: cleanse,
    [bustamove.name]: bustamove,

    // unlockable
    [snowball.name]: snowball,
    [coldmaster.name]: coldmaster,
    //[coldmaster.name]: coldmaster,

    // something that slows down cast time
  
    ////////////////////////
        
    // EARTH default
    [vines.name]: vines,
    [hitemwithastick.name]: hitemwithastick,
    [heal.name]: heal,
    //[whiskey.name]: whiskey,
    [recovery.name]: recovery,
    [shield.name]: shield,
    [grenade.name]: grenade,
    [smack.name]: smack,



    // unlockable
    // [smack.name]: smack,
    //[fists.name]: fists,
    // something that forces people to attack me
    // [heal.name]: heal,

    ////////////////////////

    // FIRE default
    [fireball.name]: fireball,
    [explosion.name]: explosion,
    [firemaster.name]: firemaster,
    [turnUpTheHeat.name]: turnUpTheHeat,
    [burnSomeone.name]: burnSomeone,
    [firestorm.name]: firestorm,
    [smokescreen.name]: smokescreen,

    // unlockable

   
    ////////////////////////
    // SPECIALS
    ///////////////////////
    
    // ICE default specials
    [freeze.name]: freeze,
    [shards.name]: shards,
    [shatter.name]: shatter,

    // unlockable
    [blizzard.name]: blizzard,
    [coldShower.name]: coldShower,
   
    // EARTH default specials
    [squirrels.name]: squirrels,
    [askforalight.name]: askforalight,
    [meditate.name]: meditate,

    // unlockable
   // [meditate.name]: meditate,
   [earthquake.name]: earthquake,


    // FIRE default specials
    [heatinup.name]: heatinup,
    [flashbacks.name]: flashbacks,
    [detonate.name]: detonate,

    // unlockable
    [consumingFlames.name]: consumingFlames,


    [magicSack.name]: magicSack,
    [tome.name]: tome,
    [dagger.name]: dagger,

    [belt.name]: belt,
    [lantern.name]: lantern,
    [silksash.name]: silksash,
    [ring.name]: ring,
    [pendant.name]: pendant,
    [trousers.name]: trousers,
    [pants.name]: pants,
    [tunic.name]: tunic,
    [chestplate.name]: chestplate,
    [clothboots.name]: clothboots,
    [studdedboots.name]: studdedboots,

    [forcefield1.name]: forcefield1,
    [bow.name]: bow,
    [forcefield2.name]: forcefield2,
    [spellbook.name]: spellbook,

    // ITEMS
    [margarita.name]: margarita,
    [beer.name]: beer,
    [whiskey.name]: whiskey,
    [cinnomonwhiskey.name]: cinnomonwhiskey,
    [esspressomartini.name]: esspressomartini,

    [water.name]: water,
    [bloodymary.name]: bloodymary,
    [hottoddy.name]: hottoddy,
   
    [tequila.name]: tequila,
    // [vodka.name]: vodka,
    [adios.name]: adios,
    [everclear.name]: everclear,


   
  
   // [hottoddy.name]: hottoddy
    // high proof whiskey- can't be dodged or reflected
    // glass of water- large heal
}