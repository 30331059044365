
import React from 'react';
import SpellList from '../components/SpellList/SpellList'
const spellTypes = require("shared/cardConstants/spellTypes");

export default function SpellSelectionPage(props){
    const {
        me,
        spells,
        gameState,
        localSpellChoices,
        hasMaxChoices,
        itemCount,
        selectedCardIndex,
        setSelectedCardIndex,
        chooseSpell,
        setShowDraw3,
        setShowRedraw
    } = props;

    return <React.Fragment>
        {selectedCardIndex == -1 && <div className="CardSelectionButtons">
            <div className="cardSelectionButtonsRow">
                {  !gameState.showInstructions && me.id && !me.ready && !me.canBeReady && me.canRedraw && !gameState.hideRedraw &&
                    <div className="redrawAllArea" onClick={()=>{setShowRedraw(true)}}>
                        <div className="icon">
                            <div className="material-symbols-outlined">
                                refresh
                            </div>
                        </div>
                        <div className="text">
                            Redraw
                        </div>
                    </div>
                }
                { 
                    // gameState.showDraw3 !== false &&
                    // <div className="drawSpecialsArea" onClick={()=>{setShowDraw3(true)}}>
                    //     <div className="icon">
                    //         <div className="material-symbols-outlined">
                    //             add
                    //         </div>
                    //     </div>
                    //     <div className="text">
                    //         Draw 3
                    //     </div>
                    // </div>
                }
                {
                    me.id && !me.ready && me.canBeReady &&
                    <div className="firstRoundInstructions">
                        Hit <span className="ready">Ready!</span> to continue.
                    </div>
                }
                {  gameState.showInstructions && me.id && !me.ready && !me.canBeReady &&
                    <div className="firstRoundInstructions">
                        Touch spells to see details.
                    </div>
                }
                {  
                // gameState.secondRound && me.id && !me.ready && !me.canBeReady &&
                //     <div className="firstRoundInstructions">
                //         {4 - itemCount} item slots left.
                //     </div>
                // }
                // {  gameState.thirdRound && me.id && !me.ready && !me.canBeReady &&
                //     <div className="firstRoundInstructions">
                //         {4 - itemCount} item slots left.
                //     </div>
                // }
                // {  !gameState.showInstructions && !gameState.secondRound && !gameState.thirdRound && me.id && !me.ready && !me.canBeReady &&
                //     <div className="firstRoundInstructions">
                //         {4 - itemCount} item slots left.
                //     </div>
                }
                {  me && me.id && me.ready && 
                    <div className="firstRoundInstructions">
                        Waiting for other players to finish.
                    </div>
                }
                {  (!me || !me.id) && 
                    <div className="firstRoundInstructions">
                        Players are choosing spells.
                    </div>
                }
            </div>
        </div>}
        <div className={`CardSelectionArea ${(me.spellsToChooseFrom || []).length <= 9?"chooseFromSix":""} ${selectedCardIndex == -1?"":""}`}>
            <SpellList
                spells={me.spellsToChooseFrom || []}
                disabledAppended={gameState.addInCurrentHand?[]:spells}
                cardChoices={localSpellChoices}
                selectedSpellIndex={selectedCardIndex}
                //setSelectedSpellIndex={setSelectedSpellIndex}
                tutorial={gameState.tutorial}
                animated={false}
                onClickSpell={(index)=>{
                    if(index < me.spellsToChooseFrom.length && selectedCardIndex == index && !me.ready && (localSpellChoices[index] || !hasMaxChoices)){
                        chooseSpell(index);
                    } else {
                        if(selectedCardIndex == index){
                            setSelectedCardIndex(-1);
                        } else {
                            setSelectedCardIndex(index);
                        }
                    }
                    
                }}
            />
        </div>
    </React.Fragment>
}