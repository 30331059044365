
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

// let name = "Wild Flame";

module.exports = {
  name: "Heating Up!",
  description: "Gain +1 fire damage each fire spell cast.",
  spellType: spellTypes.fire,
  castTime: 1,
  level: 2,
  cooldown: 40,
  duration: 10,
  bootsDamage: true,
  flair: "I can't miss!",
  target: targets.selections.targetSelf,
  image: "fireball",
  effectNumber: "/1x",
  effects: [
      { // put mark on selected player (for 15 seconds, when condition is met do thing)
        action: actions.addTimerConditionPromptEffect,
        actionParams: [
            targets.me,
            { // thing to be done when condition is met (add timer buff)
              action: actions.addTimerBuff,
             // isDebuff: true,
              id: "heatin", // sub effects need different ID from card
              actionParams: [
                  // triggering player is the player that satisfies the codition
                  targets.triggeringPlayer, 
                  {
                      buff: buffs.damageTypeBonus,
                      buffParams: [1, spellTypes.fire]
                  }, 
                  15, // each damage bonus added lasts 15 seconds
                  // TODO: let me put "heatin" here.  Then lasts until 'heatin' state is gone
              ],
              image: "heatin", 
            }, 
            // condition: [type, caster_requirement, target_requirement, damage_type_requirement ]
            [conditions.spellCast, targets.me, null, spellTypes.fire],
            // time that mark lasts (15 seconds)
            10
        ]
      }
  ], 
  xpGain: 0,
  manaCost: 1,
}

// module.exports = {
//     name: name,
//     description: "Place an effect that deals 1 fire damage in 6 seconds (if the effect is not removed).  You then gain fire damage bonuse equal to the damage done for 4 seconds.",
//     spellType: spellTypes.fire,
//     level: 1,
//     charges: 1,
//     castTime: 0,
//     cooldown: 9,
//     damage: 1,
//     target: targets.selections.targetEnemy,
//     image: "fireball",
//     effects: [
//         {
//             action: actions.addTimerPeriodicEffect,
//             actionParams: [
//                 targets.selectedPlayer,
//                 {
//                     action: actions.damage,
//                     actionParams: [1, spellTypes.fire],
//                     image: "fireball",
//                     description: "Fire damage incoming",
//                     isDebuff: true
//                 },
//                 6, 
//                 6
//             ],
//         },

//         {
//             action: actions.addTimerConditionPromptBuff,
//             actionParams: [
//               targets.me,
//               stateBuffs.mightGainFireDamage,
//               [conditions.damageDone, targets.me, null, spellTypes.fire, null, name],
//               {
//                 buff: buffs.damageTypeBonus,
//                 buffParams: [specialPromptBuffAmounts.triggeringDamageDone, spellTypes.fire]
//               }, 
//               10
//             ]
//           }
//         // {
//         //     action: actions.addTimerPeriodicEffect,
//         //     actionParams: [
//         //         targets.me, 
//         //         {
//         //             action: actions.addTimerBuff,
//         //             id: "mightGainFireBonus",
//         //             actionParams: [
//         //                 {
//         //                     buff: buffs.damageTypeBonus,
//         //                     buffParams: [specialAmounts.damageDoneByThisSpell, spellTypes.fire]
//         //                 }, 
//         //                 5, 
//         //             ],
//         //         }, 
//         //         11, 
//         //         11
//         //     ],
//         // }
//     ],
//     xpGain: 0,
//     manaCost: 1,
// }