

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Vampire Blood",
    description: "Gain health for all fire damage done.",
    flair: "Smart guy thinks he's a vampirrrre",
    spellType: spellTypes.fire,
    level: 2,
   // charges: 1,
    castTime: 1,
    cooldown: 30,
    duration: 10,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "blood",
    effectNumber: "+",
    effects: [
        {
            action: actions.addTimerConditionPromptEffect,
            actionParams: [
                targets.me,
                {
                    action: actions.gainHealth,
                    actionParams: [targets.me, specialDamageAmounts.triggeringDamageDone],
                    image: "blood"
                }, 
                [conditions.damageDone, targets.me, null, spellTypes.fire], // damage done by me
                10
            ]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}