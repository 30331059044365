

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Squirrel Army",
    description: "Gain +1 earth damage each time you are healed.",
    flair: "",
    spellType: spellTypes.earth,
    level: 2,
   // charges: 1,
    castTime: 0,
    duration: 15,
    cooldown: 40,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "squirrel",
    effectNumber: "/1x",
    effects: [
        { // put mark on selected player (for 15 seconds, when condition is met do thing)
            action: actions.addTimerConditionPromptEffect,
            actionParams: [
                targets.me,
                {
                    action: actions.addTimerBuff,
                    id: "squirrel", 
                    image: "squirrel", 
                    actionParams: [
                        targets.me, 
                        {
                            buff: buffs.damageTypeBonus,
                            buffParams: [1, spellTypes.earth]
                        }, 
                        20, 
                    ]
                },
                [conditions.healingDone, null, targets.me, null],
                10
            ]
        }
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.selectedPlayer, 
        //         {
        //             buff: buffs.healBonus,
        //             buffParams: [1, spellTypes.earth]
        //         }, 
        //         10, 
        //     ]
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}

// {
//     action: actions.addTimerConditionPromptEffect,
//     actionParams: [
//         targets.selectedPlayer,
//         {
//           action: actions.gainHealth,
//           actionParams: [targets.selectedPlayer, specialDamageAmounts.triggeringDamageDone],
//           image: "squirrel"
//         }, 
//         [conditions.damageDone, targets.selectedPlayer], // damage done by selected player
//         7
//     ]
//   },

// {
//     action: actions.removeTimer,
//     actionParams: [targets.selectedPlayer]
// },
// {
//     action: actions.addTimerState,
//     actionParams: [
//       targets.selectedPlayer, 
//       stateBuffs.silence, 
//       3,
//     ],
// }