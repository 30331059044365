

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Shards",
    description: "Deal 1 damage 3 times.",
    spellType: spellTypes.ice,
    level: 2,
   // charges: 1,
    castTime: 1.5,
    cooldown: 20,
    damage: 3,
    target: targets.selections.targetEnemy,
    image: "shards",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.ice],
        },
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.ice],
        },
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.ice],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}

