
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let stateDebuffs = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Smoke Screen",
    description: "Disable healing on the target.",
    flair: "",
    spellType: spellTypes.fire,
    level: 2,
   // charges: 1,
    castTime: 1,
    cooldown: 20,
    duration: 10,
    damage: 0,
    target: targets.selections.targetEnemy,
    image: "smoke",
    effects: [
        {
            action: actions.addTimerState,
            actionParams: [
                targets.selectedPlayer, 
                stateBuffs.noHeals,
                10
            ],
        }
  ],
    xpGain: 0,
    manaCost: 1,
}
