


import React, {useState, useEffect, useReducer, cloneElement, useCallback} from 'react';
import './VictoryPage.scss';
import BigButton from '../menu/components/BigButton';
const results = require('shared/outcomes.js');
import { useNavigate } from "react-router-dom";
const serverMessages = require('shared/serverMessages.js');
const storageValues = require("../storageValues");
import imageMap from 'images/index.js';
const singlePlayer = require("shared/singlePlayer.js");


export default function VictoryPage( props ) {
    const {winningTeam, winningPlayers, history, gameTotals, ioConnection, gameName, 
        playerID, myName, setGameID, allPlayers, currentLevel, setCurrentLevel, isTutorial} = props;

    const [glitters, setGlitters] = useState([]);

    let navigate = useNavigate();

    const [lastOptions, setLastOptions] = useState();
    

    useEffect(()=>{

        let _lastOptions = localStorage.getItem(storageValues.gameOptions);
        if(_lastOptions && _lastOptions != "undefined"){
             _lastOptions = JSON.parse(_lastOptions);
            setLastOptions(_lastOptions);
            if(_lastOptions.playersLocked){
                //console.log(winningTeam);
                //console.log(winningPlayers);
                if(winningTeam == 0){
                    setCurrentLevel(parseInt(currentLevel) + 1);
                }
            }
        }
    }, []);

    useEffect(()=>{
         let myGlitters = [];
         let maxGlitters = 300;
         let glitterCount =  window.innerWidth * 0.85;
         if(glitterCount > maxGlitters) glitterCount = maxGlitters;
         for(let i = 0; i < glitterCount; i++){
             let x = Math.random() * window.innerWidth;
             let y = Math.random() * window.innerHeight;
             myGlitters.push(<div key={i} className="glitter" style={{position: "absolute", left: `${x}px`, top: `${y}px`,  animationDelay: `${ (x * 1/150)}s`}}></div>)
         }
         setGlitters(myGlitters);
    }, []);

    let losingPlayers = {...allPlayers};

    return  <div className="winPage">

        {glitters}

        The winners
        <div className="winners">
            {winningPlayers && winningPlayers.map(playerData => {
                delete losingPlayers[playerData.id]
                return <div className="winner">
                    <div>{playerData.name}</div>
                    <img className="winnerHat" src={imageMap[playerData.hat]}/>
                    <div className="totalDamage">
                        {gameTotals[results.totalDamage][playerData.id] || 0} damage
                    </div>
                    <div className="totalHealing">
                        {gameTotals[results.totalHealing][playerData.id] || 0} healing
                    </div>
                </div>
            })}

        </div>

       
        <div className="losers">
            {losingPlayers && Object.keys(losingPlayers).map(playerId => {
                const playerData = allPlayers[playerId];
                return <div className="winner">
                    <div>{playerData.name}</div>
                    <img className="winnerHat" src={imageMap[playerData.hat]}/>
                    <div className="totalDamage">
                        {gameTotals[results.totalDamage][playerData.id] || 0} damage
                    </div>
                    <div className="totalHealing">
                        {gameTotals[results.totalHealing][playerData.id] || 0} healing
                    </div>
                </div>
            })}

        </div>

        {/* damage:
            {gameTotals && Object.keys(gameTotals[results.totalDamage]).map(playerID => {
                if(!playerID) return null;
                return <div className="totalItem">{playerID} : {gameTotals[results.totalDamage][playerID]}</div>
            })}
        healing:
            {gameTotals && Object.keys(gameTotals[results.totalHealing]).map(playerID => {
                 if(!playerID) return null;
                 return <div className="totalItem">{playerID} : {gameTotals[results.totalHealing][playerID]}</div>
            })} */}

        {/* The Ledger:
        <div className="history">
        {history && history.map(thing => {
            if(thing && thing.includes("died!")){
            return <div className="thing death">{thing}</div>
            } else if(thing && thing.includes("round")){
            return <div className="thing round">{thing}</div>
            } else if(thing && thing.includes("damage")){
            return <div className="thing ddmg">{thing}</div>
            } else if(thing && thing.includes("HUZZAH")){
            return <div className="thing huzzah">{thing}</div>
        } else {
            return <div className="thing">{thing}</div>
            }
        })}
        </div> */}
        <div className="buttons">

            <div className="mainMenu">
                <div className="button" onClick={ ()=> { 
                        ioConnection.emit(serverMessages.requests.leave, {})
                        navigate('/')
                    }}>
                   <img className="redCup" src={imageMap["redCup"]}/>
                   {/* <img className="buttonText" src='./images/practice.png' /> */}
                   <div className="buttonText">Main Menu</div>
               </div>
                {/* <BigButton 
                    red 
                    onClick={()=>{
                        ioConnection.emit(serverMessages.requests.leave, {});
                    }} >
                        Leave Game
                </BigButton> */}
            </div>
            {!isTutorial && <div className="nextGame">
                <div className="button" onClick={ ()=> {
                    let myGameName = gameID;
                    if(!myGameName) myGameName = partyID;
                    if(lastOptions.playersLocked){
                            ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName: myGameName, playerID, myName, gameOptions: singlePlayer(currentLevel)});
                            setGameID("");
                            localStorage.setItem(storageValues.currentGame, "");
                    } else {
                            ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName: myGameName, playerID, myName, gameOptions: lastOptions});
                            setGameID("");
                            localStorage.setItem(storageValues.currentGame, "");
                    }    
                }}>
                   <img className="redCup" src={imageMap["redCup"]}/>

                   {lastOptions && lastOptions.playersLocked && <div className="buttonText">Level {currentLevel}</div>}
                   {!lastOptions || !lastOptions.playersLocked && <div className="buttonText">Play Again</div>}
               </div>
                {/* <BigButton 
                    blue 
                    onClick={()=>{
                        ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName, playerID, myName});
                        setGameID("");
                        localStorage.setItem(storageValues.currentGame, "");
                    }} >
                        Next Game
                </BigButton> */}
            </div>}
        </div>
    </div>
}