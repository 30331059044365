
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
let pendant = require('./pendant.js');


module.exports = {
    name: "Weathered Ring of Reckless Abandon",
    description: "Reduce cooldowns by 1 second.",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "ring",
    // upgradesTo: "Pendant of Plastered Passions",
    effects: [
        {
            buff: buffs.fasterCastTime, 
            buffParams: [1]
        }
    ]
}