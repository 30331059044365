
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Pallete Cleanser",
    description: "Remove all effects from yourself.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    level: 1,
    damage: 0,
    duration: 0,
    target: targets.selections.targetSelf,
    image: "beer",
    item: true,
    alwaysCast: true,
    cost: 4,
    effects: [
      {
        action: actions.removeTimer,
        actionParams: [targets.me]
      }
    ],
    xpGain: 0,
    manaCost: 1,
}