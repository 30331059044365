

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Vodka Soda",
    description: "Reduce cast time by 1 and cooldowns by 1",
    flair: "For those who drink quickly.",
    spellType: spellTypes.item,
    charges: 1,
    castTime: 0,
    duration: 30,
    damage: 0,
    target: targets.selections.targetSelf,
    image: "water",
    item: true,
    level: 1,
    cost: 6,
    effects: [
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, {
        //             buff: buffs.fasterCastTime, 
        //             buffParams: [1]
        //         }, 30
        //     ],
        // },
        // {
        //     action: actions.addTimerBuff,
        //     actionParams: [
        //         targets.me, {
        //             buff: buffs.reducedCooldown, 
        //             buffParams: [1]
        //         }, 30
        //     ],
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}
