import React, { useEffect, useState } from 'react';
import './SpellDescription.scss';
import imageMap from 'images/index.js';
const {actionCosts} = require("shared/cardConstants/costs")

const targets = require("shared/cardConstants/targets");


function RedrawDescription( props ) {

    const { 
        onClick = ()=>{},
        onClose = ()=>{},
        showClose
    } = props;

    return <div className={`spellDescription open`} onClick={()=> onClick()}>
        <div className={`spellName`}>
             <div className={`nametext`}>
                Redraw Hand
             </div>
        </div>
       
        {/* {!isExpanded && <div className="readMore"> see details </div>} */}
        <div className="spellDescriptionText">

            { showClose && <div className="closeButton" onClick={()=>onClose()}>
                <span className="material-symbols-outlined">
                    close
                </span>
            </div> }
            
            <div className={`cardSpellDamage fire`}>
                <b>Can only use once</b>
            </div>
            <div className={`cardActualDescription`}>Redraw all cards currently not selected.</div>
        </div>
    </div>

}

export default RedrawDescription;
