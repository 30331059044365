
import React from 'react';

export default function FooterToolbar({currentPage, setCurrentPage}){

    return <div className="footerWrapper large">
        <div className="footer dark">
            <div className="footerButtons">
                <div className={`menuButton ${currentPage == Pages.Battle?'active':''}`} onClick={()=>{
                        setCurrentPage(Pages.Battle);
                        if(soundsOn)Sounds.softclick.play();
                    }}>
                    <span className="material-symbols-outlined" style={{color:"white"}}>
                        swords
                    </span>
                </div>
                <div className={`menuButton ${currentPage == Pages.Deck?'active':''}`} onClick={()=>{
                        setCurrentPage(Pages.Deck);
                        if(soundsOn)Sounds.softclick.play();
                    }}>
                    <span className="material-symbols-outlined" style={{color:"white"}}>
                        playing_cards
                    </span>
                </div>
                <div className={`menuButton ${currentPage == Pages.Profile?'active':''}`} onClick={()=>{
                        setCurrentPage(Pages.Profile);
                        if(soundsOn)Sounds.softclick.play();
                    }}>
                    <span className="material-symbols-outlined" style={{color:"white"}}>
                        person
                    </span>
                </div>
                <div className={`menuButton ${currentPage == Pages.Settings?'active':''}`} onClick={()=>{
                        setCurrentPage(Pages.Settings);
                        if(soundsOn)Sounds.softclick.play();
                    }}>
                    <span className="material-symbols-outlined" style={{color:"white"}}>
                        settings
                    </span>
                </div>
            </div>
        </div>
    </div>
}