

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Firestorm",
    description: "Deal 3 fire damage every 0.7 seconds to random enemies.",
    flair: "",
    spellType: spellTypes.fire,
    image: "fireballs",
    level: 1,
    //charges: 1,
    channeled: true,
    channelTime: 5,
    brokenByDamage: true,
    castTime: 2,
    cooldown: 30,
    target: targets.selections.targetSelf,
    playAnimationOnEnemies: true,
    effectNumber: "3x",
    effects: [
        
        { // thing to be done when condition is met (add timer buff)
            action: actions.addTimerConditionState,
            actionParams: [
                targets.me, 
                stateBuffs.channeling,
                5, // maxtime 
                [conditions.damageDone, null, targets.me, null]
            ]
        },
        { // need : actions.addEffectToGroup
            action: actions.addConditionalPeriodicEffect,
            actionParams: [
                targets.me, 
                {
                    action: actions.damage,
                    actionParams: [3, spellTypes.fire],
                    image: "firestorm",
                    description: "hit by firestorm",
                    overrideTarget: targets.randomAliveEnemy,

                }, 
                0.7,  // deal damage every 2 seconds
                [conditions.damageDone, null, targets.me, null], 
                5 // maxtime
            ],
        },
        // action: actions.addTimerPeriodicEffect,
        //     actionParams: [
        //         targets.selectedPlayer, 
        //         {
        //             action: actions.damage,
        //             actionParams: [5, spellTypes.fire],
        //             image: "fire",
        //             isDebuff: true
        //         }, 
        //         20, 
        //         20
        //     ],
        // { // need : actions.addEffectToGroup
        //     action: actions.addConditionalPeriodicEffect,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             action: actions.damage,
        //             actionParams: [1, spellTypes.fire],
        //             image: "firestorm2",
        //             description: "hit by firestorm2",
        //             overrideTarget: targets.allEnemies,

        //         }, 
        //         2,  // deal damage every 2 seconds
        //         [conditions.damageDone, null, targets.me, null], 
        //         5 // maxtime
        //     ],
        // },
        // { // need : actions.addEffectToGroup
        //     action: actions.addConditionalPeriodicEffect,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             action: actions.addTimerBuff,
        //             overrideTarget: targets.allEnemies,
        //             actionParams: [
        //                 {
        //                     buff: debuffs.damageTypeWeakness, 
        //                     buffParams: [1, spellTypes.ice]
        //                 }, 10
        //             ],
        //             image: "blizzard2",
        //             description: "hit by blizzard",
        //             overrideTargetCastEffect: true,
        //         }, 
        //         1, //  every 2 second
        //         [conditions.damageDone, null, targets.me, null], 
        //         6 // maxtime
        //     ],
        // },
        // { // need : actions.addEffectToGroup
        //     action: actions.addConditionalPeriodicEffect,
        //     actionParams: [
        //         targets.me, 
        //         {
        //             action: actions.addTimerBuff,
        //             overrideTarget: targets.allEnemies,
        //             actionParams: [
        //                 {
        //                     buff: buffs.slowerCastTime, 
        //                     buffParams: [1, spellTypes.ice]
        //                 }, 3
        //             ],
        //             image: "blizzard3",
        //             description: "hit by blizzard",
        //         }, 
        //         1, 
        //         [conditions.damageDone, null, targets.me, null],
        //         6 // maxtime
        //     ],
        // },
        
    ],
    xpGain: 0,
    manaCost: 1,
}