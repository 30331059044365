
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Bust a Move",
    description: "Dodge all incoming spells.",
   // flair: "Amazing dancer, that one",
    spellType: spellTypes.ice,
    level: 1,
    //charges: 1,
    castTime: 1,
    cooldown: 20,
    duration: 3,
    damage: 0,
    noQueue: true,
    target: targets.selections.targetSelf,
    image: "dancing",
    effects: [
        {
            action: actions.addTimerBuff,
            actionParams: [
              targets.me, 
              {
                buff: buffs.dodgeChance,
                buffParams: [100]
              }, 
              3, 
            ],
        },
  ],
    xpGain: 0,
    manaCost: 1,
}

// {
//     action: actions.gainHealth, // damage: ( target, damage, type)
//     actionParams: [
//     targets.allAliveAllies, 
//     [specialDamageAmounts.timerCountMultiple, targets.me, 1, debuffs.damageTypeWeakness, spellTypes.ice]],
// },
// {
//     action: actions.removeTimer,
//     actionParams: [targets.selectedPlayer, debuffs.damageTypeWeakness, [null, spellTypes.ice]]
// }