

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Vines",
    description: "Stuns target (x2 earth damage).",
    spellType: spellTypes.earth,
    image: "vines",
    level: 1,
   // charges: 1,
   // flair: "Where did those vines come from?",
    castTime: 2,
    duration: 2,
    cooldown: 30,
    damage: 2,
    target: targets.selections.targetEnemy,
    effectImage: "stun",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 2, spellTypes.earth],
        },
        {
            action: actions.addTimerState,
            actionParams: [
                targets.selectedPlayer, 
                states.stun,
                2
            ],
        }
        // {
        //     action: actions.addTimerConditionPromptEffect,
        //     actionParams: [
        //         targets.selectedPlayer,
        //         {
        //             action: actions.addTimerState,
        //             actionParams: [
        //                 targets.triggeringPlayer, 
        //                 states.stun,
        //                 4
        //             ],
        //             image: "trap",
        //         },
        //         [conditions.spellCast, null, targets.selectedPlayer],
        //         4,
        //     ]
        // }
    ],
    xpGain: 0,
    manaCost: 1,
}