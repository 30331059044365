
const Hats = {
    purple: "hatbasic",
    red: "hatbasicred",
    green: "hatbasicgreen",
    cat: "hatcat", 
    hatbluestar: "hatbluestar", 
    fire: "hatfire", 
    beer: "hatbeer",
    hatpurpstar: "hatpurpstar",
    coolblue: "hatcoolblue",
    coolgreen: "hatcoolgreen",
    coolpurple: "hatcoolpurple",
    rainbow: "hatrainbow",
    beer2: "hatbeer2",
    leaves: "hatleaves",
    flowersred: "hatflowersred",
    flowersgreen: "hatflowersgreen",
    flowerspurple: "hatflowerspurple",
    bearfur: "hatbearfur",
    dragonblue: "hatdragonblue",
    dragonred: "hatdragonred",
    tree: "hattree",
    mountain: "hatmountain",
    water: "hatwater",
    none: "none"
};

const Robes = {
    purple: "wasteynohat",
    green: "wasteynohatgreen",
    red: "wasteynohatred",
    brown: "wasteynohatbrown", 
    blue: "wasteynohatblue", 
    lightblue: "wasteynohatlightblue", 
    lightgreen: "wasteynohatlightgreen",
    lightpurple: "wasteynohatlightpurple",
    rainbow: "wasteynohatrainbow",
    dragonred: "wasteynohatdragonred",
    grey: "wasteynohatgrey",
    bear: "wasteybear",
    iced: "wasteyiced",
    fired: "wasteyfired"
}

const HatsList =[
    "purple",
    "green",
    "red",

    "cat",
    "beer",
    "fire",

    "coolblue",
    "coolgreen",
    "coolpurple",

    "flowersred",
    "flowersgreen",
    "flowerspurple",

    "rainbow",
    "leaves",
    "beer2",

    "bearfur",
    "dragonblue",
    "dragonred",

    "tree",
    "mountain",
    "water"

]
const RobesMap = {
    [Hats.purple]:"purple",
    [Hats.green]:"green",
    [Hats.red]:"red",
    [Hats.cat]:"purple",
    [Hats.beer]:"brown",
    [Hats.fire]:"red",
    [Hats.coolblue]: "lightblue",
    [Hats.coolgreen]: "lightgreen",
    [Hats.coolpurple]: "purple",
    [Hats.rainbow]:"rainbow",
    [Hats.leaves]:"lightgreen",
    [Hats.beer2]:"blue",
    [Hats.flowersred]: "red",
    [Hats.flowersgreen]: "lightgreen",
    [Hats.flowerspurple]: "lightpurple",
    [Hats.bearfur]: "brown",
    [Hats.dragonred]: "dragonred",
    [Hats.dragonblue]: "lightblue",
    [Hats.tree]: "lightgreen",
    [Hats.mountain]: "grey",
    [Hats.water]: "blue"
}
const RobesList = [
    // "purple",
    // "green",
    // "red",
    // "brown",
]

module.exports = {
    Hats, Robes, HatsList, RobesMap, RobesList
}