
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Drink to the Face",
    description: "+2 ice weakness.",
    spellType: spellTypes.ice,
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 20,
    duration: 10,
    damage: 2,
    damageType: spellTypes.ice,
    target: targets.selections.targetEnemy,
    image: "splash",
    effectImage: "frost",
    effectNumber: "/2",
    effects: [
        { // 1 ice damage
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 2, spellTypes.ice],
        },
        { // 1 ice weakness for 10 seconds
            action: actions.addTimerBuff,
            actionParams: [
                targets.selectedPlayer, {
                    buff: debuffs.damageTypeWeakness, 
                    buffParams: [2, spellTypes.ice]
                }, 10
            ],
        },
    ]
}