import React, {useRef, useState, useEffect} from 'react';
import ResourceBar from '../ResourceBar'
import './Spell.scss'
import imageMap from 'images/index.js';
function Spell( props ) {

    const {
        spellData,
        onClick,
        isSelected,
        clientIsCasting,
        isCastingAny,
        autoCastingOn,
        index,
        owner = {},
        isFighting,
        isChosen,
        isCasted,
        canAutoCastFromSelectedPlayer,
        otherSpellSelected,
        isDisplayList,
        isNext,
        isDeckPage,
        isSmaller,
        isCastingWhenReady,
        setCardBoundingBoxes,
        animated = true,
        darkBackground = false,
        disabled = false,
        grey,
        twoStacked = false
    } = props;

    let marks = [];
    if(spellData.cooldown >= 40){
        marks = [null, null, null, null];
    }
    else if(spellData.cooldown >= 30){
        marks = [null, null, null];
    }
    else if(spellData.cooldown >= 20){
        marks = [null, null];
    } else if(spellData.cooldown >= 10){

        marks = [null];
    }  

   // console.log("render spell " + spellData.name);

   const divRef = useRef();
   useEffect(() => {
     if (divRef.current) {
        setTimeout(()=>{
            const { current } = divRef;
            if(!current) return;
            const boundingRect = current.getBoundingClientRect()
            const { width, height, x, y } = boundingRect;
            if(setCardBoundingBoxes)
                 setCardBoundingBoxes({index, box: {width, height, x, y}});
           // setDimensions({ width: Math.round(width), height: Math.round(height) })
        }, 3000);
     }
   }, [divRef])

    const onCooldown = !isDisplayList && spellData.onCooldown ;//|| (owner.silence && !spellData.alwaysCast) || (!owner.unstunnable && owner.stun && !spellData.alwaysCast);
    //const isStunned = !owner.unstunnable && !spellData.alwaysCast && (owner.silence || owner.stun);
    const isStunned = owner.isStunned && !spellData.alwaysCast;

 // ${autoCastingOn &&  (!otherSpellSelected && !isDisplayList && (spellData.canAutoCast || canAutoCastFromSelectedPlayer) )? `autoCast ${spellData.spellType}`:''}
    
    // let cooldownPercent = 0;
    // if(spellData.cooldownLeft > 0 ){
    //     cooldownPercent = 100 - ((spellData.cooldownLeft / spellData.cooldown) * 100);
    // }

    let effectNum = spellData.effectNumber;
    if(!effectNum) effectNum = "";

    return (
        <div className={`Spell 
                ${grey?'grey':''}
               
                ${darkBackground?'darkBackground':''} 

                ${isSmaller?'smaller':''} 
              
                ${disabled?'disabled':''}
                ${onCooldown?'onCooldown':''}
                ${isStunned?'stunned':''}
                ${!clientIsCasting && isCasted?'inQueue':''}
                ${isSelected? 'choosingSelected': ''}
                ${isCastingWhenReady?'castingWhenReady':''}
                
                ${twoStacked? `twoStacked`:''}

                ${clientIsCasting? `casting ${spellData.spellType}`:''}
                
                ${isChosen? `autoCast ${spellData.spellType}`:''}
                ${isDeckPage? `deckPage ${spellData.spellType}`:''}
                ${isFighting && spellData.notCastable? 'notCastable':''}
            `} 
            onClick={onClick}
            ref={divRef}
            /*style={{"--time-left": cooldownTime+"s"}}*/
        >
             { spellData && owner && onCooldown && <div className='cooldownBar'>
                    <ResourceBar
                        total={1}
                        current={0}
                        previousCurrent={1}
                        timeToFillBar={Math.max(2/*sorry*/, spellData.cooldown - owner.reducedCooldown)}
                        className="countdownBarClass"
                    />
            </div>}

            {
            // !isDisplayList && spellData.onCooldown && spellData.cooldownLeft > 0 &&
            //     <div className="cooldownCount">
            //         <p>{Math.ceil(spellData.cooldownLeft)}</p>
            //     </div>
            }

            { (!!spellData.realDamage || !!spellData.damage || effectNum) && <div className={`top ${spellData.spellType}`}>
                <div className={`damageArea`}>
                    {/* {spellData.damage? <img className="sword" src={imageMap["sword"]}/>: null} */}
                    {!spellData.realDamage && !!spellData.damage && <div className="number">{spellData.damage? spellData.damage+effectNum: null}</div>}
                    {spellData.realDamage && <div className="number">{spellData.realDamage? spellData.realDamage+effectNum: null}</div>}
                    {!spellData.damage && !spellData.realDamage && <div className="number">{effectNum}</div>}
                    
                </div>
                {/* <div className={`cooldownArea`}>
                    <div className={`cooldownCircle`}>
                        <p>{spellData.cooldown}</p>
                    </div>
                </div>
                { spellData.castTime != 0 && <div className={`castTimeArea`}>
                    <ResourceBar
                        total={spellData.castTime}
                        current={spellData.castTime - 1}
                        className="emptyBar"
                        perfectFill={false}
                    />
                </div>}

                {
                    !!spellData.charges && spellData.charges > 1 && 
                        <div className={`chargesArea`}>
                            {spellData.charges}x
                        </div>
                }
                <div className={`targetArea`}>
                    <img width={"100%"} src={imageMap[spellData.target]}/>
                </div> */}

                
            </div>
            }
            <div className={`imageArea`}>
                <img src={imageMap[spellData.image]}/>
            </div>
            {
                !!spellData.charges && spellData.charges > 1 &&
                    <div className={`chargesArea count`}>
                        x{spellData.charges}
                    </div>
            }
            {!isSelected && !isChosen && !isFighting && <div className={`bottom `}>
                {
                    // fuck you safari you fucking piece of shit
                //spellData.effectImage && <img className={`effectImage `} src={imageMap[spellData.effectImage]}/>
                
                }
                {/* <div className={`spellType`}>
                    <div className={`spellTypeCircle ${spellData.spellType}`}/>
                </div> */}

                    { marks.map((nul, i)=>{
                        return <div className="mark" key={i}>

                        </div>
                    })

                    }
                
        
                {
                //  <div className={`effectArea`}>
                // {spellData.effectNum && <div className="number">{spellData.effectNum}</div>}
                
                // </div> 
                }
            </div> }

            { spellData.level == 2 &&
                <div className={`chargesArea ${spellData.spellType}`}>
                    <span className="material-symbols-outlined">
                    star
                    </span>
                </div>
                
            }

        </div>
    )
}

export default Spell;