
import React from 'react';
const AllCards = require("shared/decks/available");
const {BasicArmor, Armor} = require("shared/decks/providedDecks");
import Spell from '../components/SpellList/Spell'

let ArmorList = BasicArmor();
let AllArmor = Armor();

const ArmorPerRow = 3;

export default function GearSelectionPage(props){
    const {
        me,
        gameState,
        selectedCardIndex,
        setSelectedCardIndex,
    } = props;


    const BuildGearArea = ()=>{
        let ArmorRows = [];
        let rowCount = Math.ceil(ArmorList.length / ArmorPerRow);
        for(let i = 0; i < rowCount; i++){
            let Armor = [];
            let pCount = ArmorPerRow;
            let leftoverPs = ArmorList.length % ArmorPerRow;
            if(i == rowCount - 1 && leftoverPs > 0){
                pCount = leftoverPs;
            }
            for(let j = 0; j < pCount; j++){
                let itemIndex = (i * ArmorPerRow) + j;
                let itemKey = ArmorList[itemIndex];
                let itemData = {...AllCards[itemKey]};
                let drawArrowToMyLeft = j != 0;

                let handIndex = -1;
                let k = -1;
                let hasInHand = me.gearKeys[itemData.name];

                let prevItemData = null;
                if(hasInHand && itemData.upgradesTo){
                    prevItemData = itemData;
                    itemData = {...AllCards[itemData.upgradesTo]};
                    hasInHand = me.gearKeys[itemData.name];
                    AllArmor.map((key, ind)=>{
                        if(key == itemData.name){
                            itemIndex = ind;
                        }
                    });
                }
                // if this armor has been purchased already

                let locked = false;
                if(itemData.requires){
                    if(!me.gearKeys[itemData.requires]){
                        locked = true;
                    }
                }
                let cantBuy = locked || me.currentMonies < itemData.cost || (itemData.armorCost && me.currentArmors < itemData.armorCost);
                let total = 1;
                let current = 0;
                if(itemData.upgradesTo || itemData.upgradesFrom){
                    total = 2;
                    if(itemData.upgradesFrom && hasInHand){
                        current = 2;
                    } else if(itemData.upgradesFrom){
                        current = 1;
                    }
                } else if(hasInHand){
                    current = 1;
                }

                Armor.push(
                    <div className="PotionArea">
                        <React.Fragment>
                            {prevItemData && 
                                <div className="prevItem">
                                     <Spell 
                                        spellData={prevItemData}
                                        isSelected={selectedCardIndex == itemIndex}
                                        isChosen={hasInHand}
                                    />
                                </div>
                            }
                            { drawArrowToMyLeft && <div className={`arrowUpgrade ${cantBuy && !hasInHand?'disabled':''}`}>
                                    <span className="material-symbols-outlined">
                                        arrow_forward
                                    </span>
                                </div>
                            }
                            <Spell 
                                spellData={itemData}
                                isSelected={selectedCardIndex == itemIndex}
                                isChosen={hasInHand}
                                disabled={cantBuy && !hasInHand}
                                onClick={()=> {
                                    setSelectedCardIndex(itemIndex);
                                }}
                            />
                            {<div className={`cost ${hasInHand?'owned':''} ${cantBuy?'disabled':''}`}>
                               {
                            //    itemData.cost && <div className="monieCost">
                            //         <div className="material-symbols-outlined">
                            //             attach_money
                            //         </div>
                            //         <div className="costAmount">
                            //         {itemData.cost}
                            //         </div>
                            //     </div>}
                            //     {itemData.armorCost && <div className="armorCost">
                            //         <div className="material-symbols-outlined">
                            //             shield_moon
                            //         </div>
                            //         <div className="costAmount">
                            //         {itemData.armorCost}
                            //         </div>
                            //     </div>
                                
                                    <div className="monieCost">
                                        <div className="costAmount">
                                            {current} / {total}
                                        </div>
                                    </div>
                                }
                            </div>}
                        </React.Fragment>
                    </div>
                )
            }
            ArmorRows.push(
                <div className="PotionRow">
                    {Armor}
                </div>
            );
        }
        return <div className="PotionSelectArea">
            {ArmorRows}
        </div>
    }

    return <React.Fragment>
        <div className="CardSelectionButtons">
            <div className="cardSelectionButtonsRow">
            {
                    me.id && !me.ready && me.canBeReady &&
                    <div className="firstRoundInstructions">
                        Hit <span className="ready">Ready!</span> to continue.
                    </div>
                }
                {  me && me.id && me.ready && 
                    <div className="firstRoundInstructions">
                        Waiting for other players to finish.
                    </div>
                }
                {
                    me.id && !me.ready && !me.canBeReady &&
                    <div className="firstRoundInstructions">
                        Armor upgrades are perminant.
                    </div>
                }
            </div>
        </div>
        <div className="CardSelectionArea">
            { BuildGearArea() }
        </div>
    </React.Fragment>

}