
module.exports = {
    

    // selectedPlayer: "selectedPlayer",
    // triggeringPlayer: "triggeringPlayer",
    // triggeringPlayerTarget: "triggeringPlayerTarget",
    // randomPlayer: "randomPlayer",

    // randomPlayerNotMe: "randomPlayerNotMe",

    // randomAlivePlayerNotMe: "randomAlivePlayerNotMe",
    // randomAliveEnemy: "randomAliveEnemy",
    // randomAliveExcept: "randomAliveExcept",
    // playersDamagedByThisSpell: "playersDamagedByThisSpell",
    // allAliveExcept: "allAliveExcept",
    // allAlive: "allAlive",


    selectedPlayer: "selectedPlayer", 
    me: "me",

    // triggers
    triggeringPlayer: "triggeringPlayer",
    triggeringPlayerTarget: "triggeringPlayerTarget",
    playersDamagedByThisSpell: "playersDamagedByThisSpell",

    // targetEnemies
    randomAliveEnemy: "randomAliveEnemy",
    randomDeadEnemy: "randomDeadEnemy",
    allEnemies: "allEnemies",
    allEnemiesExcept: "allEnemiesExcept",
    randomAliveEnemyExcept: "randomAliveEnemyExcept",

    // targetAllies
    randomAliveAlly: "randomAliveAlly",
    randomDeadAlly: "randomDeadAlly",
    allAliveAllies: "allAliveAllies",
    allAliveAlliesExcept: "allAliveAlliesExcept",
    randomAliveAllyExcept: "randomAliveAllyExcept",


    selections: {
        targetEnemy: "targetEnemy",
        targetEnemies: "targetEnemies",
        targetAllies: "targetAllies",
        targetAlly: "targetAlly",
        targetSelf: "targetSelf",
 
        // targets:
        descriptions: {
            targetEnemy: "single enemy",
            targetEnemies: "all enemies",
            targetAllies: "all allies",
            targetAlly: "single ally",
            targetSelf: "myself"
        }
    },

    
}