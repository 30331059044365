import React, {useState, useEffect, useReducer, cloneElement, useCallback} from 'react';
import './CardSelectionPage.scss';
import BigButton from '../../menu/components/BigButton';
import CardDescription from './components/CardDescription'
const spellTypes = require("shared/cardConstants/spellTypes");
import imageMap from '../../../images/index.js'

const serverMessages = require("shared/serverMessages");
const AllCards = require("shared/decks/available");
import Spell from '../components/SpellList/Spell'
import SpellSelectionPage from './SpellSelectionPage'
import PotionSelectionPage from './PotionSelectionPage'
import GearSelectionPage from './GearSelectionPage'
import ActionButton from './components/ActionButton'
import Draw3Description from './components/Draw3Description'
import RedrawDescription from './components/RedrawDescription'
const {Armor} = require("shared/decks/providedDecks");
let ArmorList = Armor();


export const Tabs = {
    spells: 0,
    armor: 1,
    potions: 2
}
export const TabNames = ["Spells", "Armor", "Items"];
export const TabIcons = ["playing_cards", "shield", "local_bar"];

export default function CardSelectionPage( props ) {
    const {
        ioConnection, 
        me, 
        spells, 
        gameState,
        otherPlayers
    } = props;

    //console.log(otherPlayers);

    let startingTab = 0;
    if(gameState.startOnPotions && me && me.id) startingTab = Tabs.potions;
    if(gameState.startOnArmor  && me && me.id) startingTab = Tabs.armor;

    const [currTab, setCurrTab] = useState(startingTab);
    const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
    const [localSpellChoices, setLocalSpellChoices] = useState(me.cardChoices || {});
    const [showDraw3, setShowDraw3] = useState(false);
    const [showRedraw, setShowRedraw] = useState(false);

    let currentChoices = Object.keys(localSpellChoices).filter(key => localSpellChoices[key]).length;
    // selected weapon items!
   // let weaponItems = Object.keys(localSpellChoices).filter(key => localSpellChoices[key] && me.spellsToChooseFrom[key] && me.spellsToChooseFrom[key].spellType == spellTypes.weapon).length;
    let hasMaxChoices = currentChoices >= gameState.value  + (me?.extraSpellsInHand && gameState.addInCurrentHand?me.extraSpellsInHand:0);
    // changing- weapons no longer count toward hand!
    let choicesLeft = gameState.value  + (me?.extraSpellsInHand && gameState.addInCurrentHand?me.extraSpellsInHand:0) - currentChoices;// + weaponItems;

    let itemCount = 0;
    if(me.items){
        me.items.map(item =>{
            if(item) itemCount++;
        })
    }
    //itemCount += weaponItems;
    
    let {hidePotions, hideArmor, hideMonies} = gameState;

    useEffect(()=>{
        if(currTab == Tabs.potions && me.currentMonies < 2){
            setCurrTab(Tabs.spells);
        }
    },[me.currentMonies])

    const chooseSpell = (index)=>{
        // if adding new, make sure there is space
        if(!localSpellChoices[index]){
            // if its a weapon, check for spell slot.
            //if(me.spellsToChooseFrom[index].spellType == spellTypes.weapon && itemCount == 4) return;
            // if its not a weapon, check choicesLeft
            if(me.spellsToChooseFrom[index].spellType != spellTypes.weapon && !choicesLeft) return;
        }

        ioConnection.emit(serverMessages.requests.chooseCard, {cardIndex: index});

        if(!localSpellChoices[index]){
            setLocalSpellChoices(Object.assign(localSpellChoices, {[index]: true}));
        } else {
            setLocalSpellChoices(Object.assign(localSpellChoices, {[index]: false}));
        }
        setSelectedCardIndex(-1);
        if(soundsOn){
            checkSounds();
            Sounds.click.play();
        }
    }

    const buySelectedItem = ()=>{
        let itemKey = me.availableItems[selectedCardIndex];
        ioConnection.emit(serverMessages.requests.buyItem, {itemKey: itemKey});
        setSelectedCardIndex(-1);
        if(soundsOn){
            checkSounds();
            Sounds.click.play();
        }
    }

    const buyDraw3 = ()=>{
        ioConnection.emit(serverMessages.requests.buyDraw3, {});
        setShowDraw3(false);
    }

    const buyRedraw = ()=>{
        ioConnection.emit(serverMessages.requests.buyRedraw, {});
        let currNumSelected = 0;
        Object.values(localSpellChoices).map(value => {
            if(value) currNumSelected++;
        });
        let newLocalSpellChoices = {};
        for(let i = 0; i < currNumSelected; i++){
            newLocalSpellChoices[i] = true;
        }
        setLocalSpellChoices(newLocalSpellChoices);
        setShowRedraw(false);
    }

    const buySelectedGear = ()=>{
         let gearKey = ArmorList[selectedCardIndex];
        ioConnection.emit(serverMessages.requests.buyGear, {gearKey: gearKey});
        setSelectedCardIndex(-1);
    }

    let selectedCardData = null;
    
    if(selectedCardIndex != -1){
        if(currTab == Tabs.spells){
            if(selectedCardIndex >= me.spellsToChooseFrom.length){
                selectedCardData = spells[selectedCardIndex - me.spellsToChooseFrom.length];
            } else {
                selectedCardData = me.spellsToChooseFrom[selectedCardIndex];
            }
        } else if(currTab == Tabs.potions){
            let selectedItemKey = me.availableItems[selectedCardIndex];
            selectedCardData = {...AllCards[selectedItemKey]};
        }
        else if(currTab == Tabs.armor){
            let selectedItemKey = ArmorList[selectedCardIndex];
            selectedCardData = {...AllCards[selectedItemKey]};
        }
    }

    return <div className="CardSelectionPage">


        {selectedCardIndex == -1 &&
            <div className="ProfileArea">
                <div className="profileAreaRow">
                    {!hideMonies &&  me && me.id && 
                        <div className="resources">
                            <div className="moniesArea">
                                <div className="moniesRow">
                                    <div className="moniesIcon">  
                                        <div className="material-symbols-outlined">
                                            universal_currency_alt
                                        </div>
                                    </div>
                                    <div className="moniesNum">                    
                                        {me.currentMonies}
                                    </div>
                                </div>
                                <div className="armorRow">
                                    <div className="armorIcon">  
                                        <div className="material-symbols-outlined">
                                            shield_moon
                                        </div>
                                    </div>
                                    <div className="armorNum">                    
                                        {me.currentArmors}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    }

                    { me && me.id && <div className="achievmentsArea">
                        {/* TODO make this a section that grows and can list up to 3 */}
                        {/* <div className="achievment">
                            +1 Most Damage Taken
                        </div>
                        <div className="achievment">
                            +1 Most Fire Damage
                        </div> */}
                    </div>}

                    <div className={`partyArea ingame ${ !me || !me.id?'observer':''}`}>
                        <div className="partyMembers">
                            {
                                Object.values(otherPlayers).map((playerData, i) => {
                                    return <div className="partyMember" key={i}>
                                        {
                                            playerData.ready &&
                                            <div className="partyMemberReady">
                                                <span className="material-symbols-outlined">
                                                    done
                                                </span>
                                            </div>
                                        }
                                        <div className="partyMemberName ingame">
                                            {playerData.name}
                                        </div>
                                        <div className="partyMemberHatWrapper ingame">
                                        {playerData.hat && <img className="partyMemberHat" src={imageMap[playerData.hat]}/>}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        }

        { selectedCardIndex != -1 && selectedCardData && 
             <div  className={`cardDescriptionArea`}>
                <div  className="cardDescriptionAreaRow">
                    <CardDescription
                        spellData={selectedCardData}
                        isExpanded={true}
                        onClose={()=>setSelectedCardIndex(-1)}
                        showClose
                    />
                </div>
            </div>
        }

        { showDraw3 && 
             <div  className={`cardDescriptionArea`}>
                <div  className="cardDescriptionAreaRow">
                    <Draw3Description
                        onClose={()=>setShowDraw3(false)}
                        showClose
                    />
                </div>
            </div>
        }

        { showRedraw && 
             <div  className={`cardDescriptionArea`}>
                <div  className="cardDescriptionAreaRow">
                    <RedrawDescription
                        onClose={()=>setShowRedraw(false)}
                        showClose
                    />
                </div>
            </div>
        }

        {currTab == Tabs.spells && 
            <SpellSelectionPage 
                me={me} 
                spells={spells}
                gameState={gameState}
                localSpellChoices={localSpellChoices}
                hasMaxChoice={hasMaxChoices}
                selectedCardIndex={selectedCardIndex}
                itemCount={itemCount}
                setSelectedCardIndex={setSelectedCardIndex}
                setShowDraw3={setShowDraw3}
                setShowRedraw={setShowRedraw}
                chooseSpell={chooseSpell}
            />
        }
        {currTab == Tabs.potions && 
            <PotionSelectionPage 
                me={me} 
                localSpellChoices={localSpellChoices}
                gameState={gameState}
                itemCount={itemCount}
                selectedCardIndex={selectedCardIndex}
                setSelectedCardIndex={setSelectedCardIndex}
            />
        }
        {currTab == Tabs.armor && 
            <GearSelectionPage 
                me={me} 
                gameState={gameState}
                selectedCardIndex={selectedCardIndex}
                setSelectedCardIndex={setSelectedCardIndex}
            />
        }

        {  
            <div className="BottomArea">
                <div className="bottomAreaRow">
                    <div className="bottomAreaItems">
                        <div className="readyArea">
                            {me && me.id && !me.ready && !me.canBeReady && currTab == Tabs.spells &&
                                <div className="chooseMoreText">
                                    Choose {choicesLeft} more
                                </div>
                            }
                            {
                            // me && !me.canBeReady && currTab != Tabs.spells && 
                            //     <div className="chooseMoreText red">
                            //         Spells needed
                            //     </div>
                            }


                            { me && !me.ready && me.canBeReady &&
                                <div className="readyButton">
                                    <BigButton 
                                        blue
                                        onClick={()=>{
                                            ioConnection.emit(serverMessages.requests.ready);
                                        }} >
                                            Ready!
                                    </BigButton>
                                </div>
                            }
                        </div>
                        <div className="timeArea">
                            <div className="timeIcon">
                                <span className="material-symbols-outlined">
                                    timer
                                </span>
                            </div>
                            <div className="timeNum">
                                30
                            </div>
                        </div>
                        {me  && <div className="actionButtonArea">
                            <ActionButton
                                me={me}
                                currTab={currTab}
                                selectedCardIndex={selectedCardIndex}
                                localSpellChoices={localSpellChoices}
                                choicesLeft={choicesLeft}
                                itemCount={itemCount}
                                chooseSpell={chooseSpell}
                                buySelectedItem={buySelectedItem}
                                showDraw3={showDraw3}
                                buyDraw3={buyDraw3}
                                showRedraw={showRedraw}
                                buyRedraw={buyRedraw}
                                buySelectedGear={buySelectedGear}
                            />
                        </div>}
                        
                    </div>
                </div>
            </div>
        }

        {
            <div className="TabArea">
                <div className="tabAreaRow">
                    {
                        TabNames.map((name, i) => {
                            if(!me.id) return null;
                            let isDisabled = (i == Tabs.potions && hidePotions) || (i == Tabs.armor && hideArmor);
                
                            return <div key={i} className={`Tab ${currTab == i?'selected':''} ${isDisabled?'disabled':''}`}
                            onClick={()=>{
                                if(!isDisabled) {
                                    setSelectedCardIndex(-1);
                                    setCurrTab(i);
                                }
                            }}>
                                <div className="icon">
                                    <div className="material-symbols-outlined">
                                        {TabIcons[i]}
                                    </div>
                                </div>
                                <div className="title">{TabNames[i]}</div>
                                {
                                    i == Tabs.spells && choicesLeft > 0 &&
                                    <div className="SpellsLeftNotifierWrapper">
                                        <div className="SpellsLeftNotifier">
                                            <div className="SpellsLeftNotifierText">
                                                {choicesLeft}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        }

    </div>
}