
import React from 'react';
const AllCards = require("shared/decks/available");
import Spell from '../components/SpellList/Spell'
const spellTypes = require("shared/cardConstants/spellTypes");

const PotionsPerRow = 3;

export default function PotionSelectionPage(props){
    const {
        me,
        localSpellChoices,
        gameState,
        selectedCardIndex,
        itemCount,
        setSelectedCardIndex,
    } = props;

  

    const BuildPotionArea = ()=>{
        let PotionRows = [];
        let rowCount = Math.ceil(me.availableItems.length / PotionsPerRow);
        for(let i = 0; i < rowCount; i++){
            let Potions = [];
            let pCount = PotionsPerRow;
            let leftoverPs = me.availableItems.length % PotionsPerRow;
            if(i == rowCount - 1 && leftoverPs > 0){
                pCount = leftoverPs;
            }
            for(let j = 0; j < pCount; j++){
                let itemIndex = (i * PotionsPerRow) + j;
                let itemKey = me.availableItems[itemIndex];
                let itemData = {...AllCards[itemKey]};

                let handIndex = -1;
                let k = -1;
                let hasInHand = me.items.reduce((accum, curr) =>{
                    k++;
                    if(accum) return true;
                    else if (!curr) return false;
                    else if (curr.name == itemKey){
                        handIndex = k;
                        return true;
                    } 
                    else return false;
                }, false);

                // need to add the charges
                if(hasInHand){
                    itemData.charges = me.items[handIndex].charges;
                }
                
                Potions.push(
                    <div className="PotionArea">
                       
                         <Spell 
                            spellData={itemData}
                            isSelected={selectedCardIndex == itemIndex}
                            isChosen={hasInHand}
                            disabled={me.currentMonies < itemData.cost && !hasInHand}
                            onClick={()=> {
                                setSelectedCardIndex(itemIndex);
                            }}
                        />
                         <div className={`cost ${selectedCardIndex == itemIndex?'chosen':''} ${me.currentMonies < itemData.cost?'disabled':''}`}>
                            <div className="material-symbols-outlined">
                                attach_money
                            </div>
                            <div className="costAmount">
                            {itemData.cost}
                            </div>
                        </div>
                        
                    </div>
                )
            }
            PotionRows.push(
                <div className="PotionRow">
                    {Potions}
                </div>
            );
        }
        return <div className="PotionSelectArea">
            {PotionRows}
        </div>
    }

    return <React.Fragment>
        <div className="CardSelectionButtons">
            <div className="cardSelectionButtonsRow">
                {
                    itemCount >= 2 && 
                    <div className="firstRoundInstructions">
                        {4 - itemCount} item slots left.
                    </div>
                }
                {
                    itemCount < 2 && me.id && !me.ready && me.canBeReady &&
                    <div className="firstRoundInstructions">
                        Hit <span className="ready">Ready!</span> to continue.
                    </div>
                }
                {  itemCount < 2 && me && me.id && me.ready && 
                    <div className="firstRoundInstructions">
                        Waiting for other players to finish.
                    </div>
                }
                {
                    itemCount < 2 && me.id && !me.ready && !me.canBeReady &&
                    <div className="firstRoundInstructions">
                      {  'Items can only be used once.'}
                    </div>
                }
            </div>
        </div>
        <div className="CardSelectionArea">
            <div className="ScrollableSpellList">
                { BuildPotionArea() }
            </div>
        </div>
    </React.Fragment>
}