
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const forcefield1 = require('./forcefield1');



module.exports = {
    name: "Force Field of Superior Fuckary",
    description: "5% chance to reflect all spells",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "forcefield2",
    upgradesFrom:  "Force Field of Moderate Fuckary",
    effects: [
        {
            buff: buffs.spellTypeReflect, 
            buffParams: [10, spellTypes.ice]
        },
        {
            buff: buffs.spellTypeReflect, 
            buffParams: [10, spellTypes.earth]
        },
        {
            buff: buffs.spellTypeReflect, 
            buffParams: [10, spellTypes.fire]
        }
    ]
}