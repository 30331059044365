
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Burn 'em",
    description: "Add 5 delayed damage.",
    flair: "",
    spellType: spellTypes.fire,
    level: 1,
    castTime: 2,
    cooldown: 10,
    damage: 1,
    target: targets.selections.targetEnemy,
    image: "lighter",
    effectImage: "fire",
    effectNumber: "/5",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 1, spellTypes.fire],
        },
        {
            action: actions.addTimerPeriodicEffect,
            actionParams: [
                targets.selectedPlayer, 
                {
                    action: actions.damage,
                    actionParams: [5, spellTypes.fire],
                    image: "fire",
                    isDebuff: true
                }, 
                20, 
                20
            ],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}