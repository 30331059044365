
let spellTypes = require('./spellTypes');

module.exports =  {
    maxHealth: "maxHealth",
    maxMana: "maxMana",
  
    healthRegen: "healthRegen",
    manaRegen: "manaRegen",
    fasterCardDraw: "fasterCardDraw",

    fasterCastTime: "fasterCastTime",
    reducedCooldown: "reducedCooldown", 
    slowerCastTime: "slowerCastTime",
  
    damageTypeBonus: "damageTypeBonus",
    spellTypeReflect: "spellTypeReflect",
    damageTypeDeflect: "damageTypeDeflect",
    damageTypeResistance: "damageTypeResistance",
  
    resistance: "resistance",
  
    dodgeChance: "dodgeChance",
    missChance: "missChance",

    bonusDelayedDamage: "bonusDelayedDamage",
    
    reflectChance: "reflectChance",
    deflectChance: "deflectChance",
    absorbChance: "absorbChance",
  
    xpBonus: "xpBonus",
    healBonus: "healBonus",

    gainHealthEachRound: "gainHealthEachRound",
    // this will only draw extra when the round is already drawing from basic
    drawExtraBasicCards: "drawExtraBasicCards",

    // this is a special type of buff that adds its effect onto the conditionals list.
    // keep a map of staticConditionalEffect names to data so we can remove and reset them
    // when armor is equipped or unequipped.
    staticConditionalEffect: "staticConditionalEffect",

    drawExtraItems: "drawExtraItems",
    extraSpellsInHand: "extraSpellsInHand",
    critChance: "critChance",


    caps: {
      dodgeChance: 100,
      missChance: 100,
      critChance: 100
    },
  
    icons: {
      damageTypeBonus: {
        [spellTypes.ice]: "frost",
        [spellTypes.fire]: "fireBonus",
        [spellTypes.earth]: "earthBonus",
      },
      damageTypeResistance: {
        [spellTypes.ice]: "hot_toddy",
        [spellTypes.fire]: ""
      },
      spellTypeReflect: {
        [spellTypes.ice]: "icemaster",
        [spellTypes.fire]: "eyes",
      },
      damageTypeDeflect: {
        [spellTypes.fire]: "firemaster",
        [spellTypes.earth]: "",
      },
      damageTypeBuffer: {
        [spellTypes.frost]: ""
      },
      bonusDelayedDamage: "fireball_shot",
      deflectChance: "",
      resistance: "shield",
      missChance: "smoke",
      dodgeChance100: "dancing",
     // dodgeChance: "smoke",
      fasterCastTime: "esspresso_martini",
      reducedCooldown: "",
      slowerCastTime: "bluetint"
    }
}