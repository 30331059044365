

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Vampire's Bloody Mary",
    description: "Gain health for all fire damage done.",
    flair: "Smart guy thinks he's a vampirrrre",
    spellType: spellTypes.item,
    charges: 1,
    //cooldown: 1,
    castTime: 0,
    duration: 10,
    damage: 0,
    level: 1,
    target: targets.selections.targetSelf,
    image: "bloodymary",
    item: true,
    alwaysCast: true,
    cost: 5,
    effects: [
        {
            action: actions.addTimerConditionPromptEffect,
            actionParams: [
                targets.me,
                {
                    action: actions.gainHealth,
                    actionParams: [targets.me, specialDamageAmounts.triggeringDamageDone],
                    image: "blood"
                }, 
                [conditions.damageDone, targets.me, null, spellTypes.fire], // damage done by me
                10
            ]
        }
    ],
    xpGain: 0,
    manaCost: 1,
}
