
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');
const chestplate = require('./chestplate');
const dagger = require('./dagger');



module.exports = {
    name: "Hunting Bow of Hammered Homies",
    description: "10% chance for spells to hit critically (double damage).",
    spellType: spellTypes.armor,
    level: 1,
    notCastable: true,
    noDiscardDeck: true,
    gear: true,
    armorCost: 1,
    image: "bow",
    requires: dagger.name,
   // upgradesTo: chestplate.name,
    effects: [
        {
            buff: buffs.critChance, 
            buffParams: [10]
        }
    ]
}