




let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

module.exports = {
    name: "Little bomb",
    description: "3 damage in 15 seconds.",
    spellType: spellTypes.fire,
    level: 1,
   // charges: 1,
    castTime: 1,
    cooldown: 10,
    damage: 0,
    target: targets.selections.targetEnemy,
    image: "littlebomb",
    effectImage: "fire",
    effectNumber: "/3",
    effects: [
        {
            action: actions.addTimerPeriodicEffect,
            actionParams: [
                targets.selectedPlayer, 
                {
                    action: actions.damage,
                    actionParams: [3, spellTypes.fire],
                    image: "fire",
                    isDebuff: true
                }, 
                20, 
                20
            ],
          }
    ],
    xpGain: 0,
    manaCost: 1,
}
