

module.exports = {
    damageDelt: "damageDelt",
    healingReceived: "healingReceived",
    damageType: "damageType",
    isCrit: "isCrit",
    theDoer: "theDoer",
    theTaker: "theTaker",
    deflections: "deflections",
    levelGained: "levelGained",
    playerDied: "playerDied",

    totalDamage: "totalDamage",
    totalHealing: "totalHealing"
  }