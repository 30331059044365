import React, {useState} from 'react';
import imageMap from '../../images/index.js'

import BigButton from './components/BigButton';
import TextField from './components/TextField';
import Wastey from './components/Wastey.js';
import ImageSelector from './components/ImageSelector.js';

const storageValues = require("../../src/storageValues");
const serverMessages = require('shared/serverMessages.js');

let {Hats, Robes, RobesMap} = require('shared/hats');

export default function IntroPage(props){

    const {
        ioConnection,
        playerName,
        setPlayerName,
        myHat,
        setHat,
        myRobe,
        setRobe,
        joinGameID,
        onFinish
    } = props;

    const [nameField, setNameField] = useState("");

    const [nameSaved, setNameSaved] = useState(false);
    const [hatSaved, setHatSaved] = useState(false);

    const [buttonHidden, setButtonHidden] = useState(false);
    const [realWastey, setRealWastey] = useState(false);

    const playHideAnimation = () =>{
        setButtonHidden(true);
        setTimeout(()=>{
            setButtonHidden(false);
        }, 1);
        setTimeout(()=>{
            setRealWastey(true);
        }, 1000);
    }

    const joinGame = ()=>{
        
            let savedID = localStorage.getItem(storageValues.playerID);
            let myDeck = localStorage.getItem(storageValues.playerDeck);
            let myHat = localStorage.getItem(storageValues.myHat);
            let myRobe = localStorage.getItem(storageValues.myRobe);
            if(!myDeck || myDeck === "undefined" || myDeck == "[object Object]"){
                myDeck = BasicDeck();
            } else {
                myDeck = JSON.parse(myDeck);
            }
            ioConnection.emit(serverMessages.requests.joinOrCreateGame, {gameName: joinGameID, playerID: savedID, myName: nameField, myDeck, myHat, myRobe})
        
    }

    let saveButtonBlue = true;
    let saveButtonRed = false;
    let saveButtonGreen = false;
    if(myHat == Hats.green && nameSaved){
        saveButtonBlue = false;
        saveButtonRed = false;
        saveButtonGreen = true;
    } else if(myHat == Hats.red){
        saveButtonBlue = false;
        saveButtonRed = true;
        saveButtonGreen = false;
    }

    return <div className="MenuPage">
        
        <div className={`wizardArea ${!nameSaved?'intro':''}`}>
            <Wastey robe={myRobe} hat={myHat}/>
        </div>

        { !buttonHidden && 
            <div className={`underWizardArea ${!nameSaved?'intro':''}`}>
                {!nameSaved && <div className={"directionText"}>
                    What is your name, Wizard?
                </div>}
                {nameSaved && !hatSaved && <div className={"directionText"}>
                    Choose your outfit
                </div>}
            </div>
        }

        <div className="clothingSelectArea">
            { realWastey && !hatSaved && 
                <ImageSelector 
                    list={[Hats.purple, Hats.green, Hats.red]}
                    onSelect={(hat)=> {
                        setHat(hat);
                        setRobe(Robes[RobesMap[hat]]);
                    }} 
                    current={myHat}
                /> 
            }
        </div>

        <div className={`introArea ${!nameSaved?'intro':'intro2'}`}>
            {!nameSaved && <div className="intropagerow">
                <div className="namefieldrow">
                    <TextField
                        label={""}
                        value={nameField}
                        error={false}
                        helperText={""}
                        onChange={(e) => {
                            setNameField(e.target.value)
                        }}
                    />
                </div>
            </div>}
            
            {!buttonHidden && <div className="intropagerow">
                <div className="savebutton">
                    <BigButton 
                        blue={saveButtonBlue}
                        green={saveButtonGreen}
                        red={saveButtonRed}
                        disabled={!nameField}
                        onClick={()=>{ 
                            setPlayerName(nameField)
                            // TODO we may have been invited to a game!
                            if(!nameSaved){
                                setNameSaved(true);
                                playHideAnimation();
                            } else {
                                // finished
                                if(joinGameID){
                                    joinGame();
                                } else {
                                    // tell parent to switch 
                                    onFinish();
                                }
                            }
                            if(soundsOn)Sounds.click.play();
                        }} >
                            Save
                    </BigButton>
                </div>
            </div>}
        </div>
    </div>
          
}