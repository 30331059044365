
let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');

// let name = "Wild Flame";

module.exports = {
  name: "Fireball",
  description: "",
  description: "Add 3 delayed damage.",
  //flair: "A classic.",
  spellType: spellTypes.fire,
  level: 1,
 // charges: 1,
  castTime: 3,
  cooldown: 10,
//  duration: 5,
  damage: 7,
  target: targets.selections.targetEnemy,
  image: "fireball2",
  effectImage: "fire",
  effectNumber: "/3",
  effects: [
    {
        action: actions.damage,
        actionParams: [targets.selectedPlayer, 7, spellTypes.fire],
    },
    {
      action: actions.addTimerPeriodicEffect,
      actionParams: [
          targets.selectedPlayer, 
          {
              action: actions.damage,
              actionParams: [3, spellTypes.fire],
              image: "fire",
              isDebuff: true
          }, 
          20, 
          20
      ],
  }
  ], 
  xpGain: 0,
  manaCost: 1,
}
