                              1

let actions = require('../../../cardConstants/actions');
let buffs = require('../../../cardConstants/buffs');
let debuffs = require('../../../cardConstants/debuffs');
let conditions = require('../../../cardConstants/conditions');
let specialDamageAmounts = require('../../../cardConstants/specialDamageAmounts');
let spellTypes = require('../../../cardConstants/spellTypes');
let states = require('../../../cardConstants/stateDebuffs');
let stateBuffs = require('../../../cardConstants/stateBuffs');
let targets = require('../../../cardConstants/targets');


module.exports = {
    name: "Explosion",
    description: "",
    spellType: spellTypes.fire,
    level: 1,
   // charges: 1,
    castTime: 1.5,
    cooldown: 5,
    damage: 4,
    target: targets.selections.targetEnemy,
   // flair: "Little bitty baby boom.",
    image: "explosion",
    effects: [
        {
            action: actions.damage,
            actionParams: [targets.selectedPlayer, 4, spellTypes.fire],
        }
    ],
    xpGain: 0,
    manaCost: 1,
}